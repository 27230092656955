import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useStoreVideoAlbumSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["storeVideoAlbumId", "storeVideoAlbumName", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, storeVideoAlbumId: Number(param.storeVideoAlbumId) || undefined, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useStoreVideoAlbumQueryKey = () => {
  const [params] = useStoreVideoAlbumSearchParams();
  return ["storeVideoAlbumList", params];
};