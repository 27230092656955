import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { StoreServeClass } from "../interfaces/store-serve-class";

//新增
export const useAddStoreServeClass = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StoreServeClass>) =>
            client(`storeServeClass/add`, {
                data: params,
                method: "POST"
            }),
    );
};