import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { OrdersParcel } from "../interfaces/orders-parcel";

//列表
export const useOrdersParcelList = (ordersId?: number) => {
    const client = useHttp();
    const result = useQuery<any>(["ordersParcelList"], () =>
        client("ordersParcel/list?ordersId=" + ordersId, {})
    );
    const data = result.data?.data;
    return {...result, data };
};

//新增
export const useAddOrdersParcel = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<OrdersParcel>) =>
            client(`ordersParcel/add`, {
                data: params,
                method: "POST"
            }),
    );
};