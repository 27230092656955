import { Table, Space, Row, Col, Button, Input, Modal, message, Image, Menu } from 'antd';
import { useStoreVideoList, useDeleteStoreVideo } from '../../services/store-video-service';
import { useDebounce } from '../../utils';
import { useStoreVideoQueryKey, useStoreVideoSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
const { Search } = Input;

export const StoreVideoListPage = () => {
    const imgUrl = process.env.REACT_APP_IMG_URL;
    //参数    
    const [param, setParam] = useStoreVideoSearchParams();
    const { isLoading, error, totalElements, data: list } = useStoreVideoList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateStoreVideo = useDeleteStoreVideo;
    const { mutateAsync } = useMutateStoreVideo(
        useStoreVideoQueryKey()
    );
    const confirmDeleteStoreVideo = (storeVideoId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ storeVideoId }).then(() => {
                        message.info('删除成功');
                        setParam({
                            ...param,
                            storeVideoId: storeVideoId,
                        })
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    return (
        <div>
            <Menu selectedKeys={["storeVideoInner"]} mode="horizontal" style={{marginBottom:24}}>
                <Menu.Item key="storeVideoInner">
                    <Link to='/store/video/list'>视频管理</Link>
                </Menu.Item>
                <Menu.Item key="storeVideoAlbumInner">
                    <Link to='/store/video/album/list'>专辑管理</Link>
                </Menu.Item>
            </Menu>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/store/video/add"}>
                        <Button type="primary">新建视频</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索视频名称" 
                        style={{ width: 200 }}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            storeVideoName: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            storeVideoName: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <div className="site-layout-background">
                <Table 
                    loading = { isLoading }
                    pagination = { paginationProps } 
                    rowKey={ columns => columns.storeVideoId } 
                    columns = {[
                        {
                            title: '视频封面',
                            dataIndex: 'storeVideoCoverPreviewPic',
                            render(value, storeVideo) {
                                return (
                                    <Image
                                    width="80px"
                                    src={imgUrl + storeVideo.storeVideoCoverPreviewPic}
                                    />
                                );
                            }
                        },
                        {
                            title: '所属专辑',
                            dataIndex: 'storeVideoAlbumName',
                            key: 'storeVideoAlbumName',
                        },
                        {
                            title: '排序',
                            dataIndex: 'storeVideoSort',
                            key: 'storeVideoSort',
                        },
                        {
                            title: '是否显示',
                            dataIndex: 'storeVideoShow',
                            key: 'storeVideoShow',
                            render(value, storeVideo) {
                                return (
                                  <span>
                                    { storeVideo.storeVideoShow ? "显示" : "不显示" }
                                  </span> 
                                );
                            }
                        },
                        {
                            title: '操作',
                            key: 'action',
                            render: (value, storeVideo) => (
                                <Space size="middle">
                                    <Link to={"/store/video/update/" + storeVideo.storeVideoId}>
                                        编辑
                                    </Link>
                                    <a onClick={() => confirmDeleteStoreVideo(storeVideo.storeVideoId)}>
                                        删除
                                    </a>
                                </Space>
                            ),
                            width: 150,
                        },
                    ]}
                    dataSource={ list || [] }  />
            </div>
            <ErrorBox error={ error } />
        </div>
    )
}