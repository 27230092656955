import { Divider, Upload, Row, Col, Button, Input, Form, Radio, message, Spin, Menu } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useGoodsSpec, useEditGoodsSpec } from '../../services/goods-spec-service';
import { useGoodsSpecQueryKey } from './util';
import { useForm } from 'antd/lib/form/Form';

export const GoodsSpecUpdatePage = () => {
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    //获取数据
    const { pathname } = useLocation();
    const goodsSpecId = pathname.match(/goods\/goodsSpec\/update\/(\d+)/)?.[1]
    const { data: editingGoodsSpec, isLoading } = useGoodsSpec(
        Number(goodsSpecId)
    );    
    //表单回显
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue(editingGoodsSpec?.data)
    }, [editingGoodsSpec, form]);
    //编辑
    const useMutateGoodsSpec = useEditGoodsSpec;
    const { mutateAsync, error } = useMutateGoodsSpec(
        useGoodsSpecQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingGoodsSpec?.data, ...values }).then(() => {
            window.location.href = "/#/goods/goodsSpec/list";
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={12}>
                        <Link to={"/goods/goodsSpec/list"}>
                            <Button type="primary">规格列表</Button>
                        </Link>
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 24 }} />
                <div className="site-layout-background">
                    <Form
                        form={form}
                        {...layout}
                        name="basic"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label={"规格名称"}
                            name={"goodsSpecName"}
                            rules={[{ required: true, message: "规格名称必填" }]}
                        >
                            <Input placeholder={"请输入规格名称"} />
                        </Form.Item>

                        <Form.Item
                            label={"规格排序"}
                            name={"goodsSpecSort"}
                            rules={[{ required: true, message: "规格排序必填" }]}
                        >
                            <Input placeholder={"请输入规格排序"} />
                        </Form.Item>

                        <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                            <Button loading={ submitLoading } type="primary" htmlType="submit">
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </div>
    )
}