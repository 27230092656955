import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useTransportSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["transportId", "transportName", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, transportId: Number(param.transportId) || undefined, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useTransportQueryKey = () => {
  const [params] = useTransportSearchParams();
  return ["transportList", params];
};