import { Layout } from 'antd';
import { SiderLayout } from '../../layouts/sider';
import { HeaderLayout } from '../../layouts/header';
import { FooterLayout } from '../../layouts/footer';
import { DashboardPage } from '../dashboard/index';
import { StorePhotoListPage } from '../store-photo/list';
import { StorePhotoAddPage } from '../store-photo/add';
import { StorePhotoUpdatePage } from '../store-photo/update';
import { StorePhotoAlbumListPage } from '../store-photo-album/list';
import { StorePhotoAlbumAddPage } from '../store-photo-album/add';
import { StorePhotoAlbumUpdatePage } from '../store-photo-album/update';
import { StoreVideoListPage } from '../store-video/list';
import { StoreVideoAddPage } from '../store-video/add';
import { StoreVideoUpdatePage } from '../store-video/update';
import { StoreVideoAlbumListPage } from '../store-video-album/list';
import { StoreVideoAlbumAddPage } from '../store-video-album/add';
import { StoreVideoAlbumUpdatePage } from '../store-video-album/update';
import { GoodsListPage } from '../goods/list';
import { GoodsAddPage } from '../goods/add';
import { GoodsUpdatePage } from '../goods/update';
import { GoodsClassListPage } from '../goods-class/list';
import { GoodsClassAddPage } from '../goods-class/add';
import { GoodsClassUpdatePage } from '../goods-class/update';
import { GoodsSpecListPage } from '../goods-spec/list';
import { GoodsSpecAddPage } from '../goods-spec/add';
import { GoodsSpecUpdatePage } from '../goods-spec/update';
import { GoodsSpecValueListPage } from '../goods-spec-value/list';
import { GoodsSpecValueAddPage } from '../goods-spec-value/add';
import { GoodsSpecValueUpdatePage } from '../goods-spec-value/update';
import { GoodsEvaluateListPage } from '../goods-evaluate/list';
import { ServeListPage } from '../serve/list';
import { ServeAddPage } from '../serve/add';
import { ServeUpdatePage } from '../serve/update';
import { ServeEvaluateListPage } from '../serve-evaluate/list';
import { OrdersListPage } from '../orders/list';
import { OrdersDetailPage } from '../orders/detail';
import { OrdersBatchDeliveryPage } from '../orders-batch-delivery/index';
import { OrdersRefundListPage } from '../orders-refund/list';
import { OrdersRefundDetailPage } from '../orders-refund/detail';
import { OrdersReturnListPage } from '../orders-return/list';
import { OrdersReturnDetailPage } from '../orders-return/detail';
import { CarWashRecordListPage } from '../car-wash-record/list';
import { StoreMoneyListPage } from '../store-money/list';
import { StoreWithdrawCashAddPage } from '../store-withdraw-cash/add';
import { StoreWithdrawCashListPage } from '../store-withdraw-cash/list';
import { StoreBankAccountListPage } from '../store-bank-account/list';
import { StoreBankAccountAddPage } from '../store-bank-account/add';
import { StoreBankAccountUpdatePage } from '../store-bank-account/update';
import { StoreBillListPage } from '../store-bill/list';
import { StoreAccountListPage } from '../store-account/list';
import { StoreAccountAddPage } from '../store-account/add';
import { StoreAccountUpdatePage } from '../store-account/update';
import { StoreUpdatePage } from '../store/update';
import { StoreServeClassUpdatePage } from '../store-serve-class/update';
import { TransportListPage } from '../transport/list';
import { TransportAddPage } from '../transport/add';
import { TransportUpdatePage } from '../transport/update';
import { TransportDetailListPage } from '../transport-detail/list';
import { TransportDetailAddPage } from '../transport-detail/add';
import { TransportDetailUpdatePage } from '../transport-detail/update';
import { Navigate, Route, Routes } from "react-router";
import { HashRouter as Router } from "react-router-dom";
const { Content } = Layout;

export const AuthenticatedApp = () => {
    return (
        <Layout>
            <Router>
                <SiderLayout />
                <Layout className="site-layout" style={{ marginLeft: 150 }}>
                    <HeaderLayout />
                    <Content style={{ margin: '24px 16px 0', padding: 24, minHeight: 'calc(100vh - 150px)', overflow: 'initial', background: '#fff' }}>
                        <Routes>
                            <Route path={"/dashboard"} element={<DashboardPage />} />
                            <Route path={"/store/photo/list"} element={<StorePhotoListPage />} />
                            <Route path={"/store/photo/add"} element={<StorePhotoAddPage />} />
                            <Route path={"/store/photo/update/:storePhotoId"} element={<StorePhotoUpdatePage />} />
                            <Route path={"/store/photo/album/list"} element={<StorePhotoAlbumListPage />} />
                            <Route path={"/store/photo/album/add"} element={<StorePhotoAlbumAddPage />} />
                            <Route path={"/store/photo/album/update/:storePhotoAlbumId"} element={<StorePhotoAlbumUpdatePage />} />
                            <Route path={"/store/video/list"} element={<StoreVideoListPage />} />
                            <Route path={"/store/video/add"} element={<StoreVideoAddPage />} />
                            <Route path={"/store/video/update/:storeVideoId"} element={<StoreVideoUpdatePage />} />
                            <Route path={"/store/video/album/list"} element={<StoreVideoAlbumListPage />} />
                            <Route path={"/store/video/album/add"} element={<StoreVideoAlbumAddPage />} />
                            <Route path={"/store/video/album/update/:storeVideoAlbumId"} element={<StoreVideoAlbumUpdatePage />} />
                            <Route path={"/goods/goodsManage/list"} element={<GoodsListPage />} />
                            <Route path={"/goods/goodsManage/add"} element={<GoodsAddPage />} />
                            <Route path={"/goods/goodsManage/update/:goodsId"} element={<GoodsUpdatePage />} />
                            <Route path={"/goods/goodsSpec/list"} element={<GoodsSpecListPage />} />
                            <Route path={"/goods/goodsSpec/add"} element={<GoodsSpecAddPage />} />
                            <Route path={"/goods/goodsSpec/update/:goodsSpecId"} element={<GoodsSpecUpdatePage />} />
                            <Route path={"/goods/goodsClass/list"} element={<GoodsClassListPage />} />
                            <Route path={"/goods/goodsClass/add"} element={<GoodsClassAddPage />} />
                            <Route path={"/goods/goodsClass/update/:goodsClassId"} element={<GoodsClassUpdatePage />} />                            
                            <Route path={"/goods/goodsSpec/goodsSpecValue/list/:goodsSpecId"} element={<GoodsSpecValueListPage />} />
                            <Route path={"/goods/goodsSpec/goodsSpecValue/add/:goodsSpecId"} element={<GoodsSpecValueAddPage />} />
                            <Route path={"/goods/goodsSpec/goodsSpecValue/update/:goodsSpecValueId"} element={<GoodsSpecValueUpdatePage />} />
                            <Route path={"/goods/goodsEvaluate/list"} element={<GoodsEvaluateListPage />} />
                            <Route path={"/goods/serve/list"} element={<ServeListPage />} />
                            <Route path={"/goods/serve/add"} element={<ServeAddPage />} />
                            <Route path={"/goods/serve/update/:serveId"} element={<ServeUpdatePage />} />
                            <Route path={"/goods/serveEvaluate/list"} element={<ServeEvaluateListPage />} />
                            <Route path={"/orders/ordersManage/list"} element={<OrdersListPage />} />
                            <Route path={"/orders/ordersManage/detail/:ordersId"} element={<OrdersDetailPage />} />
                            <Route path={"/orders/batchDelivery"} element={<OrdersBatchDeliveryPage />} />
                            <Route path={"/orders/refund/list"} element={<OrdersRefundListPage />} />
                            <Route path={"/orders/refund/detail/:ordersRefundId"} element={<OrdersRefundDetailPage />} />
                            <Route path={"/orders/return/list"} element={<OrdersReturnListPage />} />
                            <Route path={"/orders/return/detail/:ordersReturnId"} element={<OrdersReturnDetailPage />} />
                            <Route path={"/carWash/record/list"} element={<CarWashRecordListPage />} />
                            <Route path={"/asset/storeMoney/list"} element={<StoreMoneyListPage />} />
                            <Route path={"/asset/storeWithdrawCash/add"} element={<StoreWithdrawCashAddPage />} />
                            <Route path={"/asset/storeWithdrawCash/list"} element={<StoreWithdrawCashListPage />} />
                            <Route path={"/asset/storeWithdrawCash/storeBankAccount/list"} element={<StoreBankAccountListPage />} />
                            <Route path={"/asset/storeWithdrawCash/storeBankAccount/add"} element={<StoreBankAccountAddPage />} />
                            <Route path={"/asset/storeWithdrawCash/storeBankAccount/update/:storeBankAccountId"} element={<StoreBankAccountUpdatePage />} />
                            <Route path={"/asset/storeBill/list"} element={<StoreBillListPage />} />
                            <Route path={"/setting/storeAccount/list"} element={<StoreAccountListPage />} />
                            <Route path={"/setting/storeAccount/add"} element={<StoreAccountAddPage />} />
                            <Route path={"/setting/changePwd/update"} element={<StoreAccountUpdatePage />} />
                            <Route path={"/setting/store/update"} element={<StoreUpdatePage />} />
                            <Route path={"/setting/storeServeClass/update"} element={<StoreServeClassUpdatePage />} />
                            <Route path={"/setting/transport/list"} element={<TransportListPage />} />
                            <Route path={"/setting/transport/add"} element={<TransportAddPage />} />
                            <Route path={"/setting/transport/update"} element={<TransportUpdatePage />} />
                            <Route path={"/setting/transport/detail/list/:transportId"} element={<TransportDetailListPage />} />
                            <Route path={"/setting/transport/detail/add/:transportId"} element={<TransportDetailAddPage />} />
                            <Route path={"/setting/transport/detail/update/:transportId"} element={<TransportDetailUpdatePage />} />
                            <Navigate to={"/dashboard"} />
                        </Routes>
                    </Content>
                    <FooterLayout />
                </Layout>
            </Router>
        </Layout>
    )
}
