import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { StoreVideoAlbum } from "../interfaces/store-video-album";

//列表
export const useStoreVideoAlbumList = (param?: Partial<StoreVideoAlbum>) => {
    const client = useHttp();
    const result = useQuery<any>(["storeVideoAlbumList", param], () =>
        client("storeVideoAlbum/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//全部列表
export const useStoreVideoAlbumListAll = (param?: Partial<StoreVideoAlbum>) => {
    const client = useHttp();
    const result = useQuery<any>(["storeVideoAlbumListAll", param], () =>
        client("storeVideoAlbum/listAll", { data: param })
    );
    const data = result.data?.data;
    return {...result, data };
};

//新增
export const useAddStoreVideoAlbum = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StoreVideoAlbum>) =>
            client(`storeVideoAlbum/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑
export const useEditStoreVideoAlbum = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StoreVideoAlbum>) =>
            client(`storeVideoAlbum/update?storeVideoAlbumId=${params.storeVideoAlbumId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除
export const useDeleteStoreVideoAlbum = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ storeVideoAlbumId }: { storeVideoAlbumId: number}) =>
            client(`storeVideoAlbum/delete?storeVideoAlbumId=${storeVideoAlbumId}`, {
            method: "DELETE",
        })
    );
};


//详情
export const useStoreVideoAlbum = (storeVideoAlbumId?: number) => {
    const client = useHttp();
    return useQuery<any>(["storeVideoAlbumList", { storeVideoAlbumId }], () =>
        client(`storeVideoAlbum/detail?storeVideoAlbumId=${storeVideoAlbumId}`),
        {
            enabled: Boolean(storeVideoAlbumId)
        }
    );
};

