import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { Transport } from "../interfaces/transport";

//列表
export const useTransportList = (param?: Partial<Transport>) => {
    const client = useHttp();
    const result = useQuery<any>(["transportList", param], () =>
        client("transport/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增
export const useAddTransport = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<Transport>) =>
            client(`transport/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑
export const useEditTransport = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<Transport>) =>
            client(`transport/update?transportId=${params.transportId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除
export const useDeleteTransport = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ transportId }: { transportId: number}) =>
            client(`transport/delete?transportId=${transportId}`, {
            method: "DELETE",
        })
    );
};


//详情
export const useTransport = (transportId?: number) => {
    const client = useHttp();
    return useQuery<any>(["transport", { transportId }], () =>
        client(`transport/detail?transportId=${transportId}`),
        {
            enabled: Boolean(transportId)
        }
    );
};


export const useTransportListAll = () => {
    const client = useHttp();
    const result = useQuery<any>(["transportListAll", { }], () =>
        client(`transport/listAll`)
    );
    const data = result.data?.data;
    return {...result, data };
};

