import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useOrdersSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["ordersId", "ordersGoodsIds", "ordersSn", "startOrdersCreateTime", "endOrdersCreateTime", "ordersReceiverName", "ordersReceiverTel", "ordersGoodsInfo", "ordersState", "ordersType", "typeId", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, ordersId: Number(param.ordersId) || undefined, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useOrdersQueryKey = () => {
  const [params] = useOrdersSearchParams();
  return ["ordersList", params];
};