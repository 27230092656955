import { Divider, Upload, Row, Col, Button, Input, Form, Radio, message, Select, Spin, Menu } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useStorePhoto, useEditStorePhoto } from '../../services/store-photo-service';
import { useStorePhotoAlbumListAll } from '../../services/store-photo-album-service';
import { useStorePhotoQueryKey } from './util';
import { useForm } from 'antd/lib/form/Form';
const { Option } = Select;

export const StorePhotoUpdatePage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const imgUrl = process.env.REACT_APP_IMG_URL;
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    //准备相册
    const { data: storePhotoAlbumList } = useStorePhotoAlbumListAll();
    const options = storePhotoAlbumList?.map((item: any, value: any) => 
        <Option value={item.storePhotoAlbumId} key={item.storePhotoAlbumId}>{item.storePhotoAlbumName}</Option>
    );
    //获取数据
    const { pathname } = useLocation();
    const storePhotoId = pathname.match(/store\/photo\/update\/(\d+)/)?.[1]
    const { data: editingStorePhoto, isLoading } = useStorePhoto(
        Number(storePhotoId)
    );    
    const [imageUrl, setImageUrl] = useState("");
    //表单回显
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue(editingStorePhoto?.data)
        const picUrl = imgUrl + editingStorePhoto?.data?.storePhotoUrl;
        console.log(picUrl);
        setImageUrl(picUrl)
    }, [editingStorePhoto, form]);
    //图片
    const [uploadLoading, setUploadLoading] = useState(false);
    const uploadButton = (
        <div>
          {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const beforeUpload = (file:any) => {
        setImageUrl("");
        setUploadLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadLoading(false);
            setImageUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                storePhotoUrl: relativePath
            })
        }
    };
    const normFile = (e: any) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //编辑
    const useMutateStorePhoto = useEditStorePhoto;
    const { mutateAsync, error } = useMutateStorePhoto(
        useStorePhotoQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingStorePhoto?.data, ...values }).then(() => {
            window.location.href = "/#/store/photo/list";
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Menu selectedKeys={["storePhotoInner"]} mode="horizontal" style={{marginBottom:24}}>
                    <Menu.Item key="storePhotoInner">
                        <Link to='/store/photo/list'>照片管理</Link>
                    </Menu.Item>
                    <Menu.Item key="storePhotoAlbumInner">
                        <Link to='/store/photo/album/list'>相册管理</Link>
                    </Menu.Item>
                </Menu>
                <Row>
                    <Col span={12}>
                        <Link to={"/store/photo/list"}>
                            <Button type="primary">相片列表</Button>
                        </Link>
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 20 }} />
                <div className="site-layout-background">
                    <Form
                        form={form}
                        {...layout}
                        name="basic"
                        onFinish={onFinish}
                    >
                        <Form.Item
                                label={"所属相册"}
                                name={"storePhotoAlbumId"}
                                rules={[{ required: true, message: "请选择相册" }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="请选择相册"
                                    optionFilterProp="children"
                                    style={{textAlign:'left'}}
                                >
                                    {options}
                                </Select>
                        </Form.Item>
                        <Form.Item
                            label={"相片名称"}
                            name={"storePhotoName"}
                            rules={[{ required: true, message: "相片名称必填" }]}
                        >
                            <Input placeholder={"请输入相片名称"} />
                        </Form.Item>

                        <Form.Item
                            name={"uploadPic"}
                            label={"相片图片"}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[{ required: true, message: "相片图片必传" }]}
                            style={{ textAlign:'left' }}
                            initialValue={[
                                {
                                "uid": "",
                                "lastModified": "",
                                "lastModifiedDate": "",
                                "name": "",
                                "size": "",
                                "type": "",
                                "percent": "",
                                "originFileObj": {
                                    "uid": ""
                                },
                                "status": "done",
                                "response": {
                                    "status": 0,
                                    "data": {
                                    "relativePath": "",
                                    "realPath": ""
                                    }
                                },
                                "xhr": {}
                                }
                            ]}
                            >
                                <Upload
                                    name="imageFile"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action={apiUrl + "/upload/img"}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>

                        <Form.Item hidden
                            label={"相片图片"}
                            name={"storePhotoUrl"}
                            rules={[{ required: true, message: "相片图片必传" }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={"相片排序"}
                            name={"storePhotoSort"}
                            rules={[{ required: true, message: "相片排序必填" }]}
                        >
                            <Input placeholder={"请输入相片排序"} />
                        </Form.Item>

                        <Form.Item
                            label={"是否显示"}
                            name={"storePhotoShow"}
                            rules={[{ required: true, message: "相片显示必选" }]}
                            style={{ textAlign:'left' }}
                        >
                            <Radio.Group>
                                <Radio value={true}>显示</Radio>
                                <Radio value={false}>不显示</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                            <Button loading={ submitLoading } type="primary" htmlType="submit">
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </div>
    )
}