import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { StoreBill } from "../interfaces/store-bill";

//列表
export const useStoreBillList = (param?: Partial<StoreBill>) => {
    const client = useHttp();
    const result = useQuery<any>(["storeBillList", param], () =>
        client("storeBill/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};