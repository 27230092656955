import { Table, Space, Row, Col, Button, Input, Modal, message, Card, Form, DatePicker } from 'antd';
import { useStoreBillList } from '../../services/store-bill-service';
import { useDebounce } from '../../utils';
import { useStoreBillQueryKey, useStoreBillSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import { useState } from 'react';
const { Search } = Input;
const { RangePicker } = DatePicker;

export const StoreBillListPage = () => {
    //参数
    const [param, setParam] = useStoreBillSearchParams();
    const { isLoading, error, totalElements, data: list } = useStoreBillList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //时间
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    const [startStoreBillDate, setStartStoreBillDate] = useState();
    const [endStoreBillDate, setEndStoreBillDate] = useState();
    const handleSelectTime = (value:any ,dateString:any) => {
        setStartStoreBillDate(dateString[0]);
        setEndStoreBillDate(dateString[1]);
    }
    //完成
    const onFinish = (values: any) => {
        setParam({
            ...param,
            startStoreBillDate: startStoreBillDate,
            endStoreBillDate: endStoreBillDate,
            pageNum: null
        })
    }
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Form.Item name="billDetailDateRange" label="对账日期" {...rangeConfig}>
                        <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item>
                    <Button style={{ marginLeft:70 }} type="primary" htmlType="submit">
                        提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <div className="site-layout-background">
                <Table
                    loading = { isLoading }
                    pagination = { paginationProps }
                    rowKey={ columns => columns.storeBillId }
                    columns = {[
                        {
                            title: '日期',
                            dataIndex: 'storeBillDate',
                            key: 'storeBillDate',
                            render(value, storeBill) {
                                return <span>
                                    {storeBill.storeBillDate ? dayjs(storeBill.storeBillDate).format('YYYY-MM-DD'):'无'}
                                </span>
                            },
                        },
                        {
                            title: '收入(元)',
                            dataIndex: 'storeBillIncomeMoney',
                            key: 'storeBillIncomeMoney',
                            render(value, storeBill) {
                                return <span>
                                    + {storeBill.storeBillIncomeMoney}<br/>
                                    {storeBill.storeBillIncomeNum}笔
                                </span>
                            },
                        },
                        {
                            title: '支出(元)',
                            dataIndex: 'storeBillPayMoney',
                            key: 'storeBillPayMoney',
                            render(value, storeBill) {
                                return <span>
                                    - {storeBill.storeBillPayMoney}<br/>
                                    {storeBill.storeBillPayNum}笔
                                </span>
                            },
                        },
                    ]}
                    dataSource={ list || [] }  />
            </div>
            <ErrorBox error={ error } />
        </div>
    )
}
