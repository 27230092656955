import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { OrdersReturn } from "../interfaces/orders-return";

//列表
export const useOrdersReturnList = (param?: Partial<OrdersReturn>) => {
    const client = useHttp();
    const result = useQuery<any>(["ordersReturnList", param], () =>
        client("ordersReturn/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//详情
export const useOrdersReturn = (ordersReturnId?: number) => {
    const client = useHttp();
    return useQuery<any>(["useOrdersReturnDetail", { ordersReturnId }], () =>
        client(`ordersReturn/detail?ordersReturnId=${ordersReturnId}`),
        {
            enabled: Boolean(ordersReturnId)
        }
    );
};

//商品详情
export const useOrdersReturnOrdersGoods = (ordersReturnId?: number) => {
    const client = useHttp();
    return useQuery<any>(["ordersReturnOrdersGoodsDetail", { ordersReturnId }], () =>
        client(`ordersReturn/ordersGoods?ordersReturnId=${ordersReturnId}`),
        {
            enabled: Boolean(ordersReturnId)
        }
    );
};

//同意退款并发送地址
export const useAgreeOrdersReturn = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ ordersReturnId, storeAddressId }: { ordersReturnId: number, storeAddressId: number}) =>
            client(`ordersReturn/agree?ordersReturnId=${ordersReturnId}&storeAddressId=${storeAddressId}`, {
            method: "GET",
        })
    );
};

//拒绝退款
export const useRejectOrdersReturn = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<OrdersReturn>) =>
            client(`ordersReturn/reject?ordersReturnId=${params.ordersReturnId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//确认收货并退款
export const useAgreeOrdersReturnFinal = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ ordersReturnId }: { ordersReturnId: number}) =>
            client(`ordersReturn/agreeOrdersReturn?ordersReturnId=${ordersReturnId}`, {
            method: "GET",
        })
    );
};

//拒绝收货
export const useRejectReceive = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ ordersReturnId }: { ordersReturnId: number}) =>
            client(`ordersReturn/rejectReceive?ordersReturnId=${ordersReturnId}`, {
            method: "GET",
        })
    );
};

