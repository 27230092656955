import { Table, Space, Row, Col, Button, Input, Modal, message, Image, Menu } from 'antd';
import { useStorePhotoList, useDeleteStorePhoto } from '../../services/store-photo-service';
import { useDebounce } from '../../utils';
import { useStorePhotoQueryKey, useStorePhotoSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
const { Search } = Input;

export const StorePhotoListPage = () => {
    const imgUrl = process.env.REACT_APP_IMG_URL;
    //参数
    const [param, setParam] = useStorePhotoSearchParams();
    const { isLoading, error, totalElements, data: list } = useStorePhotoList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateStorePhoto = useDeleteStorePhoto;
    const { mutateAsync } = useMutateStorePhoto(
        useStorePhotoQueryKey()
    );
    const confirmDeleteStorePhoto = (storePhotoId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ storePhotoId }).then(() => {
                        message.info('删除成功');
                        setParam({
                            ...param,
                            storePhotoId: storePhotoId,
                        })
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    return (
        <div>
            <Menu selectedKeys={["storePhotoInner"]} mode="horizontal" style={{marginBottom:24}}>
                <Menu.Item key="storePhotoInner">
                    <Link to='/store/photo/list'>照片管理</Link>
                </Menu.Item>
                <Menu.Item key="storePhotoAlbumInner">
                    <Link to='/store/photo/album/list'>相册管理</Link>
                </Menu.Item>
            </Menu>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/store/photo/add"}>
                        <Button type="primary">新建照片</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search
                        placeholder="可搜索照片名称"
                        style={{ width: 200 }}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            storePhotoName: evt.target.value,
                            pageNum: null
                        })}
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            storePhotoName: value,
                            pageNum: null
                        })}
                    />
                </Col>
            </Row>
            <div className="site-layout-background">
                <Table
                    loading = { isLoading }
                    pagination = { paginationProps }
                    rowKey={ columns => columns.storePhotoId }
                    columns = {[
                        {
                            title: '照片',
                            dataIndex: 'storePhotoUrl',
                            render(value, storePhoto) {
                                return (
                                    <Image
                                    width="80px"
                                    src={imgUrl + storePhoto.storePhotoUrl}
                                    />
                                );
                            }
                        },
                        {
                            title: '所属相册',
                            dataIndex: 'storePhotoAlbumName',
                            key: 'storePhotoAlbumName',
                        },
                        {
                            title: '排序',
                            dataIndex: 'storePhotoSort',
                            key: 'storePhotoSort',
                        },
                        {
                            title: '是否显示',
                            dataIndex: 'storePhotoShow',
                            key: 'storePhotoShow',
                            render(value, storePhoto) {
                                return (
                                  <span>
                                    { storePhoto.storePhotoShow ? "显示" : "不显示" }
                                  </span>
                                );
                            }
                        },
                        {
                            title: '操作',
                            key: 'action',
                            render: (value, storePhoto) => (
                                <Space size="middle">
                                    <Link to={"/store/photo/update/" + storePhoto.storePhotoId}>
                                        编辑
                                    </Link>
                                    <a onClick={() => confirmDeleteStorePhoto(storePhoto.storePhotoId)}>
                                        删除
                                    </a>
                                </Space>
                            ),
                            width: 150,
                        },
                    ]}
                    dataSource={ list || [] }  />
            </div>
            <ErrorBox error={ error } />
        </div>
    )
}
