import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";

export const useOrdersRefundLogList = (ordersRefundId?: number) => {
    const client = useHttp();
    const result = useQuery<any>(["ordersRefundLogList"], () =>
        client("ordersRefundLog/listAll?ordersRefundId=" + ordersRefundId, {})
    );
    const data = result.data?.data;
    return {...result, data };
};