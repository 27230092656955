import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { GoodsSku } from "../interfaces/goods-sku";

export const useGoodsSkuList = () => {
    const client = useHttp();
    const result = useQuery<any>(["goodsSkuList", { }], () =>
        client(`goodsSku/create`)
    );
    const data = result.data?.data;
    return {...result, data };
};


export const useGoodsSkuCreateList = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ goodsSpecValueIds }: { goodsSpecValueIds: number[]}) =>
            client(`goodsSku/createSku?goodsSpecValueIds=${goodsSpecValueIds}`, {
            method: "GET",
        })
    );
};

// export const useGoodsSkuListByGoodsId = (goodsId?: number) => {
//     const client = useHttp();
//     return useQuery<any>(["goodsSkuList", { goodsId }], () =>
//         client(`goodsSku/findByGoodsId?goodsId=${goodsId}`),
//         {
//             enabled: Boolean(goodsId)
//         }
//     );
// };

export const useGoodsSkuListByGoodsId = (goodsId?: number) => {
    const client = useHttp();
    const result = useQuery<any>(["goodsSkuList"], () =>
        client("goodsSku/findByGoodsId?goodsId=" + goodsId, {})
    );
    const data = result.data?.data;
    return {...result, data };
};

