import { LoginPage } from "./login"
import React, { useState } from "react";
import { Button, Card, Divider } from "antd";
import styled from "@emotion/styled";
import logo from "../../assets/logo.png";
import bg from "../../assets/bg.svg";
import { RegisterPage } from "./register";
import { ErrorBox } from "../../components/lib";

export const UnAuthenticatedApp = () => {
    const [isRegister, setIsRegister] = useState(false);
    const [error, setError ] = useState<Error | null>(null);

    return (
        <Container style={{ display: "flex", justifyContent: "center"}}>
            <Header />
            <Background />
            <ShadowCard>
                <Title>{isRegister ? "请注册" : "请登录"}</Title>
                <ErrorBox error={error} />
                {isRegister ? (
                  <RegisterPage />
                ) : (
                  <LoginPage onError={setError} />
                )}
                <Divider />
                {/* <Button type={"link"} onClick={() => setIsRegister(!isRegister)}>
                    {isRegister ? "已经有账号了？直接登录" : "没有账号？注册新账号"}
                </Button> */}
            </ShadowCard>
            <Footer>
              Copyright © 2022 All Rights Reserved 厦门荟盈星街置业有限公司
            </Footer>
        </Container>
    );
}

export const LongButton = styled(Button)`
  width: 100%;
`;

const Title = styled.h2`
  margin-bottom: 2.4rem;
  color: rgb(94, 108, 132);
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left bottom, right bottom;
  background-image: url(${bg});
`;


const Footer = styled.div`
  width: 100%;
  text-align: center;
  height: 5rem;
  line-height: 5rem;
`;

const Header = styled.header`
    background: url(${logo}) no-repeat center;
    padding: 5rem 0;
    background-size: 12rem;
    width: 100%;
`;

const ShadowCard = styled(Card)`
    width: 40rem;
    min-height: 50rem;
    padding: 3.2rem 4rem;
    border-radius: 0.3rem;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
    text-align: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
`;

