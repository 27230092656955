import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { TransportDetail } from "../interfaces/transport-detail";


export const useTransportDetailList = (param?: Partial<TransportDetail>, transportId?: any) => {
    const client = useHttp();
    const result = useQuery<any>(["transportDetailList", param], () =>
        client("transportDetail/list?transportId=" + transportId, { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增专题
export const useAddTransportDetail = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<TransportDetail>) =>
            client(`transportDetail/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑
export const useEditTransportDetail = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<TransportDetail>) =>
            client(`transportDetail/update`, {
                data: params,
                method: "PUT"
            }),
    );
};

//详情
export const useTransportDetail = (transportDetailId?: number) => {
    const client = useHttp();
    return useQuery<any>(["transportDetail", { transportDetailId }], () =>
        client(`transportDetail/detail?transportDetailId=${transportDetailId}`),
        {
            enabled: Boolean(transportDetailId)
        }
    );
};

//删除
export const useDeleteTransportDetail = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ transportDetailId }: { transportDetailId: number}) =>
            client(`transportDetail/delete?transportDetailId=${transportDetailId}`, {
            method: "DELETE",
        })
    );
};

