import { Button, Col, Row, Space, Steps, Table, Typography, Image, Tabs } from "antd";
import { useLocation } from "react-router";
import { useOrders } from '../../services/orders-service';
import { useOrdersAddress } from '../../services/orders-address-service';
import { useOrdersGoodsList } from '../../services/orders-goods-service';
import { useOrdersParcelList } from '../../services/orders-parcel-service';
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
const { Text, Link, Title } = Typography;
const { Step } = Steps;
const { TabPane } = Tabs;

export const OrdersDetailPage = () => {
    const imgUrl = process.env.REACT_APP_IMG_URL;
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //获取数据
    const { pathname } = useLocation();
    const ordersId = pathname.match(/orders\/ordersManage\/detail\/(\d+)/)?.[1]
    //详情
    const { data: orders, isLoading } = useOrders(
        Number(ordersId)
    ); 
    const [ordersState, setOrdersState] = useState(0); 
    const [currentStep, setCurrentStep] = useState(0); 
    const os = orders?.data?.ordersState
    //订单地址
    const { data: ordersAddress } = useOrdersAddress(
        Number(ordersId)
    ); 
    //订单商品
    const { data: ordersGoodsList } = useOrdersGoodsList(
        Number(ordersId)
    );
    //包裹
    const { data: ordersParcelList } = useOrdersParcelList(
        Number(ordersId)
    )
    //进度条
    useEffect(() =>{
        if(Number(os) === 10) {
            setCurrentStep(0)
        }
        if(Number(os) === 20) {
            setCurrentStep(1)
        }
        if(Number(os) === 30) {
            setCurrentStep(2)
        }
        if(Number(os) === 35) {
            setCurrentStep(1)
        }
        if(Number(os) === 40) {
            setCurrentStep(3)
        }
        setOrdersState(os)
    }, [os])
    return (
        <div>
            <Row style={{fontSize: 14, marginBottom: 24}}>
                <Text style={{marginRight:30}}>
                    订单编号： {orders?.data?.ordersSn}
                </Text>
                <Text>
                    申请时间： {orders?.data?.ordersCreateTime ? dayjs(orders?.data?.ordersCreateTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                </Text>
            </Row>
            <Row style={{ marginBottom: 24 }}>
                <Col span={8} style={{ width: "100%", padding:20, border: "1px solid #f0f2f5" }}>
                    <Title level={4}>
                        {orders?.data?.ordersState == 10 ? '商品已拍下，等待买家付款':''}
                        {orders?.data?.ordersState == 20 ? '等待商家发货':''}
                        {orders?.data?.ordersState == 30 ? '商家已发货，等待交易成功':''}
                        {orders?.data?.ordersState == 35 ? '等待买家上门服务':''}
                        {orders?.data?.ordersState == 40 ? '交易完成':''}
                        {orders?.data?.ordersState == 50 ? '退款成功':''}
                    </Title>
                    <Space direction="vertical" style={{marginBottom: 20}}>
                        {orders?.data?.ordersState == 10 ? <div>
                            <Text>如买家未在{orders?.data?.ordersAutoCancelTime ? dayjs(orders?.data?.ordersAutoCancelTime).format('YYYY-MM-DD HH:mm:ss'):'' }内付款，订单将按照设置逾期自动关闭</Text>
                        </div>:''}
                        {orders?.data?.ordersState == 20 ?  <div>
                            <Text>买家已付款，请尽快发货，否则买家有权申请退款</Text>
                            {/* <Text>买家有申请退款，需先处理完退款申请才能进行后续发货</Text> */}
                        </div>:''}
                        {orders?.data?.ordersState == 30 ? <div>
                            <Text>买家如在7天没有申请退款，交易将自动完成</Text>
                        </div>:''}
                        {orders?.data?.ordersState == 35 ? <div>
                            <Text></Text>
                        </div>:''}
                        {orders?.data?.ordersState == 40 ? <div>
                            <Text></Text>
                        </div>:''}
                        {orders?.data?.ordersState == 50 ? <div>
                            <Text>退款金额：￥{orders?.data?.ordersRefundMoney}</Text>
                        </div>:''}
                    </Space>
                    {/* {orders?.data?.ordersState == 10 ? <div>
                        <Button type="primary" style={{marginRight: 10}}>取消订单</Button>
                    </div>:''}
                    {orders?.data?.ordersState == 20 ? <div>
                        <Button type="primary" style={{marginRight: 10}}>发货</Button>
                    </div>:''}
                    {orders?.data?.ordersState == 30 ? <div>
                        <Button type="primary" style={{marginRight: 10}}>修改物流</Button>
                    </div>:''} */}
                </Col>

                <Col span={16} style={{ padding:20, borderTop: "1px solid #f0f2f5", borderRight: "1px solid #f0f2f5", borderBottom: "1px solid #f0f2f5" }}>
                    <Steps current={currentStep}>
                        <Step title="买家下单" description={orders?.data?.ordersCreateTime ? dayjs(orders?.data?.ordersCreateTime).format('YYYY-MM-DD HH:mm:ss'):''} />
                        <Step title="买家付款" description={orders?.data?.ordersPaymentTime ? dayjs(orders?.data?.ordersPaymentTime).format('YYYY-MM-DD HH:mm:ss'):''} />
                        <Step title="商家发货/核销" description={orders?.data?.ordersShippingTime ? dayjs(orders?.data?.ordersShippingTime).format('YYYY-MM-DD HH:mm:ss'):''} />  
                        <Step title="交易成功" description={orders?.data?.ordersFinishedTime ? dayjs(orders?.data?.ordersFinishedTime).format('YYYY-MM-DD HH:mm:ss'):''} />
                    </Steps>
                </Col>
            </Row>
            <Row style={{ backgroundColor: '#f0f2f5', marginBottom: 24  }}>
                <Col span={6} style={{  width: "100%", padding:20 }}>
                    <Space direction="vertical">
                        <Text strong>收货人信息</Text>
                        <Text>收货人：{ordersAddress?.data?.ordersAddressReceiverName ? ordersAddress?.data?.ordersAddressReceiverName : orders?.data?.ordersReceiverName}</Text>
                        <Text>联系电话：{ordersAddress?.data?.ordersAddressReceiverTel ? ordersAddress?.data?.ordersAddressReceiverTel : orders?.data?.ordersReceiverTel}</Text>
                        <Text>收货地址：{ordersAddress?.data?.ordersAddressProvince}{ordersAddress?.data?.ordersAddressCity}{ordersAddress?.data?.ordersAddressDistrict}{ordersAddress?.data?.ordersAddressDetail}</Text>
                    </Space>
                </Col>
                <Col span={6} style={{  width: "100%", padding:20 }}>
                    <Space direction="vertical">
                        <Text strong>配送信息</Text>
                        <Text>配送方式：快递</Text>
                        <Text>发货时间：{orders?.data?.ordersShippingTime ? dayjs(orders?.data?.ordersShippingTime).format('YYYY-MM-DD HH:mm:ss'):'无'}</Text>
                    </Space>
                </Col>
                <Col span={6} style={{  width: "100%", padding:20 }}>
                    <Space direction="vertical">
                        <Text strong>付款信息</Text>
                        <Text>实付金额：{orders?.data?.ordersActuallyAmount}</Text>
                        <Text>付款方式：微信支付</Text>
                        <Text>付款时间：{orders?.data?.ordersPaymentTime ? dayjs(orders?.data?.ordersPaymentTime).format('YYYY-MM-DD HH:mm:ss'):'无'}</Text>
                    </Space>
                </Col>
                <Col span={6} style={{  width: "100%", padding:20 }}>
                    <Space direction="vertical">
                        <Text strong>买家信息</Text>
                        <Text>买家：{orders?.data?.memberNickname}</Text>
                        <Text>买家留言：</Text>
                    </Space>
                </Col>
            </Row>
            <Row style={{marginBottom: 24}}>
                <Tabs type="card" style={{width:"100%"}}>
                    {/* ordersParcelList */}
                    {
                        ordersParcelList?.map((item: any, index: any, value: any) => 
                            <TabPane tab={"包裹" + (index + 1)} key={index + 1}>
                                <Row style={{width:"100%", padding:20, border: "1px solid #f0f2f5"}}>
                                    <Col span={8} style={{ padding:20 }}>
                                        <Col span={24} style={{marginBottom:10}}>
                                            <Space direction="vertical">
                                                <Text>发货方式：快递</Text>
                                                <Text>发货时间：{item?.ordersParcel?.ordersParcelAddTime ? dayjs(item?.ordersParcel?.ordersParcelAddTime).format('YYYY-MM-DD HH:mm:ss'):'无'}</Text>
                                                <Text>快递公司：{item?.ordersParcel?.expressName} <Link style={{marginLeft: 10}}>修改</Link></Text>
                                                <Text>快递单号：{item?.ordersParcel?.trackingNumber}</Text>
                                            </Space>
                                        </Col>
                                        <Col span={24}>
                                            {
                                                item?.ordersGoodsList?.map((item: any, index: any, value: any) => 
                                                    <Image style={{paddingRight: 10}} width={60} src={"https://img.ihsh.cn/upload/8/" + item.goodsPic} />        
                                                )
                                            }
                                        </Col>
                                        
                                    </Col>
                                    <Col span={16} style={{ padding:20 }}>
                                        <Space direction="vertical">
                                            <Text>物流状态：<Link>查看</Link></Text>
                                        </Space>
                                    </Col>
                                </Row>
                            </TabPane>
                        )
                    }
                </Tabs>
            </Row>
            <Row style={{marginBottom: 24}}>
                <Table
                    pagination={false}
                    style={{width:"100%"}}
                    columns = {[
                        {
                            title: '商品',
                            dataIndex: 'goodsName',
                            key: 'goodsName',
                            width: "30%",
                            render: (value, item) => (
                                <Space size="middle">
                                    <Row>
                                        <Col span={6}>
                                            <Image
                                                width="100%"
                                                src={imgUrl + item.goodsPic}
                                            />
                                        </Col>
                                        <Col span={18} style={{paddingLeft:10}}>
                                            <Row>
                                                <Col span={24} style={{marginBottom:5}}>
                                                   {item.goodsName}
                                                </Col>
                                                <Col span={24} style={{marginBottom:5}}>
                                                    规格：{item.goodsSkuInfo}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Space>
                            ),
                        },
                        {
                            title: '单价(元)',
                            dataIndex: 'goodsPrice',
                            key: 'goodsPrice',
                            width: "11.6%",
                        },
                        {
                            title: '数量',
                            dataIndex: 'goodsNum',
                            key: 'goodsNum',
                            width: "11.6%",
                        },
                        {
                            title: '优惠(元)',
                            dataIndex: 'goodsPrice',
                            key: 'goodsPrice',
                            width: "11.6%",
                        },
                        {
                            title: '小计(元)',
                            dataIndex: 'goodsTotalPrice',
                            key: 'goodsTotalPrice',
                            width: "11.6%",
                        },
                        {
                            title: '售后状态',
                            dataIndex: 'goodsReturnRefundType',
                            key: 'goodsReturnRefundType',
                            width: "11.6%",
                            render(value, ordersGoods) {
                                return <span>
                                    {ordersGoods.goodsReturnRefundType == 0 ? '无售后':''}
                                    {ordersGoods.goodsReturnRefundType == 1 ? '仅退款':''}
                                    {ordersGoods.goodsReturnRefundType == 2 ? '退货退款':''}
                                </span>
                            },
                        },
                        {
                            title: '发货状态',
                            dataIndex: 'goodsState',
                            key: 'goodsState',
                            width: "11.6%",
                            render(value, ordersRefundOrdersGoods) {
                                return <span>
                                    {ordersRefundOrdersGoods.goodsState == 30 ? '是':'否'}
                                </span>
                            },
                        },
                    ]}
                    dataSource={ ordersGoodsList || [] } />
            </Row>
        </div>
    )
}