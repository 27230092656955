import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useServeEvaluateSearchParams = () => {
    //const [param, setParam] = useUrlQueryParam(["serveEvaluateContent", "startServeEvaluateAddTime", "endServeEvaluateAddTime", "ordersSn", "serveName", "serveEvaluateScores", "serveEvaluateType", "pageNum"]);
    const [param, setParam] = useUrlQueryParam(["serveEvaluateContent", "startServeEvaluateAddTime", "endServeEvaluateAddTime", "ordersSn", "serveName", "serveEvaluateScores", "serveEvaluateType", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, pageNum: Number(param.pageNum) || undefined, serveEvaluateScores: Number(param.serveEvaluateScores) || undefined, serveEvaluateType: Number(param.serveEvaluateType) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useServeEvaluateQueryKey = () => {
  const [params] = useServeEvaluateSearchParams();
  return ["serveEvaluateList", params];
};