import { Table, Space, Row, Col, Button, Input, Modal, message, Rate, Image, Card, Form, DatePicker, Select } from 'antd';
import { useServeEvaluateList, useDeleteServeEvaluate } from '../../services/serve-evaluate-service';
import { useDebounce } from '../../utils';
import { useServeEvaluateQueryKey, useServeEvaluateSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import { useState } from 'react';
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

export const ServeEvaluateListPage = () => {
    //参数    
    const [param, setParam] = useServeEvaluateSearchParams();
    const { isLoading, error, totalElements, data: list } = useServeEvaluateList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateServeEvaluate = useDeleteServeEvaluate;
    const { mutateAsync } = useMutateServeEvaluate(
        useServeEvaluateQueryKey()
    );
    const confirmDeleteServeEvaluate = (serveEvaluateId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ serveEvaluateId }).then(() => {
                        message.info('删除成功');
                        window.location.href = "/#/goods/serveEvaluate/list";
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    //提交表单
    //时间
    const [startServeEvaluateAddTime, setStartServeEvaluateAddTime] = useState();
    const [endServeEvaluateAddTime, setEndServeEvaluateAddTime] = useState();
    const handleSelectTime = (value:any ,dateString:any) => {
        setStartServeEvaluateAddTime(dateString[0]);
        setEndServeEvaluateAddTime(dateString[1]);
    }
    const onFinish = (values: any) => {
        console.log(values);
        setParam({
            ...param,
            startServeEvaluateAddTime: startServeEvaluateAddTime,
            endServeEvaluateAddTime: endServeEvaluateAddTime,
            ordersSn: values.ordersSn,
            serveName: values.serveName,
            serveEvaluateScores: values.serveEvaluateScores,
            serveEvaluateType: values.serveEvaluateType,
            pageNum: null
        })
    }
    return (
        <div>
            <Card style={{  marginBottom: 24, backgroundColor: "#f2f3f5" }}>
                <Form onFinish={onFinish}>
                    <Form.Item name="serveEvaluateAddTimeRange" label="创建时间" {...rangeConfig}>
                        <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="ordersSn"
                                label="订单编号"
                            >
                                <Input placeholder="输入订单编号" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="serveName"
                                label="服务名称"
                            >
                                <Input placeholder="输入服务名称" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="serveEvaluateScores"
                                label="评价星级"
                            >
                                <Select
                                    showSearch
                                    placeholder="请选择星级评价"
                                    optionFilterProp="children"
                                >
                                   <Option value={0}>全部</Option>
                                   <Option value={1}>一星</Option>
                                   <Option value={2}>二星</Option>
                                   <Option value={3}>三星</Option>
                                   <Option value={4}>四星</Option>
                                   <Option value={5}>五星</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="serveEvaluateType"
                                label="评价方式"
                            >
                                <Select
                                    showSearch
                                    placeholder="请选择评价方式"
                                    optionFilterProp="children"
                                >
                                   <Option value={0}>全部</Option>
                                   <Option value={1}>用户自评</Option>
                                   <Option value={2}>默认评价</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button style={{ marginLeft:70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <div className="site-layout-background">
                <Table 
                    loading = { isLoading }
                    pagination = { paginationProps } 
                    rowKey={ columns => columns.serveEvaluateId } 
                    columns = {[
                        {
                            title: '服务信息',
                            width: 350,
                            dataIndex: 'goodsName',
                            key: 'goodsName',
                            fixed: 'left',
                            render: (value, serveEvaluate) => (
                                <Space size="middle">
                                    <Row>
                                        <Col span={6}>
                                            <Image
                                                width="100%"
                                                src={"https://img.ihsh.cn/upload/8/" + serveEvaluate.serveEvaluatePic}
                                            />
                                        </Col>
                                        <Col span={18} style={{paddingLeft:10}}>
                                            <Row>
                                                <Col span={24} style={{marginBottom:5}}>
                                                    {serveEvaluate.serveName}
                                                </Col>
                                                <Col span={24} style={{marginBottom:5}}>
                                                    规格：{serveEvaluate.serveSpecName}
                                                </Col>
                                                <Col span={24}>
                                                    订单编号：{serveEvaluate.ordersSn}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Space>
                            ),
                        },
                        {
                            title: '评价内容',
                            dataIndex: 'serveEvaluateContent',
                            key: 'serveEvaluateContent',
                            render(value, serveEvaluate) {
                                return (
                                    <Row>
                                        <Col span={24}>
                                            {serveEvaluate.serveEvaluateContent}
                                        </Col>
                                        <Col span={24}>
                                            {
                                                serveEvaluate?.serveEvaluatePic?.split(",")?.map((item: any, value: any) => 
                                                    <span  style={{marginRight:10}}>
                                                        <Image
                                                            width={60}
                                                            src={"https://img.ihsh.cn/upload/8/" + item} />
                                                    </span>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                );
                            }
                        },
                        {
                            title: '星级评价',
                            dataIndex: 'serveEvaluateScores',
                            key: 'serveEvaluateScores',
                            render(value, serveEvaluate) {
                                return (
                                  <span>
                                    <Rate allowHalf disabled defaultValue={serveEvaluate.serveEvaluateScores} />
                                  </span> 
                                );
                            }
                        },
                        { 
                            title: '评价时间', 
                            dataIndex: 'serveEvaluateAddTime', 
                            key: 'serveEvaluateAddTime',
                            render(value, serveEvaluate) {
                                return <span>
                                    {serveEvaluate.serveEvaluateAddTime ? dayjs(serveEvaluate.serveEvaluateAddTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                                </span>
                            },
                        },
                        {
                            title: '买家',
                            dataIndex: 'memberNickname',
                            key: 'memberNickname',
                        },
                        {
                            title: '操作',
                            key: 'operation',
                            fixed: 'right',
                            width: 150,
                            render: (value, serveEvaluate) => (
                                <Space size="middle">
                                    <a onClick={() => confirmDeleteServeEvaluate(serveEvaluate.serveEvaluateId)}>
                                        删除
                                    </a>
                                </Space>
                            ),
                        },
                    ]}
                    dataSource={ list || [] }
                    scroll={{ x: 1500 }} />
            </div>
            <ErrorBox error={ error } />
        </div>
    )
}