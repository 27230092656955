import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { StoreAccount } from "../interfaces/store-account";

export const useStoreAccountUpdatePassword = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StoreAccount>) =>
            client(`storeAccount/updatePassword`, {
                data: params,
                method: "PUT"
            }),
    );
};

//列表
export const useStoreAccountList = (param?: Partial<StoreAccount>) => {
    const client = useHttp();
    const result = useQuery<any>(["storeAccountList", param], () =>
        client("storeAccount/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增
export const useAddStoreAccount = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StoreAccount>) =>
            client(`storeAccount/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑
export const useEditStoreAccount = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StoreAccount>) =>
            client(`storeAccount/update?storeAccountId=${params.storeAccountId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//重置密码
export const useRestStoreAccount = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StoreAccount>) =>
            client(`storeAccount/restPwd?storeAccountId=${params.storeAccountId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除
export const useDeleteStoreAccount = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ storeAccountId }: { storeAccountId: number}) =>
            client(`storeAccount/delete?storeAccountId=${storeAccountId}`, {
            method: "DELETE",
        })
    );
};

//详情
export const useStoreAccount = (storeAccountId?: number) => {
    const client = useHttp();
    return useQuery<any>(["storeAccountList", { storeAccountId }], () =>
        client(`storeAccount/detail?storeAccountId=${storeAccountId}`),
        {
            enabled: Boolean(storeAccountId)
        }
    );
};
