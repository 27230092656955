import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { OrdersAddress } from "../interfaces/orders-address";

export const useOrdersAddress = (ordersId?: number) => {
    const client = useHttp();
    return useQuery<any>(["ordersAddress", { ordersId }], () =>
        client(`ordersAddress/detail?ordersId=${ordersId}`),
        {
            enabled: Boolean(ordersId)
        }
    );
};

export const useOrdersAddressDetail = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<OrdersAddress>) =>
            client(`ordersAddress/detail?ordersId=` + params.ordersId, {
                method: "GET"
            }),
    );
};