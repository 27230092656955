import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useStoreAccountSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["storeAccountId", "storeAccountName", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, storeAccountId: Number(param.storeAccountId) || undefined, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useStoreAccountQueryKey = () => {
  const [params] = useStoreAccountSearchParams();
  return ["storeAccountList", params];
};