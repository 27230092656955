import { StoreAccount } from "../interfaces/store-account";

const apiUrl = process.env.REACT_APP_API_URL;

const localStorageStoreAccountKey = "__auth_provider_store_account_token__";

const localStorageStoreKey = "__auth_provider_store_token__";

export const getToken = () => window.localStorage.getItem(localStorageStoreAccountKey);

export const getSToken = () => window.localStorage.getItem(localStorageStoreKey);

export const handleStoreAccountResponse = ({ storeAccount }: { storeAccount: StoreAccount}) => {
    window.localStorage.setItem(localStorageStoreAccountKey, storeAccount.token || "");
    window.localStorage.setItem(localStorageStoreKey, storeAccount.stoken || "");
    return storeAccount;
}

export const login = (data: { storeAccountName: string; storeAccountPassword: string}) => {
    return fetch(`${apiUrl}/signIn`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "shopId": "1",
        },
        body: JSON.stringify(data),
    }).then( response => 
        response.json()
    );
};

export const register = (data: { storeAccountName: string; storeAccountPassword: string}) => {
    return fetch(`${apiUrl}/register`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    }).then(async (response) => {
        if (response.ok){
            return handleStoreAccountResponse(await response.json());
        } else {
            return Promise.reject(await response.json());
        }
    });
};

export const logout = async () => {
    window.localStorage.removeItem(localStorageStoreAccountKey);
    window.localStorage.removeItem(localStorageStoreKey);
} 