import { Table, Space, Row, Col, Button, Input, Modal, message, Image, Tabs, Card, Form, DatePicker, TimePicker, Select } from 'antd';
import { useServeList, useDeleteServe, useServeClassList } from '../../services/serve-service';
import { useDebounce } from '../../utils';
import { useServeQueryKey, useServeSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
import { useState } from 'react';
import dayjs from 'dayjs';
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;

export const ServeListPage = () => {
    //获取参数
    const { data: serveClassList } = useServeClassList();
    const serveClassListData = serveClassList?.data?.content;
    const options = serveClassListData?.map((item: any, value: any) => 
        <Option value={item.serveClassId} key={item.serveClassId}>{item.serveClassName}</Option>
    );
    //参数
    const [param, setParam] = useServeSearchParams();
    console.log(param.typeId)
    const { isLoading, error, totalElements, data: list } = useServeList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateServe = useDeleteServe;
    const { mutateAsync } = useMutateServe(
        useServeQueryKey()
    );
    const confirmDeleteServe = (serveId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ serveId }).then(() => {
                        message.info('删除成功');
                        setParam({
                            ...param,
                            serveId: serveId,
                        })
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    //提交表单
    //时间
    const [startServeAddTime, setStartServeAddTime] = useState();
    const [endServeAddTime, setEndServeAddTime] = useState();
    const handleSelectTime = (value:any ,dateString:any) => {
        setStartServeAddTime(dateString[0]);
        setEndServeAddTime(dateString[1]);
    }
    //销量
    const [minServeSaleNum, setMinServeSaleNum] = useState();
    const [maxServeSaleNum, setMaxServeSaleNum] = useState();
    const handleMinServeSaleNum = (e:any) => {
        setMinServeSaleNum(e.target.value)
    }
    const handleMaxServeSaleNum = (e:any) => {
        setMaxServeSaleNum(e.target.value)
    }
    //价格
    const [minServePrice, setMinServePrice] = useState();
    const [maxServePrice, setMaxServePrice] = useState();
    const handleMinServePrice = (e:any) => {
        setMinServePrice(e.target.value)
    }
    const handleMaxServePrice = (e:any) => {
        setMaxServePrice(e.target.value)
    }
    //分类
    const [serveClassId, setServeClassId] = useState();
    const handleServeClass = (value: any) => {
        setServeClassId(value)
    };
    //上下架
    const [typeId, setTypeId] = useState();
    const handleTab = (key: any, event: any) => {
        setTypeId(key)
        setParam({
            ...param,
            typeId: key,
            pageNum: null
        })
    }
    //提交
    const onFinish = (values: any) => {
        setParam({
            ...param,
            serveName: values.serveName,
            startServeAddTime: startServeAddTime,
            endServeAddTime: endServeAddTime,
            minServeSaleNum: minServeSaleNum,
            maxServeSaleNum: maxServeSaleNum,
            minServePrice: minServePrice,
            maxServePrice: maxServePrice,
            serveClassId: serveClassId,
            typeId: typeId,
            pageNum: null
        })
    };
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/goods/serve/add"}>
                        <Button type="primary">新建服务</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    
                </Col>
            </Row>
            <Card style={{marginBottom: 24, backgroundColor: "#f2f3f5" }}>
                <Form onFinish={onFinish}>
                    <Form.Item name="serveAddTimeRange" label="创建时间" {...rangeConfig}>
                        <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="serveName"
                                label="服务名称"
                            >
                                <Input placeholder="输入服务名称" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="serveClassId"
                                label="服务分类"
                            >
                                <Select
                                    showSearch
                                    placeholder="请选择服务分类"
                                    optionFilterProp="children"
                                    onChange={handleServeClass}
                                >
                                    {options}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                label="服务销量"
                            >
                                <Input.Group compact>
                                    <Input
                                        onChange={handleMinServeSaleNum}
                                        name="minServeSaleNum"
                                        style={{ width: '44%', textAlign: 'center' }} />
                                    <Input
                                        className="site-input-split"
                                        style={{
                                            width: '12%',
                                            borderLeft: 0,
                                            borderRight: 0,
                                            pointerEvents: 'none',
                                        }}
                                        placeholder="~"
                                        disabled
                                    />
                                    <Input
                                        onChange={handleMaxServeSaleNum}
                                        name="maxServeSaleNum"
                                        className="site-input-right"
                                        style={{ width: '44%', textAlign: 'center', }} />
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="服务价格"
                            >
                                <Input.Group compact>
                                    <Input
                                        onChange={handleMinServePrice}
                                        name="minServePrice"
                                        style={{ width: '44%', textAlign: 'center' }} />
                                    <Input
                                        className="site-input-split"
                                        style={{
                                            width: '12%',
                                            borderLeft: 0,
                                            borderRight: 0,
                                            pointerEvents: 'none',
                                        }}
                                        placeholder="~"
                                        disabled
                                    />
                                    <Input
                                        onChange={handleMaxServePrice}
                                        name="maxServePrice"
                                        className="site-input-right"
                                        style={{ width: '44%', textAlign: 'center', }} />
                                </Input.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button style={{ marginLeft:70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form> 
            </Card>
            <div className="site-layout-background">
                <Tabs onTabClick={handleTab} defaultActiveKey={param.typeId} type="card" size={"small"}>
                    <TabPane tab="全部" key="1">
                    </TabPane>
                    <TabPane tab="销售中" key="2">
                    </TabPane>=
                    <TabPane tab="仓库中" key="3">
                    </TabPane>
                </Tabs>
                <Table
                    loading = { isLoading }
                    pagination = { paginationProps } 
                    rowKey={ columns => columns.serveId } 
                    columns={[
                        {
                            title: '服务名称',
                            width: 300,
                            dataIndex: 'serveName',
                            key: 'serveName',
                            fixed: 'left',
                            render: (value, serve) => (
                                <Space size="middle">
                                    <Row>
                                        <Col span={6}>
                                            <Image
                                                width="100%"
                                                src={"https://img.ihsh.cn/upload/8/" + serve.servePic}
                                            />
                                        </Col>
                                        <Col span={18} style={{paddingLeft:10}}>
                                            {serve.serveName}
                                        </Col>
                                    </Row>
                                </Space>
                            ),
                        },
                        { 
                            title: '价格（元）', 
                            dataIndex: 'servePrice', 
                            key: 'servePrice' 
                        },
                        { 
                            title: '访问量', 
                            dataIndex: 'servePageView', 
                            key: 'servePageView' 
                        },
                        { 
                            title: '销量', 
                            dataIndex: 'serveSaleNum', 
                            key: 'serveSaleNum' 
                        },
                        { 
                            title: '创建时间', 
                            dataIndex: 'serveAddTime', 
                            key: 'serveAddTime',
                            render(value, serve) {
                                return <span>
                                    {serve.serveAddTime ? dayjs(serve.serveAddTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                                </span>
                            },
                        },
                        {
                            title: '操作',
                            key: 'operation',
                            fixed: 'right',
                            width: 150,
                            render: (value, serve) => (
                                <Space size="middle">
                                    <Link to={"/goods/serve/update/" + serve.serveId}>
                                        编辑
                                    </Link>
                                    <a onClick={() => confirmDeleteServe(serve.serveId)}>
                                        删除
                                    </a>
                                </Space>
                            ),
                        },
                    ]}
                    dataSource={ list || [] }
                    scroll={{ x: 1500 }}/>
            </div>
            <ErrorBox error={ error } />
        </div>
    )
}