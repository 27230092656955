import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { Goods } from "../interfaces/goods";

//商品列表
export const useGoodsList = (param?: Partial<Goods>) => {
    const client = useHttp();
    const result = useQuery<any>(["goodsList", param], () =>
        client("goods/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增商品
export const useAddGoods = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<Goods>) =>
            client(`goods/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑商品
export const useEditGoods = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<Goods>) =>
            client(`goods/update`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除商品
export const useDeleteGoods = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ goodsId }: { goodsId: number}) =>
            client(`goods/delete?goodsId=${goodsId}`, {
            method: "DELETE",
        })
    );
};

//商品详情
export const useGoods = (goodsId?: number) => {
    const client = useHttp();
    return useQuery<any>(["goods", { goodsId }], () =>
        client(`goods/detail?goodsId=${goodsId}`),
        {
            enabled: Boolean(goodsId)
        }
    );
};

export const useGoodsDetail = (goodsId?: number) => {
    const client = useHttp();
    return useQuery<any>(["goodsDetail", { goodsId }], () =>
        client(`goodsDetail/detail?goodsId=${goodsId}`),
        {
            enabled: Boolean(goodsId)
        }
    );
};

//
export const useGoodsClassListTree = () => {
    const client = useHttp();
    return useQuery<any>(["goodsClassListTree", {}], () =>
        client(`goodsClass/listTree`)
    );
};
