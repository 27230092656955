import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { Orders } from "../interfaces/orders";

//订单列表
export const useOrdersList = (param?: Partial<Orders>) => {
    const client = useHttp();
    const result = useQuery<any>(["ordersList", param], () =>
        client("orders/list", { data: param })
    );
    const totalElements = result?.data?.data?.totalElements;
    const data = result.data?.data?.ordersVOList;
    return {...result, totalElements, data };
};

//订单详情
export const useOrders = (ordersId?: number) => {
    const client = useHttp();
    return useQuery<any>(["orders", { ordersId }], () =>
        client(`orders/detail?ordersId=${ordersId}`),
        {
            enabled: Boolean(ordersId)
        }
    );
};

//完成订单
export const useFinishOrders = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ ordersId }: { ordersId: number}) =>
            client(`orders/finishOrders?ordersId=${ordersId}`, {
            method: "PUT",
        })
    );
};