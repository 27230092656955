import { Button, Col, Form, Input, message, Row, Select, Tabs, Upload, Typography, Space, Card, Radio} from "antd";
import { LoadingOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useServe, useEditServe, useServeDetail, useServeNotice, useServeSpecList } from '../../services/serve-service';
import { useServeClassByTree } from '../../services/serve-class-service';
import { useServeQueryKey, useServeSearchParams } from './util';
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css';
const { Option } = Select;
const { TabPane } = Tabs;
const { Text } = Typography;

export const ServeUpdatePage = () => {
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
     //获取数据
    const { pathname } = useLocation();
    const serveId = pathname.match(/goods\/serve\/update\/(\d+)/)?.[1]
    const { data: serveSpecLists } = useServeSpecList(
        Number(serveId)
    );
    const { data: editingServe, isLoading } = useServe(
        Number(serveId)
    );
    const { data: editingServeDetail } = useServeDetail(
        Number(serveId)
    );
    const { data: editingServeNotice } = useServeNotice(
        Number(serveId)
    );
    const [fileList, setFileList] = useState<any>([]);
    const [count, setCount] = useState(0)
    //准备分类
    const { data: serveClassList } = useServeClassByTree();
    const options = serveClassList?.map((item: any, value: any) =>
        <Option value={item.serveClassId} key={item.serveClassId}>{item.serveClassName}</Option>
    );
    //表单
    const [servePicUrl, setServePicUrl] = useState("");
    const [serveVideoPasterPicUrl, setServeVideoPasterPicUrl] = useState("");
    const [serveVideoUrl, setServeVideoUrl] = useState("");
    const [serveNoticeContentValue, setServeNoticeContentValue] = useState("");
    const [serveContentValue, setServeContentValue] = useState("");
    const [serveNoticeEditorState, setServeNoticeEditorState] = useState(BraftEditor.createEditorState(''))
    const [serveContentEditorState, setServeContentEditorState] = useState(BraftEditor.createEditorState(''))
    //const [serveSpecLists, setServeSpecLists] = useState<any>();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [form] = useForm();

    useEffect(() => {
        form.setFieldsValue(editingServe?.data?.serve)
        const servePic = "https://img.ihsh.cn/upload/8/" + editingServe?.data?.serve?.servePic;
        setServePicUrl(servePic)
        if(editingServe?.data?.serve?.serveVideo){
            const serveVideo = "https://img.ihsh.cn/upload/8/" + editingServe?.data?.serve?.serveVideo;
            setServeVideoUrl(serveVideo)
        }
        if(editingServe?.data?.serve?.serveVideoPasterPic){
            const serveVideoPasterPic = "https://img.ihsh.cn/upload/8/" + editingServe?.data?.serve?.serveVideoPasterPic;
            setServeVideoPasterPicUrl(serveVideoPasterPic)
        }
        // if(editingServe?.data?.antDesignUploadMoreVOList){
        //     for(let i=0;i<editingServe?.data?.antDesignUploadMoreVOList?.length;i++){
        //         fileList.push(editingServe?.data?.antDesignUploadMoreVOList[i]);
        //     }
        // }
    }, [editingServe, form]);


    //记住第一次，与第一次长度
    //如果有第二次，从第一次之后的长度开始
    const [imgNum, setImgNum] = useState(0)
    const [imgTimes, setImgTimes] = useState(0)
    useEffect(() => {
        if(editingServe?.data?.antDesignUploadMoreVOList) {
            console.log("imgTimes----------" + imgTimes)
            if(imgTimes > 0) {
                if(editingServe?.data?.antDesignUploadMoreVOList){
                    for(let i=0;i<editingServe?.data?.antDesignUploadMoreVOList?.length;i++){
                        let pos = i + 1
                        if(pos > imgNum) {
                            fileList.push(editingServe?.data?.antDesignUploadMoreVOList[i]);
                        }      
                    } 
                }
            } else {
                setImgNum(editingServe?.data?.antDesignUploadMoreVOList?.length)
                if(editingServe?.data?.antDesignUploadMoreVOList){
                    for(let i=0;i<editingServe?.data?.antDesignUploadMoreVOList?.length;i++){        
                        fileList.push(editingServe?.data?.antDesignUploadMoreVOList[i]);
                    } 
                }
                console.log(imgNum)
            }
            setImgTimes(1)
        }
    }, [editingServe?.data?.antDesignUploadMoreVOList, count]);

    useEffect(() => {
        setServeNoticeContentValue(editingServeNotice?.data?.serveNoticeContent);
        setServeNoticeEditorState(BraftEditor.createEditorState(editingServeNotice?.data?.serveNoticeContent));
    }, [editingServeNotice, form]);

    useEffect(() => {
        setServeContentValue(editingServeDetail?.data?.serveContent);
        setServeContentEditorState(BraftEditor.createEditorState(editingServeDetail?.data?.serveContent));
    }, [editingServeDetail, form]);

    // useEffect(() => {
    //     setServeSpecLists(serveSpecLists);
    // }, [serveSpecLists, form]);

    //console.log(serveSpecListValue)

    //上传服务主图
    const [uploadServePicLoading, setUploadServePicLoading] = useState(false);
    const uploadServePicButton = (
        <div>
          {uploadServePicLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const handleChangeServePic = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadServePicLoading(false);
            setServePicUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                servePic: relativePath
            })
        }
    };
    const beforeUploadServePic = (file:any) => {
        setServePicUrl("");
        setUploadServePicLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadServePicLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadServePicLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const normServePicFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //上传多图
    const uploadServeMorePicButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const handleChangeServeMorePic = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setFileList(info?.fileList)
        }
        if (info.file.status === 'removed') {
            setFileList(info?.fileList)
        }
    };
    const beforeUploadServeMorePic = (file:any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const normServeMorePicFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //上传视频封面
    const [uploadServeVideoPasterPicLoading, setUploadServeVideoPasterPicLoading] = useState(false);
    const uploadButton = (
        <div>
          {uploadServeVideoPasterPicLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const handleChangeServeVideoPasterPic = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadServeVideoPasterPicLoading(false);
            setServeVideoPasterPicUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                serveVideoPasterPic: relativePath
            })
        }
    };
    const beforeUploadServeVideoPasterPic = (file:any) => {
        setServeVideoPasterPicUrl("");
        setUploadServeVideoPasterPicLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadServeVideoPasterPicLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadServeVideoPasterPicLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const normServeVideoPasterPicFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //上传视频
    const [uploadServeVideoLoading, setUploadServeVideoLoading] = useState(false);
    const uploadVideoButton = (
        <div>
          {uploadServeVideoLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传视频</div>
        </div>
    );
    const beforeSpecVideoUpload = (file:any) => {
        setServeVideoUrl("");
        setUploadServeVideoLoading(true);
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
            setUploadServeVideoLoading(false);
            message.error('Image must smaller than 20MB!');
        }
        return isLt20M;
    }
    const handleSpecVideoChange = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadServeVideoLoading(false);
            setServeVideoUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                serveVideo: relativePath
            })
        }
    };
    const normSpecVideoFile = (e: any) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //提交
    const useMutateEditServe = useEditServe;
    const { mutateAsync , error } = useMutateEditServe(
        useServeQueryKey()
    );
    //富文本 - 须知
    const handleServeNoticeEditorChange = (editorState:any) => {
        setServeNoticeContentValue(editorState.toHTML())
    }
    //富文本 - 内容
    const handleServeContentEditorChange = (editorState:any) => {
        setServeContentValue(editorState.toHTML())
    }
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        var serveMorePic = "";
        fileList?.map((item: any, value: any) => {
            serveMorePic = serveMorePic + item?.response + ",";
        })
        const serve = {
            serveId: editingServe?.data?.serve?.serveId,
            serveName: values.serveName,
            servePromotions: values.servePromotions,
            serveClassId: values.serveClassId,
            servePic: values.servePic,
            serveMorePic: serveMorePic,
            serveVideo: values.serveVideo,
            serveVideoPasterPic: values.serveVideoPasterPic,
            serveShow: values.serveShow,
            serveSort: values.serveSort
        }
        const serveSpecList = values?.serveSpecList
        const serveDetail = {
            serveContent: serveContentValue
        };
        const serveNotice = {
            serveNoticeContent: serveNoticeContentValue
        };
        mutateAsync({ serve, serveSpecList, serveDetail, serveNotice }).then(() => {
            window.location.href = "/#/goods/serve/list";
        });
    }
    //富文本上传
    const myUploadFn = (param: any) => {
        const serverURL = 'https://sj.ihsh.cn/upload/braftEditorImag'
        const xhr = new XMLHttpRequest
        const fd = new FormData()
        const successFn = (response: any) => {
          // 假设服务端直接返回文件上传后的地址
          // 上传成功后调用param.success并传入上传后的文件地址
          param.success({
            url: xhr.responseText,
            meta: {
              loop: true, // 指定音视频是否循环播放
              autoPlay: true, // 指定音视频是否自动播放
              controls: true, // 指定音视频是否显示控制栏
            }
          })
        }
        const progressFn = (event: any) => {
          // 上传进度发生变化时调用param.progress
          param.progress(event.loaded / event.total * 100)
        }
        const errorFn = (response: any) => {
          // 上传发生错误时调用param.error
          param.error({
            msg: 'unable to upload.'
          })
        }
        xhr.upload.addEventListener("progress", progressFn, false)
        xhr.addEventListener("load", successFn, false)
        xhr.addEventListener("error", errorFn, false)
        xhr.addEventListener("abort", errorFn, false)
        fd.append('file', param.file)
        xhr.open('POST', serverURL, true)
        xhr.setRequestHeader("name", "imageFile");
        xhr.send(fd)
    }

    const a = [
        { serveSpecName: 'serveSpecName1', serveSpecPrice: 'serveSpecPrice1', serveSpecOriginalPrice: 'serveSpecOriginalPrice1', serveSpecCostPrice: 'serveSpecCostPrice1' },
        { serveSpecName: 'serveSpecName2', serveSpecPrice: 'serveSpecPrice2', serveSpecOriginalPrice: 'serveSpecOriginalPrice2', serveSpecCostPrice: 'serveSpecCostPrice2' },
        { serveSpecName: 'serveSpecName3', serveSpecPrice: 'serveSpecPrice3', serveSpecOriginalPrice: 'serveSpecOriginalPrice3', serveSpecCostPrice: 'serveSpecCostPrice3' },
    ]
    return(
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/goods/serve/list"}>
                        <Button type="primary">服务列表</Button>
                    </Link>
                </Col>
            </Row>
            <Form
                form={form}
                {...layout}
                name="basic"
                onFinish={onFinish}
                initialValues={{
                    serveSort: 0,
                    serveShow: true
                }}
            >
                <Tabs defaultActiveKey="1">
                    <TabPane tab="基本信息" key="1">
                        <Form.Item
                            label={"服务名称"}
                            name={"serveName"}
                            rules={[{ required: true, message: "服务名称必填" }]}
                            style={{marginTop: 24}}
                        >
                            <Input style={{width: 380}} placeholder={"请输入服务名称"} />
                        </Form.Item>

                        <Form.Item
                            label={"服务买点"}
                            name={"servePromotions"}
                        >
                            <Input style={{width: 380}} placeholder={"请输入服务买点"} />
                        </Form.Item>

                        <Form.Item
                            label={"服务分类"}
                            name={"serveClassId"}
                            rules={[{ required: true, message: "服务分类必选" }]}
                        >
                            <Select
                                showSearch
                                placeholder="请选择服务分类"
                                optionFilterProp="children"
                                style={{textAlign:'left', width: 380}}
                            >
                                {options}
                            </Select>
                        </Form.Item>

                        {/* 服务主图 */}
                        <Form.Item
                            name={"uploadServePic"}
                            label={"服务主图"}
                            valuePropName="fileList"
                            getValueFromEvent={normServePicFile}
                            rules={[{ required: true, message: "服务主图必传" }]}
                            style={{ textAlign:'left' }}
                            initialValue={[
                                {
                                "uid": "",
                                "lastModified": "",
                                "lastModifiedDate": "",
                                "name": "",
                                "size": "",
                                "type": "",
                                "percent": "",
                                "originFileObj": {
                                    "uid": ""
                                },
                                "status": "done",
                                "response": {
                                    "status": 0,
                                    "data": {
                                    "relativePath": "",
                                    "realPath": ""
                                    }
                                },
                                "xhr": {}
                                }
                            ]}
                        >
                            <Upload
                                name="imageFile"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action="https://sj.ihsh.cn/upload/img"
                                beforeUpload={beforeUploadServePic}
                                onChange={handleChangeServePic}
                            >
                                {servePicUrl ? <img src={servePicUrl} alt="avatar" style={{ width: '100%' }} /> : uploadServePicButton}
                            </Upload>
                        </Form.Item>

                        <Form.Item hidden
                            label={"服务主图"}
                            name={"servePic"}
                            rules={[{ required: true, message: "服务主图必传" }]}
                        >
                            <Input />
                        </Form.Item>

                        {/* 服务详情图 */}
                        <Form.Item
                            name={"uploadServeMorePic"}
                            label={"服务详情图"}
                            valuePropName="fileList"
                            getValueFromEvent={normServeMorePicFile}
                            rules={[{ required: true, message: "服务详情图必传" }]}
                            style={{ textAlign:'left' }}
                            initialValue={fileList}
                        >
                            <Upload
                                name="imageFile"
                                action="https://sj.ihsh.cn/upload/antdImg"
                                listType="picture-card"
                                fileList={fileList as []}
                                beforeUpload={beforeUploadServeMorePic}
                                onChange={handleChangeServeMorePic}
                            >
                                {fileList.length < 5? uploadServeMorePicButton : ''}
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name={"uploadVideo"}
                            label={"服务视频"}
                            valuePropName="fileList"
                            getValueFromEvent={normSpecVideoFile}
                            style={{ textAlign:'left' }}
                        >
                            <Upload
                                name="videoFile"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action="https://sj.ihsh.cn/upload/video"
                                beforeUpload={beforeSpecVideoUpload}
                                onChange={handleSpecVideoChange}
                            >
                                {serveVideoUrl ? <video src={serveVideoUrl} style={{ width: '100%' }} /> : uploadVideoButton}
                            </Upload>
                        </Form.Item>

                        <Form.Item hidden
                            label={"服务视频"}
                            name={"serveVideo"}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name={"uploadServeVideoPasterPic"}
                            label={"视频贴片"}
                            valuePropName="fileList"
                            getValueFromEvent={normServeVideoPasterPicFile}
                            style={{ textAlign:'left' }}
                        >
                            <Upload
                                name="imageFile"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action="https://sj.ihsh.cn/upload/img"
                                beforeUpload={beforeUploadServeVideoPasterPic}
                                onChange={handleChangeServeVideoPasterPic}
                            >
                                {serveVideoPasterPicUrl ? <img src={serveVideoPasterPicUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                            <Text style={{color: "#999", fontSize: 12}}>视频贴片是指视频播放器在播放前展示的图片。</Text>
                        </Form.Item>

                        <Form.Item hidden
                            label={"视频贴片"}
                            name={"serveVideoPasterPic"}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={"服务排序"}
                            name={"serveSort"}
                            rules={[{ required: true, message: "服务排序必填" }]}
                        >
                            <Input style={{width: 380}} placeholder={"请输入服务排序"} />
                        </Form.Item>

                        <Form.Item
                            label={"是否显示"}
                            name={"serveShow"}
                            rules={[{ required: true, message: "服务显示必选" }]}
                            style={{ textAlign:'left' }}
                        >
                            <Radio.Group>
                                <Radio defaultChecked value={true}>显示</Radio>
                                <Radio value={false}>不显示</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label={"服务规格"}
                            name={"spec"}
                        >
                            <Card style={{width: 700, boxShadow: "0 0 4px 0 rgb(200 200 200 / 50%)"}}>
                                <Space style={{ display: 'flex', marginBottom: 0 }} align="baseline">
                                    <Form.Item style={{width: 150, marginBottom: 10, textAlign:"center"}}>
                                        规格名称
                                    </Form.Item>
                                    <Form.Item style={{width: 150, marginBottom: 10, textAlign:"center"}}>
                                        规格价格
                                    </Form.Item>
                                    <Form.Item style={{width: 150, marginBottom: 10, textAlign:"center"}}>
                                        规格原价
                                    </Form.Item>
                                    <Form.Item style={{width: 150, marginBottom: 10, textAlign:"center"}}>
                                        规格成本价
                                    </Form.Item>
                                </Space>

                                <Form.List initialValue={serveSpecLists}  name="serveSpecList">

                                    {(fields, { add, remove }) => (
                                    <>
                                        {/* <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item
                                                name={[0, 'serveSpecName']}
                                                fieldKey={[0, 'serveSpecName']}
                                                rules={[{ required: true, message: '规格名称必填' }]}>
                                                    <Input style={{width: 150}} placeholder="请输入规格名称" />
                                            </Form.Item>
                                            <Form.Item
                                                name={[0, 'serveSpecPrice']}
                                                fieldKey={[0, 'serveSpecPrice']}
                                                rules={[{ required: true, message: '规格价格必填' }]}
                                            >
                                                <Input style={{width: 150}} placeholder="请输入规格价格" />
                                            </Form.Item>
                                            <Form.Item
                                                name={[0, 'serveSpecOriginalPrice']}
                                                fieldKey={[0, 'serveSpecOriginalPrice']}
                                                rules={[{ required: true, message: '规格原价必填' }]}
                                            >
                                                <Input style={{width: 150}} placeholder="请输入规格原价" />
                                            </Form.Item>
                                            <Form.Item
                                                name={[0, 'serveSpecCostPrice']}
                                                fieldKey={[0, 'serveSpecCostPrice']}
                                                rules={[{ required: true, message: '规格成本价必填' }]}
                                            >
                                                <Input style={{width: 150}} placeholder="请输入规格成本价" />
                                            </Form.Item>
                                        </Space> */}
                                        {fields.map((field, index) => (
                                            <Space
                                                key={field.key} style={{display: 'flex', marginBottom: 8 }} align="baseline">

                                                    <Space>
                                                        <Form.Item
                                                            name={[field.name, 'serveSpecName']}
                                                            fieldKey={[field.fieldKey, 'serveSpecName']}
                                                            rules={[{ required: true, message: '规格名称必填' }]}

                                                        >
                                                            <Input style={{width: 150}} placeholder="请输入规格名称" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={[field.name, 'serveSpecPrice']}
                                                            fieldKey={[field.fieldKey, 'serveSpecPrice']}
                                                            rules={[{ required: true, message: '规格价格必填' }]}

                                                        >
                                                            <Input style={{width: 150}} placeholder="请输入规格价格" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={[field.name, 'serveSpecOriginalPrice']}
                                                            fieldKey={[field.fieldKey, 'serveSpecOriginalPrice']}
                                                            rules={[{ required: true, message: '规格原价必填' }]}

                                                        >
                                                            <Input style={{width: 150}} placeholder="请输入规格原价" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={[field.name, 'serveSpecCostPrice']}
                                                            fieldKey={[field.fieldKey, 'serveSpecCostPrice']}
                                                            rules={[{ required: true, message: '规格成本价必填' }]}

                                                        >
                                                            <Input style={{width: 150}} placeholder="请输入规格成本价" />
                                                        </Form.Item>
                                                    </Space>


                                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button style={{width: 150}} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                添加规格
                                            </Button>
                                        </Form.Item>
                                    </>
                                    )}
                                </Form.List>
                            </Card>
                        </Form.Item>
                    </TabPane>
                    <TabPane tab="服务须知" key="2">
                        <Form.Item
                            label={"服务须知"}
                            name={"serveNoticeContent"}
                            style={{marginTop: 24}}
                        >
                            <BraftEditor
                                style={{border: "1px solid #f0f0f0"}}
                                value={serveNoticeEditorState}
                                defaultValue={serveNoticeEditorState}
                                onChange={handleServeNoticeEditorChange}
                                media={{uploadFn: myUploadFn}}
                            />
                        </Form.Item>
                    </TabPane>
                    <TabPane tab="服务详情" key="3">
                        <Form.Item
                            label={"服务详情"}
                            name={"serveContent"}
                            style={{marginTop: 24}}
                        >
                            <BraftEditor
                                style={{border: "1px solid #f0f0f0"}}
                                value={serveContentEditorState}
                                defaultValue={serveContentEditorState}
                                onChange={handleServeContentEditorChange}
                                media={{uploadFn: myUploadFn}}
                            />
                        </Form.Item>
                    </TabPane>
                </Tabs>
                <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                    <Button loading={ submitLoading } type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
