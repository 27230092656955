import { Button, Col, Form, Input, Row, Tabs, Select, message, Upload, Typography, Radio, Space, DatePicker, Divider, Tooltip, Checkbox } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useAddStoreServeClass } from '../../services/store-serve-class-service';
import { useServeClassByTree } from '../../services/serve-class-service';
import { useStoreServeClassQueryKey } from './util';
import { Link, useLocation } from "react-router-dom";
const { Option } = Select;
const { TabPane } = Tabs;
const { Text } = Typography;
const { RangePicker } = DatePicker;

export const StoreServeClassUpdatePage = () => {
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //准备数据
    const { data: serveClassList, isLoading } = useServeClassByTree();
    const serveClassListOptions = serveClassList?.map((item: any, value: any) => 
        <Option value={item.serveClassId} key={item.serveClassId}>{item.serveClassName}</Option>
    );
    //表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const [form] = useForm();
    //多选
    const [serveClassIds, setServeClassIds] = useState(false);
    function handleChange(value: any) {
        setServeClassIds(value);
    }
    //提交
    const useMutateAddStoreServeClass = useAddStoreServeClass;
    const { mutateAsync: mutateAsyncAddStoreServeClass , error } = useMutateAddStoreServeClass(
        useStoreServeClassQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsyncAddStoreServeClass({ ...values, serveClassIds }).then(() => {
            window.location.href = "/#/setting/store/update";
        });
    }
    return(
        <div>
            <Form
                form={form}
                {...layout}
                name="basic"
                onFinish={onFinish}
                initialValues={{
                    storeShow: true
                }}
            >
                <Form.Item
                    label={"门店服务"}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        showSearch
                        placeholder="请选择门店服务"
                        optionFilterProp="children"
                        style={{ width: 380, textAlign:'left'}}
                        onChange={handleChange}
                 >   
                        {serveClassListOptions}
                    </Select>
                </Form.Item>
                
                <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                    <Button loading={ submitLoading } type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}