import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { StoreWithdrawCash } from "../interfaces/store-withdraw-cash";

//列表
export const useStoreWithdrawCashList = (param?: Partial<StoreWithdrawCash>) => {
    const client = useHttp();
    const result = useQuery<any>(["storeWithdrawCashList", param], () =>
        client("storeWithdrawCash/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增
export const useAddStoreWithdrawCash = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StoreWithdrawCash>) =>
            client(`storeWithdrawCash/add`, {
                data: params,
                method: "POST"
            }),
    );
};