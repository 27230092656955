import { Divider, Upload, Row, Col, Button, Input, Form, Radio, Spin, message } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useAddGoodsSpecValue } from '../../services/goods-spec-value-service';
import { useGoodsSpecValueQueryKey } from './util';
import { useForm } from 'antd/lib/form/Form';

export const GoodsSpecValueAddPage = () => {
    const { pathname } = useLocation();
    const goodsSpecId = pathname.match(/goods\/goodsSpec\/goodsSpecValue\/add\/(\d+)/)?.[1]
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    const [form] = useForm();
    //保存
    const useMutateGoodsSpecValue = useAddGoodsSpecValue;
    const { mutateAsync, error } = useMutateGoodsSpecValue(
        useGoodsSpecValueQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, goodsSpecId }).then(() => {
            window.location.href = "/#/goods/goodsSpec/goodsSpecValue/list/" + goodsSpecId;
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/goods/goodsSpec/goodsSpecValue/list/" + goodsSpecId}>
                        <Button type="primary">规格值列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <div className="site-layout-background">
                <Form
                    form={form}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                    initialValues={{
                        goodsSpecValueSort: 0
                    }}
                >
                    <Form.Item
                        label={"规格值名称"}
                        name={"goodsSpecValueName"}
                        rules={[{ required: true, message: "规格值名称必填" }]}
                    >
                        <Input placeholder={"请输入规格值名称"} />
                    </Form.Item>

                    <Form.Item
                        label={"规格值排序"}
                        name={"goodsSpecValueSort"}
                        rules={[{ required: true, message: "规格值排序必填" }]}
                    >
                        <Input placeholder={"请输入规格值排序"} />
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
