import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { Store } from "../interfaces/store";

export const useStore = () => {
    const client = useHttp();
    return useQuery<any>(["store", {}], () =>
        client(`store`),
    );
};

export const useEditStore = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<Store>) =>
            client(`store/update`, {
                data: params,
                method: "PUT"
            }),
    );
};