import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useOrdersRefundSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["ordersRefundSn", "ordersSn", "goodsName", "ordersRefundState", "ordersRefundGoodsStatusVal", "startOrdersRefundAddTime", "endOrdersRefundAddTime", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, pageNum: Number(param.pageNum) || undefined, ordersRefundState: Number(param.ordersRefundState) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useOrdersRefundQueryKey = () => {
  const [params] = useOrdersRefundSearchParams();
  return ["ordersRefundList", params];
};