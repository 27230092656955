import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useStorePhotoAlbumSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["storePhotoAlbumId", "storePhotoAlbumName", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, storePhotoAlbumId: Number(param.storePhotoAlbumId) || undefined, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useStorePhotoAlbumQueryKey = () => {
  const [params] = useStorePhotoAlbumSearchParams();
  return ["storePhotoAlbumList", params];
};