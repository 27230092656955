import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { ServeClass } from "../interfaces/serve-class";

//分类列表
export const useServeClassList = (param?: Partial<ServeClass>) => {
    const client = useHttp();
    const result = useQuery<any>(["serveClassList", param], () =>
        client("serveClass/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};


export const useServeClassByTree = (param?: Partial<ServeClass>) => {
    const client = useHttp();
    const result = useQuery<any>(["serveClassList", param], () =>
        client("serveClass/listByTree", { data: param })
    );
    const data = result.data?.data;
    return {...result, data };
};

//全部分类
export const useServeClassListAll = () => {
    const client = useHttp();
    const result = useQuery<any>(["serveClassList"], () =>
        client("serveClass/list", { })
    );
    const data = result.data?.data;
    return {...result, data };
};

//新增分类
export const useAddServeClass = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<ServeClass>) =>
            client(`serveClass/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑分类
export const useEditServeClass = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<ServeClass>) =>
            client(`serveClass/update?serveClassId=${params.serveClassId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除分类
export const useDeleteServeClass = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ serveClassId }: { serveClassId: number}) =>
            client(`serveClass/delete?serveClassId=${serveClassId}`, {
            method: "DELETE",
        })
    );
};


//分类详情
export const useServeClass = (serveClassId?: number) => {
    const client = useHttp();
    return useQuery<any>(["serveClassList", { serveClassId }], () =>
        client(`serveClass/detail?serveClassId=${serveClassId}`),
        {
            enabled: Boolean(serveClassId)
        }
    );
};
