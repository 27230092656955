import { Divider, Upload, Row, Col, Button, Input, Form, Radio, Spin, message, Menu } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { useAddGoodsSpec } from '../../services/goods-spec-service';
import { useGoodsSpecQueryKey } from './util';
import { useForm } from 'antd/lib/form/Form';

export const GoodsSpecAddPage = () => {
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    const [form] = useForm();
    //保存
    const useMutateGoodsSpec = useAddGoodsSpec;
    const { mutateAsync, error } = useMutateGoodsSpec(
        useGoodsSpecQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values }).then(() => {
          window.location.href = "/#/goods/goodsSpec/list";
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/goods/goodsSpec/list"}>
                        <Button type="primary">规格列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <div className="site-layout-background">
                <Form
                    form={form}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                    initialValues={{
                        goodsSpecSort: 0
                    }}
                >
                    <Form.Item
                        label={"规格名称"}
                        name={"goodsSpecName"}
                        rules={[{ required: true, message: "规格名称必填" }]}
                    >
                        <Input placeholder={"请输入规格名称"} />
                    </Form.Item>

                    <Form.Item
                        label={"规格排序"}
                        name={"goodsSpecSort"}
                        rules={[{ required: true, message: "规格排序必填" }]}
                    >
                        <Input placeholder={"请输入规格排序"} />
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}