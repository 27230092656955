import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { ServeEvaluate } from "../interfaces/serve-evaluate";

//商品评论列表
export const useServeEvaluateList = (param?: Partial<ServeEvaluate>) => {
    const client = useHttp();
    const result = useQuery<any>(["serveEvaluateList", param], () =>
        client("serveEvaluate/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增商品评论
export const useAddServeEvaluate = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<ServeEvaluate>) =>
            client(`serveEvaluate/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑商品评论
export const useEditServeEvaluate = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<ServeEvaluate>) =>
            client(`serveEvaluate/update?serveEvaluateId=${params.serveEvaluateId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除商品评论
export const useDeleteServeEvaluate = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ serveEvaluateId }: { serveEvaluateId: number}) =>
            client(`serveEvaluate/delete?serveEvaluateId=${serveEvaluateId}`, {
            method: "DELETE",
        })
    );
};


//商品评论详情
export const useServeEvaluate = (serveEvaluateId?: number) => {
    const client = useHttp();
    return useQuery<any>(["serveEvaluateList", { serveEvaluateId }], () =>
        client(`serveEvaluate/detail?serveEvaluateId=${serveEvaluateId}`),
        {
            enabled: Boolean(serveEvaluateId)
        }
    );
};

