import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { OrdersGoods } from "../interfaces/orders-goods";

export const useOrdersGoodsList = (ordersId?: number) => {
    const client = useHttp();
    const result = useQuery<any>(["ordersGoodsList"], () =>
        client("ordersGoods/list?ordersId=" + ordersId, {})
    );
    const data = result.data?.data;
    return {...result, data };
};

export const useOrdersGoodsLists = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<OrdersGoods>) =>
            client(`ordersGoods/list?ordersId=` + params.ordersId, {
                method: "GET"
            }),
    );
};