import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { StoreVideo } from "../interfaces/store-video";

//列表
export const useStoreVideoList = (param?: Partial<StoreVideo>) => {
    const client = useHttp();
    const result = useQuery<any>(["storeVideoList", param], () =>
        client("storeVideo/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增
export const useAddStoreVideo = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StoreVideo>) =>
            client(`storeVideo/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑
export const useEditStoreVideo = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StoreVideo>) =>
            client(`storeVideo/update?storeVideoId=${params.storeVideoId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除
export const useDeleteStoreVideo = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ storeVideoId }: { storeVideoId: number}) =>
            client(`storeVideo/delete?storeVideoId=${storeVideoId}`, {
            method: "DELETE",
        })
    );
};


//详情
export const useStoreVideo = (storeVideoId?: number) => {
    const client = useHttp();
    return useQuery<any>(["storeVideoList", { storeVideoId }], () =>
        client(`storeVideo/detail?storeVideoId=${storeVideoId}`),
        {
            enabled: Boolean(storeVideoId)
        }
    );
};

