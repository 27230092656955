import { Divider, Upload, Row, Col, Button, Input, Form, Radio, message, Spin, Menu } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useStoreVideoAlbum, useEditStoreVideoAlbum } from '../../services/store-video-album-service';
import { useStoreVideoAlbumQueryKey } from './util';
import { useForm } from 'antd/lib/form/Form';

export const StoreVideoAlbumUpdatePage = () => {
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    //获取数据
    const { pathname } = useLocation();
    const storeVideoAlbumId = pathname.match(/store\/video\/album\/update\/(\d+)/)?.[1]
    const { data: editingStoreVideoAlbum, isLoading } = useStoreVideoAlbum(
        Number(storeVideoAlbumId)
    );    
    //表单回显
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue(editingStoreVideoAlbum?.data)
    }, [editingStoreVideoAlbum, form]);
    //编辑
    const useMutateStoreVideoAlbum = useEditStoreVideoAlbum;
    const { mutateAsync, error } = useMutateStoreVideoAlbum(
        useStoreVideoAlbumQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingStoreVideoAlbum?.data, ...values }).then(() => {
            window.location.href = "/#/store/video/album/list";
        });
    };
    return (
        <div>
            <Menu selectedKeys={["storeVideoAlbumInner"]} mode="horizontal" style={{marginBottom:24}}>
                <Menu.Item key="storeVideoInner">
                    <Link to='/store/video/list'>视频管理</Link>
                </Menu.Item>
                <Menu.Item key="storeVideoAlbumInner">
                    <Link to='/store/video/album/list'>专辑管理</Link>
                </Menu.Item>
            </Menu>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={12}>
                        <Link to={"/store/video/album/list"}>
                            <Button type="primary">专辑列表</Button>
                        </Link>
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 24 }} />
                <div className="site-layout-background">
                    <Form
                        form={form}
                        {...layout}
                        name="basic"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label={"专辑名称"}
                            name={"storeVideoAlbumName"}
                            rules={[{ required: true, message: "专辑名称必填" }]}
                        >
                            <Input placeholder={"请输入专辑名称"} />
                        </Form.Item>

                        <Form.Item
                            label={"专辑排序"}
                            name={"storeVideoAlbumSort"}
                            rules={[{ required: true, message: "专辑排序必填" }]}
                        >
                            <Input placeholder={"请输入专辑排序"} />
                        </Form.Item>

                        <Form.Item
                            label={"是否显示"}
                            name={"storeVideoAlbumShow"}
                            rules={[{ required: true, message: "专辑显示必选" }]}
                            style={{ textAlign:'left' }}
                        >
                            <Radio.Group>
                                <Radio value={true}>显示</Radio>
                                <Radio value={false}>不显示</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                            <Button loading={ submitLoading } type="primary" htmlType="submit">
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </div>
    )
}