import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";

export const useExpressList = () => {
    const client = useHttp();
    const result = useQuery<any>(["expressList"], () =>
        client("express/list", { })
    );
    const data = result.data?.data;
    return {...result, data };
};