import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { StorePhotoAlbum } from "../interfaces/store-photo-album";

//列表
export const useStorePhotoAlbumList = (param?: Partial<StorePhotoAlbum>) => {
    const client = useHttp();
    const result = useQuery<any>(["storePhotoAlbumList", param], () =>
        client("storePhotoAlbum/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//全部列表
export const useStorePhotoAlbumListAll = (param?: Partial<StorePhotoAlbum>) => {
    const client = useHttp();
    const result = useQuery<any>(["storePhotoAlbumListAll", param], () =>
        client("storePhotoAlbum/listAll", { data: param })
    );
    const data = result.data?.data;
    return {...result, data };
};

//新增
export const useAddStorePhotoAlbum = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StorePhotoAlbum>) =>
            client(`storePhotoAlbum/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑
export const useEditStorePhotoAlbum = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StorePhotoAlbum>) =>
            client(`storePhotoAlbum/update?storePhotoAlbumId=${params.storePhotoAlbumId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除
export const useDeleteStorePhotoAlbum = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ storePhotoAlbumId }: { storePhotoAlbumId: number}) =>
            client(`storePhotoAlbum/delete?storePhotoAlbumId=${storePhotoAlbumId}`, {
            method: "DELETE",
        })
    );
};


//详情
export const useStorePhotoAlbum = (storePhotoAlbumId?: number) => {
    const client = useHttp();
    return useQuery<any>(["storePhotoAlbumList", { storePhotoAlbumId }], () =>
        client(`storePhotoAlbum/detail?storePhotoAlbumId=${storePhotoAlbumId}`),
        {
            enabled: Boolean(storePhotoAlbumId)
        }
    );
};

