import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useStoreSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["storeName", "storeTel", "startStoreAddTime", "endStoreAddTime", "minStoreSaleNum", "maxStoreSaleNum", "minStoreScore", "maxStoreScore", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useStoreQueryKey = () => {
  const [params] = useStoreSearchParams();
  return ["storeList", params];
};