import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { Dashboard } from "../interfaces/dashboard";

//列表
export const useDashboard = () => {
    const client = useHttp();
    return useQuery<any>(["dashboard", { }], () =>
        client(`dashboard`),
        {}
    );
};