import { Divider, Row, Col, Button, Input, Form, Radio, Spin, message, Menu } from 'antd';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { useAddStoreBankAccount } from '../../services/store-bank-account';
import { useStoreBankAccountQueryKey } from './util';
import { useForm } from 'antd/lib/form/Form';

export const StoreBankAccountAddPage = () => {
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    const [form] = useForm();
    //保存
    const useMutateStoreBankAccount = useAddStoreBankAccount;
    const { mutateAsync, error } = useMutateStoreBankAccount(
        useStoreBankAccountQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values }).then(() => {
          window.location.href = "/#/asset/storeWithdrawCash/storeBankAccount/list";
        });
    };
    return (
        <div>
            <Menu selectedKeys={["storeBankAccountInner"]} mode="horizontal" style={{marginBottom:24}}>
                <Menu.Item key="storePhotoInner">
                    <Link to='/asset/storeWithdrawCash/add'>提现</Link>
                </Menu.Item>
                <Menu.Item key="storePhotoAlbumInner">
                    <Link to='/asset/storeWithdrawCash/list'>提现记录</Link>
                </Menu.Item>
                <Menu.Item key="storeBankAccountInner">
                    <Link to='/asset/storeWithdrawCash/storeBankAccount/list'>银行卡</Link>
                </Menu.Item>
            </Menu>
            <Row>
                <Col span={12}>
                    <Link to={"/asset/storeWithdrawCash/storeBankAccount/list"}>
                        <Button type="primary">银行卡列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <div className="site-layout-background">
                <Form
                    form={form}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                    initialValues={{
                        storeBankAccountType: 1,
                        storeBankAccountDefault: true
                    }}
                >
                    <Form.Item
                        label={"账号类型"}
                        name={"storeBankAccountType"}
                        rules={[{ required: true, message: "账号类型必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={1}>银行卡</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={"持卡人"}
                        name={"storeBankAccountBankEncTrueName"}
                        rules={[{ required: true, message: "持卡人必填" }]}
                    >
                        <Input placeholder={"请输入持卡人"} />
                    </Form.Item>

                    <Form.Item
                        label={"卡号"}
                        name={"storeBankAccountBankEncBankNo"}
                        rules={[{ required: true, message: "卡号必填" }]}
                    >
                        <Input placeholder={"请输入卡号"} />
                    </Form.Item>

                    <Form.Item
                        label={"所属银行"}
                        name={"storeBankAccountBankName"}
                        rules={[{ required: true, message: "所属银行必填" }]}
                    >
                        <Input placeholder={"请输入所属银行"} />
                    </Form.Item>

                    <Form.Item
                        label={"默认账号"}
                        name={"storeBankAccountDefault"}
                        rules={[{ required: true, message: "默认账号必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}