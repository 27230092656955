import { Button, Card, Col, Row, Space, Steps, Table, Typography, Image, Divider, List, Modal, Form, message } from "antd";
import { useLocation } from "react-router";
import { useOrdersRefund, useOrdersRefundOrdersGoods, useAgreeOrdersRefund, useRejectOrdersRefund } from '../../services/orders-refund-service';
import { useOrdersRefundLogList } from '../../services/orders-refund-log-service';
import { useOrdersRefundQueryKey, useOrdersRefundSearchParams } from './util';
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
const { Text, Link, Title } = Typography;
const { Step } = Steps;

export const OrdersRefundDetailPage = () => {
    const imgUrl = process.env.REACT_APP_IMG_URL;
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //获取数据
    const { pathname } = useLocation();
    const ordersRefundId = pathname.match(/orders\/refund\/detail\/(\d+)/)?.[1]
    //详情
    const { data: ordersRefund, isLoading } = useOrdersRefund(
        Number(ordersRefundId)
    ); 
    const [ordersRefundState, setOrdersRefundState] = useState(0); 
    const [currentStep, setCurrentStep] = useState(0); 
    const ors = ordersRefund?.data?.ordersRefundState
    //进度条
    useEffect(() =>{
        if(Number(ors) === 20 || Number(ors) === 30) {
            setCurrentStep(1)
        }
        if(Number(ors) === 40 ||  Number(ors) === 50) {
            setCurrentStep(2)
        }
        setOrdersRefundState(ors)
    }, [ors])
    //商品
    const { data: ordersRefundOrdersGoods } = useOrdersRefundOrdersGoods(
        Number(ordersRefundId)
    );
    //协商记录
    const { data: ordersRefundLogList } = useOrdersRefundLogList(
        Number(ordersRefundId)
    ); 
    //同意退款弹框
    const [submitAgreeLoading, setSubmitAgreeLoading] = useState(false);
    const useMutateAgreeOrdersRefund = useAgreeOrdersRefund;
    const { mutateAsync } = useMutateAgreeOrdersRefund(
        useOrdersRefundQueryKey()
    );
    const [isAgreeModalVisible, setIsAgreeModalVisible] = useState(false);
    const showAgreeModal = () => {
        setIsAgreeModalVisible(true);
    };
    const handleAgreeOk = (ordersRefundId: number) => {
        setSubmitAgreeLoading(true)
        return new Promise((resolve, reject) => {
            mutateAsync({ ordersRefundId }).then(() => {
                message.info('退款成功');
                window.location.href = "/#/orders/refund/list";
            });
        }).catch(
            () => console.log('Oops errors!')
        );
        setIsAgreeModalVisible(false);
    };
    const handleAgreeCancel = () => {
        setIsAgreeModalVisible(false);
    };
    //拒绝退款弹框
    const [submitRejectLoading, setSubmitRejectLoading] = useState(false);
    const useMutateRejectOrdersRefund = useRejectOrdersRefund;
    const { mutateAsync: mutateAsyncReject } = useMutateRejectOrdersRefund(
        useOrdersRefundQueryKey()
    );
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const showRejectModal = () => {
        setIsRejectModalVisible(true);
    };
    const handleRejectOk = (ordersRefundId: number) => {
        setSubmitRejectLoading(true)
        mutateAsyncReject({ ordersRefundId }).then(() => {
            window.location.href = "/#/orders/refund/list";
        });
        setIsRejectModalVisible(false);
    };
    const handleRejectCancel = () => {
        setIsRejectModalVisible(false);
    };
    return (
        <div>
            <Modal confirmLoading={submitAgreeLoading} title="同意买家退款" visible={isAgreeModalVisible} onOk={() => handleAgreeOk(ordersRefund?.data?.ordersRefundId)} onCancel={handleAgreeCancel}>
                <p>退款方式：仅退款</p>
                <p>退款金额：￥{ordersRefund?.data?.ordersRefundMoney}</p>
            </Modal>
            <Modal confirmLoading={submitRejectLoading} title="拒绝买家退款" visible={isRejectModalVisible} onOk={() => handleRejectOk(ordersRefund?.data?.ordersRefundId)} onCancel={handleRejectCancel}>
                <p>退款方式：仅退款</p>
                <p>退款金额：￥{ordersRefund?.data?.ordersRefundMoney}</p>
            </Modal>
            <Row style={{fontSize: 14, marginBottom: 24}}>
                <Text style={{marginRight:30}}>
                    退款编号： {ordersRefund?.data?.ordersRefundSn}
                </Text>
                <Text>
                    申请时间： {ordersRefund?.data?.ordersRefundAddTime ? dayjs(ordersRefund?.data?.ordersRefundAddTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                </Text>
            </Row>
            <Row style={{ marginBottom: 24 }}>
                <Col span={8} style={{ height: 220, width: "100%", padding:20, border: "1px solid #f0f2f5" }}>
                    <Title level={4}>
                        {ordersRefund?.data?.ordersRefundState == 10 ? '等待商家处理退款申请':''}
                        {ordersRefund?.data?.ordersRefundState == 20 ? '商家同意退款申请，待到账':''}
                        {ordersRefund?.data?.ordersRefundState == 30 ? '商家不同意退款申请':''}
                        {ordersRefund?.data?.ordersRefundState == 40 ? '退款关闭':''}
                        {ordersRefund?.data?.ordersRefundState == 50 ? '退款成功':''}
                    </Title>
                    <Space direction="vertical" style={{marginBottom: 20}}>
                        {ordersRefund?.data?.ordersRefundState == 10 ? <div>
                            <Text>收到买家仅退款申请，请在{ordersRefund?.data?.ordersRefundNextStepTime ? dayjs(ordersRefund?.data?.ordersRefundNextStepTime).format('YYYY-MM-DD HH:mm:ss'):'无'}前尽快处理</Text>
                            <Text>如你逾期未处理，将自动同意退款</Text> 
                        </div>:''}
                        {ordersRefund?.data?.ordersRefundState == 20 ?  <div>
                            <Text>退款金额：￥{ordersRefund?.data?.ordersRefundMoney}</Text>
                        </div>:''}
                        {ordersRefund?.data?.ordersRefundState == 30 ? <div>
                            <Text>你已拒绝本次退款申请，如买家修改退款申请后，需要你重新处理</Text>
                            <Text>如买家在{ordersRefund?.data?.ordersRefundNextStepTime ? dayjs(ordersRefund?.data?.ordersRefundNextStepTime).format('YYYY-MM-DD HH:mm:ss'):'无'}前未响应，退款申请将自动关闭</Text>
                        </div>:''}
                        {ordersRefund?.data?.ordersRefundState == 40 ? <div>
                            <Text>本次退款已被撤销</Text>
                        </div>:''}
                        {ordersRefund?.data?.ordersRefundState == 50 ? <div>
                            <Text>退款金额：￥{ordersRefund?.data?.ordersRefundMoney}</Text>
                        </div>:''}
                    </Space>
                    {ordersRefund?.data?.ordersRefundState == 10 ? <div>
                        <Button onClick={showAgreeModal} type="primary" style={{marginRight: 10}}>同意买家退款</Button>
                        <Button onClick={showRejectModal}>拒绝买家退款</Button>
                    </div>:''}
                </Col>
                <Col span={16} style={{ height: 220, padding:20, borderTop: "1px solid #f0f2f5", borderRight: "1px solid #f0f2f5", borderBottom: "1px solid #f0f2f5" }}>
                    <Steps current={currentStep}>
                        <Step title="买家申请退款" description={ordersRefund?.data?.ordersRefundAddTime ? dayjs(ordersRefund?.data?.ordersRefundAddTime).format('YYYY-MM-DD HH:mm:ss'):''} />
                        <Step title="商家处理退款申请" description={ordersRefund?.data?.ordersRefundConfirmTime ? dayjs(ordersRefund?.data?.ordersRefundConfirmTime).format('YYYY-MM-DD HH:mm:ss'):''} />
                        {ordersRefundState === 40 ? 
                            <Step title="退款失败" description={ordersRefund?.data?.ordersRefundCloseTime ? dayjs(ordersRefund?.data?.ordersRefundCloseTime).format('YYYY-MM-DD HH:mm:ss'):''} /> : 
                            <Step title="退款成功" description={ordersRefund?.data?.ordersRefundFinishTime ? dayjs(ordersRefund?.data?.ordersRefundFinishTime).format('YYYY-MM-DD HH:mm:ss'):''} />}
                    </Steps>
                </Col>
            </Row>
            <Row style={{ backgroundColor: '#f0f2f5', marginBottom: 24  }}>
                <Col span={12} style={{  width: "100%", padding:20 }}>
                    <Space direction="vertical">
                        <Text strong>退款申请信息</Text>
                        <Text>退款方式：仅退款</Text>
                        <Text>货物状态：{ordersRefund?.data?.ordersRefundGoodsStatus ? '已发货':'未发货'}</Text>
                        <Text>退款原因：{ordersRefund?.data?.ordersRefundReason}</Text>
                    </Space>
                </Col>
                <Col span={12} style={{ width: "100%", padding:20 }}>
                    <Space direction="vertical">
                        <Text strong>订单信息</Text>
                        <Text>买家昵称：{ordersRefund?.data?.memberNickname}</Text>
                        <Text>订单编号：{ordersRefund?.data?.ordersSn}</Text>
                        <Text>付款时间：{ordersRefund?.data?.ordersPayTime ? dayjs(ordersRefund?.data?.ordersPayTime).format('YYYY-MM-DD HH:mm:ss'):'无'}</Text>
                        <Text>实付金额：￥{ordersRefund?.data?.ordersActuallyAmount}</Text>
                        <Text>订单金额：￥{ordersRefund?.data?.ordersTotalAmount}</Text>
                    </Space>
                </Col>
            </Row>
            <Row style={{marginBottom: 24}}>
                <Table
                    pagination={false}
                    style={{width:"100%"}}
                    columns = {[
                        {
                            title: '退款商品',
                            dataIndex: 'goodsName',
                            key: 'goodsName',
                            width: "30%",
                            render: (value, item) => (
                                <Space size="middle">
                                    <Row>
                                        <Col span={6}>
                                            <Image
                                                width="100%"
                                                src={imgUrl + item.goodsPic}
                                            />
                                        </Col>
                                        <Col span={18} style={{paddingLeft:10}}>
                                            <Row>
                                                <Col span={24} style={{marginBottom:5}}>
                                                   {item.goodsName}
                                                </Col>
                                                <Col span={24} style={{marginBottom:5}}>
                                                    规格：{item.goodsSkuInfo}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Space>
                            ),
                        },
                        {
                            title: '商品小计金额(元)',
                            dataIndex: 'goodsTotalPrice',
                            key: 'goodsTotalPrice',
                            width: "17.5%",
                        },
                        {
                            title: '商品数量',
                            dataIndex: 'goodsNum',
                            key: 'goodsNum',
                            width: "17.5%",
                        },
                        {
                            title: '是否已发货',
                            dataIndex: 'goodsState',
                            key: 'goodsState',
                            width: "17.5%",
                            render(value, ordersRefundOrdersGoods) {
                                return <span>
                                    {ordersRefundOrdersGoods.goodsState == 30 ? '是':'否'}
                                </span>
                            },
                        },
                        {
                            title: '退款金额(元)',
                            dataIndex: 'ordersRefundMoney',
                            key: 'ordersRefundMoney',
                            width: "17.5%",
                        },
                    ]}
                    dataSource={ [{...ordersRefundOrdersGoods?.data}] || [] } />
            </Row>
            <Card style={{ width: "100%" }}>
                <Row style={{marginBottom: 24}}>
                    <Text strong>协商记录</Text>
                </Row>
                <List
                    bordered = {false}
                    split = {false}
                    dataSource={ordersRefundLogList || []}
                    renderItem={(item:any) => (
                        <List.Item>
                            <Row style={{marginBottom: 30}}>
                                <Space style={{width: "100%"}}>
                                    <Text strong style={{ paddingRight: 30 }}>
                                        {item.ordersRefundLogType == 1 ? "买家" : "卖家"}
                                    </Text>
                                    <Text>
                                        {item.ordersRefundLogCreateTime ? dayjs(item.ordersRefundLogCreateTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                                    </Text>
                                </Space>
                                <Divider dashed style={{ margin: "14px 0"}} />
                                {item.ordersRefundLogMsgType == 10 ? <Space direction="vertical">
                                    <Text strong>{item.ordersRefundLogTitle}</Text>
                                    <Text>退款方式：仅退款</Text>
                                    <Text>退款原因：{item.ordersRefundReason}</Text>
                                    <Text>退款金额：{item.ordersRefundMoney}</Text>
                                    <Text>手机号码：{item.ordersRefundContactTel}</Text>
                                </Space> : ""}
                                {item.ordersRefundLogMsgType == 20 ? <Space direction="vertical">
                                    <Text strong>{item.ordersRefundLogTitle}</Text>
                                    <Text>退款金额：{item.ordersRefundMoney}</Text>
                                </Space> : ""}
                                {item.ordersRefundLogMsgType == 30 ? <Space direction="vertical">
                                    <Text strong>{item.ordersRefundLogTitle}</Text>
                                    <Text>拒绝理由：{item.ordersRefundRejectReason}</Text>
                                </Space> : ""}
                                {item.ordersRefundLogMsgType == 40 ? <Space direction="vertical">
                                    <Text strong>{item.ordersRefundLogTitle}</Text>
                                </Space> : ""}
                            </Row>
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    )
}