import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useOrdersReturnSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["storeAddressId", "ordersReturnSn", "ordersSn", "goodsName", "ordersReturnState", "ordersReturnGoodsStatusVal", "startOrdersReturnAddTime", "endOrdersReturnAddTime", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, pageNum: Number(param.pageNum) || undefined, ordersReturnState: Number(param.ordersReturnState) || undefined, storeAddressId: Number(param.storeAddressId) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useOrdersReturnQueryKey = () => {
  const [params] = useOrdersReturnSearchParams();
  return ["ordersReturnList", params];
};