import { Table, Space, Row, Col, List, Typography, Image, Tabs, Card, Form, DatePicker, message, Select, Input, Button, Modal, Radio, Spin } from 'antd';
import { useOrdersList, useFinishOrders } from '../../services/orders-service';
import { useDebounce } from '../../utils';
import { useOrdersQueryKey, useOrdersSearchParams } from './util';
import { useOrdersGoodsLists } from '../../services/orders-goods-service';
import { OrdersGoods } from "../../interfaces/orders-goods";
import { useOrdersAddressDetail } from '../../services/orders-address-service';
import { useExpressList } from '../../services/express-service';
import { useAddOrdersParcel } from '../../services/orders-parcel-service';

import dayjs from 'dayjs';
import '../orders/list.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text, Title } = Typography;

export const OrdersListPage = () => {
  const imgUrl = process.env.REACT_APP_IMG_URL;
  //参数
  const [param, setParam] = useOrdersSearchParams();
  const { isLoading, error, totalElements, data: list } = useOrdersList(useDebounce(param, 200));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
        setParam({
        ...param,
        pageNum: (current -1),
    })
  }
  const rangeConfig = {
    rules: [{ type: 'array' as const, message: '请选择时间!' }],
  };
  const [startOrdersCreateTime, setStartOrdersCreateTime] = useState();
  const [endOrdersCreateTime, setEndOrdersCreateTime] = useState();
  const handleSelectTime = (value:any ,dateString:any) => {
    setStartOrdersCreateTime(dateString[0]);
    setEndOrdersCreateTime(dateString[1]);
  }
  const [typeId, setTypeId] = useState();
  const handleTab = (key: any, event: any) => {
    setTypeId(key)
    setParam({
      ...param,
      ordersState: key,
      pageNum: null
    })
  }
  //完成
  const onFinish = (values: any) => {
    setParam({
        ...param,
        startOrdersCreateTime: startOrdersCreateTime,
        endOrdersCreateTime: endOrdersCreateTime,
        ordersSn: values.ordersSn,
        ordersReceiverName: values.ordersReceiverName,
        ordersReceiverTel: values.ordersReceiverTel,
        ordersGoodsInfo: values.ordersGoodsInfo,
        ordersState: values.ordersState,
        ordersType: values.ordersType,
        typeId: typeId,
        pageNum: null
    })
  }
  //发货
  const [modalLoading, setModalLoading] = useState(false);
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //商品列表
  const [ordersGoodsList, setOrdersGoodsList] = useState();
  const useMutateOrdersGoodsList = useOrdersGoodsLists;
  const { mutateAsync } = useMutateOrdersGoodsList(
    useOrdersQueryKey()
  );
  //地址详情
  const [ordersAddress, setOrdersAddress] = useState<any>();
  const useMutateOrdersAddressDetail = useOrdersAddressDetail;
  const { mutateAsync: mutateAsyncOrdersAddressDetail } = useMutateOrdersAddressDetail(
    useOrdersQueryKey()
  );
  //物流
  const { data: expressList } = useExpressList();
  const options = expressList?.map((item: any, value: any) =>
    <Option value={item.expressId} key={item.expressId}>{item.expressName}</Option>
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = (ordersId: number) => {
    setModalLoading(true);
    setIsModalVisible(true);
    //调用商品
    mutateAsync({ordersId}).then((data) => {
      setOrdersGoodsList(data?.data)
      setModalLoading(false);
    });
    //调用地址
    mutateAsyncOrdersAddressDetail({ordersId}).then((data) => {
      setOrdersAddress(data.data)
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  //选择配送方式
  const [ordersParcelType, setOrdersParcelType] = useState(1);
  const onChangeOrdersParcelType = (e:any) => {
    setOrdersParcelType(e.target.value);
  };
  //全选
  const [ordersGoodsIds, setOrdersGoodsIds] = useState<any>([])
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: OrdersGoods[]) => {
      setOrdersGoodsIds(selectedRowKeys)
    },
    getCheckboxProps: (record: OrdersGoods) => ({
      disabled: record.goodsState === 30,
    }),
  };
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
  //物流公司选择
  const [expressId, setExpressId] = useState(0)
  function handleChangeExpress(value: any) {
    setExpressId(value)
  }
  //快递单号选择
  const [trackingNumber, setTrackingNumber] = useState("")
  function handleChangeTrackingNumber(e: any) {
    setTrackingNumber(e.target.value)
  }
  //发货
  const [submitAgreeLoading, setSubmitAgreeLoading] = useState(false);
  const useMutateAddOrdersParcel = useAddOrdersParcel
  const { mutateAsync: mutateAsyncAddOrdersParcel } = useMutateAddOrdersParcel(
      useOrdersQueryKey()
  );
  const handleOk = () => {
    if(ordersParcelType == 1) {
      if(ordersGoodsIds.length == 0){
        message.error('请至少选择一件商品');
      }
      if(expressId == 0){
        message.error('请选择快递公司');
      }
      if(trackingNumber.length == 0){
        message.error('请输入真实有效的物流单号');
      }
      if(ordersGoodsIds.length > 0 && expressId > 0 && trackingNumber.length > 0){
        setSubmitAgreeLoading(true)
        mutateAsyncAddOrdersParcel({ ordersGoodsIds, expressId, trackingNumber, ordersParcelType }).then(() => {
          setParam({
              ...param,
              ordersGoodsIds: ordersGoodsIds,
          })
          setIsModalVisible(false);
        });
      }
    } else{
      if(ordersGoodsIds.length == 0){
        message.error('请至少选择一件商品');
      } else{
        //开始提交
        setSubmitAgreeLoading(true)
        mutateAsyncAddOrdersParcel({ ordersGoodsIds, ordersParcelType }).then(() => {
          setParam({
              ...param,
              ordersGoodsIds: ordersGoodsIds,
          })
          setIsModalVisible(false);
        });
      }
    }
  };
  //完成事件
  const useMutateFinishOrders = useFinishOrders;
  const { mutateAsync:  mutateAsyncFinishOrders} = useMutateFinishOrders(
    useOrdersQueryKey()
  );
  const confirmFinishOrders = (ordersId: number) => {
      Modal.confirm({
          title: "确定核销条数据吗?",
          content: "点击确定核销",
          okText: "确定",
          onOk(): any {
              return new Promise((resolve, reject) => {
                mutateAsyncFinishOrders({ ordersId }).then(() => {
                      message.info('核销成功');
                      setParam({
                          ...param,
                          ordersId: ordersId,
                      })
                      Modal.destroyAll();
                  });
              }).catch(
                  () => console.log('Oops errors!')
              );
          },
          cancelText: "取消"
      });
  };
  return (
    <div>
      <Modal confirmLoading={submitAgreeLoading} centered bodyStyle={{height: '100', overflowY: 'auto'}} destroyOnClose={true} width={888} title="订单发货" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Spin spinning={modalLoading}>
          <Row style={{marginBottom: 24}}>
            <Table
              pagination={false}
              style={{width:"100%"}}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              rowKey={ columns => columns.ordersGoodsId }
              columns = {[
                {
                  title: '商品',
                  dataIndex: 'goodsName',
                  key: 'goodsName',
                  width: "40%",
                  render: (value, item:any) => (
                    <Space size="middle">
                      <Row>
                        <Col span={6}>
                          <Image
                            width="100%"
                            src={imgUrl + item?.goodsPic}
                          />
                        </Col>
                        <Col span={18} style={{paddingLeft:10}}>
                          <Row>
                            <Col span={24} style={{marginBottom:5}}>
                              {item?.goodsName}
                            </Col>
                            <Col span={24} style={{marginBottom:5}}>
                              规格：{item?.goodsSkuInfo}
                            </Col>
                            </Row>
                        </Col>
                      </Row>
                    </Space>
                  ),
                },
                {
                  title: '数量',
                  dataIndex: 'goodsNum',
                  key: 'goodsNum',
                  width: "20%",
                },
                {
                  title: '状态',
                  dataIndex: 'goodsState',
                  key: 'goodsState',
                  width: "20%",
                  render(goodsState) {
                    return <span>
                        {goodsState == 30 ? '已发货':'未发货'}
                    </span>
                  },
                },
                {
                  title: '运单号',
                  dataIndex: 'trackingNumber',
                  key: 'trackingNumber',
                  width: "20%",
                },
              ]}
              dataSource={ ordersGoodsList || [] } />
          </Row>
          <Row style={{marginBottom: 24}}>
            <Col style={{marginRight:30}}>
              配送信息
            </Col>
            <Col>
              <Space direction="vertical">
                <Text>配送方式：快递</Text>
                <Text>收货人：{ordersAddress?.ordersAddressReceiverName} {ordersAddress?.ordersAddressReceiverTel}</Text>
                <Text>收货地址：{ordersAddress?.ordersAddressProvince}{ordersAddress?.ordersAddressCity}{ordersAddress?.ordersAddressDistrict}{ordersAddress?.ordersAddressDetail}</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{marginBottom: 24}}>
            <Col style={{marginRight:30}}>
              发货方式
            </Col>
            <Col>
              <Space direction="vertical">
                <Radio.Group onChange={onChangeOrdersParcelType} value={ordersParcelType}>
                  <Radio value={1}>快递配送</Radio>
                  <Radio value={2}>无需物流</Radio>
                </Radio.Group>
              </Space>
            </Col>
          </Row>
          {ordersParcelType == 1 ? <Row>
            <Col style={{marginRight:30, visibility: 'hidden'}}>
              物流信息
            </Col>
            <Col>
              <Row style={{marginBottom: 0}}>
                <Form.Item
                  label={"物流公司"}
                >
                  <Select
                    onChange={handleChangeExpress}
                    showSearch
                    style={{ width: 200 }}
                    placeholder="请选择物流公司"
                    optionFilterProp="children"
                  >
                    {options}
                  </Select>
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  label={"物流单号"}
                >
                  <Input onChange={handleChangeTrackingNumber} style={{width: 200}} placeholder={"输入真实有效的物流单号"} />
                </Form.Item>
              </Row>
            </Col>
          </Row> : ''}
        </Spin>
      </Modal>
      <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
        <Form onFinish={onFinish}>
          <Form.Item name="goodsAddTimeRange" label="下单时间" {...rangeConfig}>
            <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                name="ordersSn"
                label="订单编号"
              >
                <Input placeholder="请输入订单编号" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="ordersReceiverName"
                label="收货人姓名"
              >
                <Input placeholder="请输入收货人姓名" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="ordersReceiverTel"
                label="收货人手机号"
              >
                <Input placeholder="请输入收货人手机号" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                name="ordersGoodsInfo"
                label="商品名称"
              >
                <Input placeholder="请输入商品名称" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="ordersState"
                label="订单状态"
              >
                <Select
                  showSearch
                  placeholder="请选择订单类型"
                  optionFilterProp="children"
                >
                  <Option value={100}>全部</Option>
                  <Option value={10}>待付款</Option>
                  <Option value={20}>待发货</Option>
                  <Option value={30}>待收货</Option>
                  <Option value={35}>待到店消费</Option>
                  <Option value={40}>已完成</Option>
                  <Option value={0}>已取消</Option>
                  <Option value={50}>售后中</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="ordersType"
                label="订单类型"
              >
                <Select
                  showSearch
                  placeholder="请选择订单状态"
                  optionFilterProp="children"
                >
                  <Option value={0}>全部</Option>
                  <Option value={1}>普通订单</Option>
                  <Option value={2}>服务订单</Option>
                  <Option value={3}>洗车卡订单</Option>
                  <Option value={4}>拼团订单</Option>
                  <Option value={5}>秒杀订单</Option>
                  <Option value={7}>砍价订单</Option>
                  <Option value={8}>新人专享</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button style={{ marginLeft:70 }} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Tabs onTabClick={handleTab} defaultActiveKey={param.typeId} type="card" size={"small"}>
        <TabPane tab="全部" key="100">
        </TabPane>
        <TabPane tab="待付款" key="10">
        </TabPane>
        <TabPane tab="待发货" key="20">
        </TabPane>
        <TabPane tab="待收货" key="30">
        </TabPane>
        <TabPane tab="待到店消费" key="35">
        </TabPane>
        <TabPane tab="已完成" key="40">
        </TabPane>
        <TabPane tab="已取消" key="0">
        </TabPane>
        <TabPane tab="售后中" key="50">
        </TabPane>
      </Tabs>
      <Row style={{height: 50, lineHeight: 50, fontWeight: 500, backgroundColor: "#f7f8fa"}}>
        <Col style={{height: 50, lineHeight: "50px",  width: "25%", textAlign:'left', paddingLeft:15}}>商品</Col>
        <Col style={{height: 50, lineHeight: "50px", width: "15%", textAlign:'right', paddingRight:15}}>单价(元) / 数量</Col>
        <Col style={{height: 50, lineHeight: "50px", width: "10%", textAlign:'center'}}>售后</Col>
        <Col style={{height: 50, lineHeight: "50px", width: "10%", textAlign:'center'}}>买家 / 收货人</Col>
        <Col style={{height: 50, lineHeight: "50px", width: "10%", textAlign:'center'}}>配送方式</Col>
        <Col style={{height: 50, lineHeight: "50px", width: "10%", textAlign:'center'}}>实收金额(元)</Col>
        <Col style={{height: 50, lineHeight: "50px", width: "10%", textAlign:'center'}}>订单状态</Col>
        <Col style={{height: 50, lineHeight: "50px", width: "10%", textAlign:'center'}}>操作</Col>
      </Row>
      <List
        loading = { isLoading }
        pagination = { paginationProps }
        dataSource={list || []}
        renderItem={(item:any) => (
          <List.Item style={{display: 'block', borderBottom: '0px solid #f0f0f0'}}>
              <Table
                bordered={true}
                showHeader={false}
                pagination={false}
                dataSource={item.ordersGoodsList}
                title={() => <Row>
                  <Col span={20}>
                    <span style={{marginRight:20}}>订单号：{item.ordersSn}</span>
                    {item.ordersOutSn ? <span style={{marginRight:20}}>三方单号：{item.ordersOutSn}</span> : ''}
                    <span style={{marginRight:20}}>下单时间：{item.ordersCreateTime ? dayjs(item.ordersCreateTime).format('YYYY-MM-DD HH:mm:ss'):'无'}</span>
                  </Col>
                  <Col style={{textAlign:'right', paddingRight: 20}} span={4}>
                    <span>
                      <Link to={"/orders/ordersManage/detail/" + item.ordersId}>
                        查看详情
                      </Link>
                    </span>
                    <span style={{marginLeft:5, marginRight: 5}}>
                      -
                    </span>
                    <span>
                      备注
                    </span>
                  </Col>
                </Row>}
                columns={[
                    {
                      title: '商品',
                      dataIndex: 'goodsName',
                      key: 'goodsName',
                      width: "40%",
                      render: (value, ordersGoods) => (
                        <Space size="middle">
                            <Row>
                                <Col style={{width:"10%"}}>
                                    <Image
                                        width="100%"
                                        src={imgUrl + ordersGoods.goodsPic}
                                    />
                                </Col>
                                <Col style={{paddingLeft:10, width:"60%"}}>
                                  {ordersGoods.goodsName}
                                </Col>
                                <Col style={{width:"30%", textAlign: 'right'}}>
                                  {ordersGoods.goodsPrice}<br/>{ordersGoods.goodsNum}件
                                </Col>
                            </Row>
                        </Space>
                      ),
                    },
                    {
                      title: '售后',
                      dataIndex: 'ordersRefund',
                      key: 'ordersRefund',
                      width: "10%",
                      align: 'center',
                      render: (text:any, row:any, index:any) => {
                        if (index === 0) {
                          return {
                            children: '',
                            props: {
                              rowSpan: item.ordersGoodsNum
                            },
                          };
                        }
                        if (index > 0) {
                          return {
                            props: {
                              rowSpan: 0
                            },
                          };
                        }
                      },
                    },
                    {
                      title: '买家 / 收货人',
                      dataIndex: 'ordersReceiverName',
                      key: 'ordersReceiverName',
                      width: "10%",
                      align: 'center',
                      render: (text:any, row:any, index:any) => {
                        if (index === 0) {
                          return {
                            children: <span>{item.memberNickname} <br/> {item.ordersReceiverName} <br/> {item.ordersReceiverTel}</span>,
                            props: {
                              rowSpan: item.ordersGoodsNum
                            },
                          };
                        }
                        if (index > 0) {
                          return {
                            props: {
                              rowSpan: 0
                            },
                          };
                        }
                      },
                    },
                    {
                      title: '配送方式',
                      dataIndex: 'ordersShipping',
                      key: 'ordersShipping',
                      width: "10%",
                      align: 'center',
                      render: (text:any, row:any, index:any) => {
                        if (index === 0) {
                          return {
                            children: <span>快递</span>,
                            props: {
                              rowSpan: item.ordersGoodsNum
                            },
                          };
                        }
                        if (index > 0) {
                          return {
                            props: {
                              rowSpan: 0
                            },
                          };
                        }
                      },
                    },
                    {
                      title: '实收金额(元)',
                      dataIndex: 'ordersActuallyAmount',
                      key: 'ordersActuallyAmount',
                      width: "10%",
                      align: 'center',
                      render: (text:any, row:any, index:any) => {
                        if (index === 0) {
                          return {
                            children: <span>{item.ordersActuallyAmount}</span>,
                            props: {
                              rowSpan: item.ordersGoodsNum
                            },
                          };
                        }
                        if (index > 0) {
                          return {
                            props: {
                              rowSpan: 0
                            },
                          };
                        }
                      },
                    },
                    {
                      title: '订单状态',
                      dataIndex: 'ordersState',
                      key: 'ordersState',
                      width: "10%",
                      align: 'center',
                      render: (text:any, row:any, index:any) => {
                        if (index === 0) {
                          return {
                            children: <span>{item.ordersStateValue}</span>,
                            props: {
                              rowSpan: item.ordersGoodsNum
                            },
                          };
                        }
                        if (index > 0) {
                          return {
                            props: {
                              rowSpan: 0
                            },
                          };
                        }
                      },
                    },
                    {
                      title: '操作',
                      dataIndex: 'action',
                      key: 'action',
                      width: "10%",
                      align: 'center',
                      render: (text:any, row:any, index:any) => {
                        if (index === 0) {
                          return {
                            children: <span>
                              {(item.ordersState == 20 && item.ordersDelivery == true) ? <Space size="middle">
                                <Button onClick={ () => showModal(item?.ordersId) }>
                                  发货
                                </Button>
                              </Space> : ''}
                              {(item.ordersState == 20 && item.ordersDelivery == false) ? <Space size="middle">
                                <Button onClick={ () => confirmFinishOrders(item?.ordersId) }>
                                  核销
                                </Button>
                              </Space> : ''}
                            </span>,
                            props: {
                              rowSpan: item.ordersGoodsNum
                            },
                          };
                        }
                        if (index > 0) {
                          return {
                            children: <span>
                              {(item.ordersState == 20 && item.ordersDelivery == false)? <Space size="middle">
                                <Button onClick={ () => showModal(item?.ordersId) }>
                                  发货
                                </Button>
                              </Space> : ''}
                              {(item.ordersState == 20 && item.ordersDelivery == false)? <Space size="middle">
                                <Button onClick={ () => confirmFinishOrders(item?.ordersId) }>
                                  核销
                                </Button>
                              </Space> : ''}
                            </span>,
                            props: {
                              rowSpan: 0
                            },
                          };
                        }

                      },
                    },
                  ]}
              />
          </List.Item>
        )}
      />
    </div>
  )
}
