import { Typography, Card, Col, Row, Statistic } from "antd"
import React from "react";
import { useState } from "react";
import { useDashboard } from '../../services/dashboard-service';
import { useStore } from '../../services/store-service';
const { Text, Link } = Typography

export const DashboardPage = () => {
    const { isLoading, data: dashboard } = useDashboard();
    const { data: store } = useStore();
    return (
        <div style={{ background: '#f0f2f5' }}>
            <Card style={{ width: "100%" }}>
                <Row style={{fontWeight:700, fontSize:16, paddingBottom:10}}>
                    实时概况
                </Row>
                <Row style={{textAlign:"center"}}>
                    <Col span={12} style={{ borderRight: "1px solid #f0f2f5" }}>
                        <Row gutter={16} style={{height:100}}>
                            <Col span={12}>
                                <Statistic title="支付订单数" value={dashboard?.data?.todayOrdersNum} />
                                <Text style={{color:'#666'}}>昨日订单数：{dashboard?.data?.yesterdayOrdersNum}</Text>
                            </Col>
                            <Col span={12} style={{height:100}}>
                                <Statistic title="支付金额(元)" value={dashboard?.data?.todayOrdersAmount} />
                                <Text style={{color:'#666'}}>昨日支付金额：{dashboard?.data?.yesterdayOrdersAmount}</Text>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{height:100, paddingTop:24}}>
                            <Col span={12} >
                                <Statistic title="可用店铺余额(元)" value={store?.data?.storeMoney} />
                            </Col>
                            <Col span={12} style={{height:100}}>
                                <Statistic title="待结算(元)" value={store?.data?.storeMoneyReady} />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={16} style={{height:100}}>
                            <Col span={12}>
                                <Statistic title="待付款" value={dashboard?.data?.waitPayOrdersNum} />
                            </Col>
                            <Col span={12}>
                                <Statistic title="待发货/核销" value={dashboard?.data?.waitDeliverOrdersNum} />
                            </Col>
                        </Row>
                        <Row gutter={16} style={{height:100, paddingTop:24}}>
                            {/* <Col span={12}>
                                <Statistic title="待核销" value={dashboard?.data?.waitVerificationOrdersNum} />
                            </Col> */}
                            <Col span={12}>
                                <Statistic title="待售后" value={dashboard?.data?.refundingNum} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}