import { Button, Form, Input } from "antd";
import { useAuth } from "../../context/auth-context";
import { useAsync } from "../../utils/use-async";

export const LoginPage = ({
    onError,
}: {
    onError: (error: Error) => void;
}) => {
    const { login , storeAccount } = useAuth();
    const { run, isLoading } = useAsync(undefined, { throwOnError: true });
    
    const handleSubmit = async (values: any) => {
        try {
            await run(login(values));
        } catch (e) {
            onError(e);
        }
    };
    return (
        <Form onFinish={handleSubmit}>
            <Form.Item name={ "storeAccountName" } rules={[{required: true, message: "请输入用户名"}]}>
                <Input placeholder={"用户名"} type="text" id={"storeAccountName"} />
            </Form.Item>
            <Form.Item name={ "storeAccountPassword" } rules={[{required: true, message: "请输入密码"}]}>
                <Input placeholder={"密码"} type="password" id={"storeAccountPassword"} />
            </Form.Item>
            <Form.Item>
                <Button style={{ width: "100%" }} htmlType={"submit"} type={"primary"}>
                    登录
                </Button>
            </Form.Item>
        </Form>
    )
}