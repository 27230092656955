import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useGoodsSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["goodsSpecValueIds", "goodsSpecIds", "goodsId", "goodsName", "startGoodsAddTime", "endGoodsAddTime", "minGoodsSaleNum", "maxGoodsSaleNum", "minGoodsPrice", "maxGoodsPrice", "goodsClassId", "typeId", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, goodsId: Number(param.goodsId) || undefined, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useGoodsQueryKey = () => {
  const [params] = useGoodsSearchParams();
  return ["goodsList", params];
};