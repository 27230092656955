import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { StorePhoto } from "../interfaces/store-photo";

//列表
export const useStorePhotoList = (param?: Partial<StorePhoto>) => {
    const client = useHttp();
    const result = useQuery<any>(["storePhotoList", param], () =>
        client("storePhoto/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增
export const useAddStorePhoto = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StorePhoto>) =>
            client(`storePhoto/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑
export const useEditStorePhoto = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<StorePhoto>) =>
            client(`storePhoto/update?storePhotoId=${params.storePhotoId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除
export const useDeleteStorePhoto = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ storePhotoId }: { storePhotoId: number}) =>
            client(`storePhoto/delete?storePhotoId=${storePhotoId}`, {
            method: "DELETE",
        })
    );
};


//详情
export const useStorePhoto = (storePhotoId?: number) => {
    const client = useHttp();
    return useQuery<any>(["storePhotoList", { storePhotoId }], () =>
        client(`storePhoto/detail?storePhotoId=${storePhotoId}`),
        {
            enabled: Boolean(storePhotoId)
        }
    );
};

