import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { OrdersRefund } from "../interfaces/orders-refund";

//列表
export const useOrdersRefundList = (param?: Partial<OrdersRefund>) => {
    const client = useHttp();
    const result = useQuery<any>(["ordersRefundList", param], () =>
        client("ordersRefund/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//详情
export const useOrdersRefund = (ordersRefundId?: number) => {
    const client = useHttp();
    return useQuery<any>(["ordersRefundDetail", { ordersRefundId }], () =>
        client(`ordersRefund/detail?ordersRefundId=${ordersRefundId}`),
        {
            enabled: Boolean(ordersRefundId)
        }
    );
};

//商品详情
export const useOrdersRefundOrdersGoods = (ordersRefundId?: number) => {
    const client = useHttp();
    return useQuery<any>(["ordersRefundOrdersGoodsDetail", { ordersRefundId }], () =>
        client(`ordersRefund/ordersGoods?ordersRefundId=${ordersRefundId}`),
        {
            enabled: Boolean(ordersRefundId)
        }
    );
};

//同意
export const useAgreeOrdersRefund = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ ordersRefundId }: { ordersRefundId: number}) =>
            client(`ordersRefund/agree?ordersRefundId=${ordersRefundId}`, {
            method: "GET",
        })
    );
};

//拒绝
export const useRejectOrdersRefund = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<OrdersRefund>) =>
            client(`ordersRefund/reject?ordersRefundId=${params.ordersRefundId}`, {
                data: params,
                method: "PUT"
            }),
    );
};