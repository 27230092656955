import qs from "qs";
import * as auth from "../services/auth-provider";
import { useAuth } from "../context/auth-context";
import { useCallback } from "react";

const apiUrl = process.env.REACT_APP_API_URL;

interface Config extends RequestInit {
    token?: string;
    stoken?: string;
    shopId?: string;
    data?: object;
}

export const http = async (
    endpoint: string,
    { data, token, stoken, shopId, headers, ...customConfig }: Config = {}
  ) => {
    const config = {
      method: "GET",
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "stoken": stoken ? `${stoken}` : "",
        "shopId": "1",
        "Content-Type": data ? "application/json" : "",
      },
      ...customConfig,
    };
  
    if (config.method.toUpperCase() === "GET") {
      const datas = qs.stringify(data)
      if(datas != null && datas.length > 0){
        endpoint += `?${qs.stringify(data)}`;
      } else {
        endpoint += `${qs.stringify(data)}`;
      }
    } else {
      config.body = JSON.stringify(data || {});
    }
  
    // axios 和 fetch 的表现不一样，axios可以直接在返回状态不为2xx的时候抛出异常
    return window
      .fetch(`${apiUrl}/${endpoint}`, config)
      .then(async (response) => {
        if (response.status === 401) {
          await auth.logout();
          window.location.reload();
          return Promise.reject({ message: "请重新登录" });
        }
        const data = await response.json();
        if (response.ok) {
          return data;
        } else {
          return Promise.reject(data);
        }
      });
};

export const useHttp = () => {
    const { storeAccount } = useAuth();
    // utility type 的用法：用泛型给它传入一个其他类型，然后utility type对这个类型进行某种操作
    return useCallback(
      (...[endpoint, config]: Parameters<typeof http>) =>
        http(endpoint, { ...config, token: storeAccount?.token, stoken: storeAccount?.stoken, shopId: "8"}),
      [storeAccount?.token]
    );
};



export const https = async (
  endpoint: string,
  { data, token, stoken, shopId, headers, ...customConfig }: Config = {}
) => {
  const config = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "stoken": stoken ? `${stoken}` : "",
      "shopId": "1",
      "Content-Type": data ? "application/json" : "",
    },
    ...customConfig,
  };

  if (config.method.toUpperCase() === "GET") {
    const datas = qs.stringify(data)
    if(datas != null && datas.length > 0){
      endpoint += `&${qs.stringify(data)}`;
    } else {
      endpoint += `${qs.stringify(data)}`;
    }
  } else {
    config.body = JSON.stringify(data || {});
  }

  // axios 和 fetch 的表现不一样，axios可以直接在返回状态不为2xx的时候抛出异常
  return window
    .fetch(`${apiUrl}/${endpoint}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        await auth.logout();
        window.location.reload();
        return Promise.reject({ message: "请重新登录" });
      }
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
};

export const useHttps = () => {
  const { storeAccount } = useAuth();
  // utility type 的用法：用泛型给它传入一个其他类型，然后utility type对这个类型进行某种操作
  return useCallback(
    (...[endpoint, config]: Parameters<typeof http>) =>
      https(endpoint, { ...config, token: storeAccount?.token, stoken: storeAccount?.stoken, shopId: "8"}),
    [storeAccount?.token]
  );
};

