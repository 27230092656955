import { Button, Col, Form, Input, Row, Tabs, Select, message, Upload, Typography, Radio, Space, DatePicker, Card } from "antd";
import { LoadingOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useEditGoods, useGoods, useGoodsDetail } from '../../services/goods-service';
import { useGoodsClassListTree } from '../../services/goods-class-service';
import { useTransportListAll } from '../../services/transport-service';
import { useGoodsSkuListByGoodsId } from '../../services/goods-sku-service';
import { useGoodsSpecListAll } from '../../services/goods-spec-service';
import { useGoodsSpecValueListAll } from '../../services/goods-spec-value-service';
import { useGoodsSkuCreateList } from '../../services/goods-sku-service';
import { useGoodsQueryKey, useGoodsSearchParams } from './util';
import { useGoodsSpecValueQueryKey, useGoodsSpecValueSearchParams } from '../goods-spec-value/util';
import { Link, useLocation } from "react-router-dom";
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css';
import moment from "moment";
const { Option } = Select;
const { TabPane } = Tabs;
const { Text } = Typography;
const { RangePicker } = DatePicker;

export const GoodsUpdatePage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const imgUrl = process.env.REACT_APP_IMG_URL;
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //富文本
    const [controls, setControls] = useState<any>([
        'undo', 'redo', 'separator',
        'font-size', 'line-height', 'letter-spacing', 'separator',
        'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
        'superscript', 'subscript', 'remove-styles', 'emoji',  'separator', 'text-indent', 'text-align', 'separator',
        'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
        'link', 'separator', 'hr', 'separator',
        'media', 'separator',
        'clear'
    ]);
    const [form] = useForm();
    const [submitLoading, setSubmitLoading] = useState(false);
    //获取数据
    const { pathname } = useLocation();
    const goodsId = pathname.match(/goods\/goodsManage\/update\/(\d+)/)?.[1]
    //准备分类
    const { data: goodsClassList } = useGoodsClassListTree();
    const options = goodsClassList?.data?.map((item: any, value: any) =>
        <Option value={item.goodsClassId} key={item.goodsClassId}>{item.goodsClassName}</Option>
    );
    const { data: editingGoods, isLoading } = useGoods(
        Number(goodsId)
    );
    const [goodsPicUrl, setGoodsPicUrl] = useState("");
    const [goodsVideoUrl, setGoodsVideoUrl] = useState("");
    const [goodsVideoCoverPreviewPicUrl, setGoodsVideoCoverPreviewPicUrl] = useState("");
    const [goodsShowStartTimeStr, setGoodsShowStartTimeStr] = useState("");
    const [fileList, setFileList] = useState<any>([]);
    //表单
    useEffect(() => {
        form.setFieldsValue({
            "uploadMore": editingGoods?.data?.goodsMorePicVOList
        })
        form.setFieldsValue(editingGoods?.data)
        const goodsPic = imgUrl + editingGoods?.data?.goodsPic;
        setGoodsPicUrl(goodsPic)
        if(editingGoods?.data?.goodsVideo){
            const goodsVideo = imgUrl + editingGoods?.data?.goodsVideo;
            setGoodsVideoUrl(goodsVideo)
        }
        if(editingGoods?.data?.goodsVideoCoverPreviewPic){
            const goodsVideoCoverPreviewPic = imgUrl + editingGoods?.data?.goodsVideoCoverPreviewPic;
            setGoodsVideoCoverPreviewPicUrl(goodsVideoCoverPreviewPic)
        }
        if(editingGoods?.data?.goodsShowStartTimeStr){
            setGoodsShowStartTimeStr(editingGoods?.data?.goodsShowStartTimeStr)
            form.setFieldsValue({
                "goodsShowStartTimeStr": moment(editingGoods?.data?.goodsShowStartTimeStr, "YYYY-MM-DD HH:mm:ss")
            })
        }
        if(editingGoods?.data?.goodsMorePicVOList){
            setFileList(editingGoods?.data?.goodsMorePicVOList);
        }
    }, [editingGoods?.data, form]);
    //获取商品详情
    const { data: editingGoodsDetail } = useGoodsDetail(
        Number(goodsId)
    );
    const [goodsContent, setGoodsContent] = useState("");
    useEffect(() => {
        setGoodsContent(editingGoodsDetail?.data?.goodsContent);
        form.setFieldsValue({
            "goodsContent": BraftEditor.createEditorState(editingGoodsDetail?.data?.goodsContent)
        })
    }, [editingGoodsDetail, form]);
    //准备多规格的内容
    const { data: goodsSkuList } = useGoodsSkuListByGoodsId(
        Number(goodsId)
    );
    const [goodsSpecOpenShow, setGoodsSpecOpenShow] = useState(false);
    const [goodsSkuDTOList, setGoodsSkuDTOList] = useState();
    useEffect(() => {
        //是否显示多规格
        setGoodsSpecOpenShow(editingGoods?.data?.goodsSpecOpen);
        //多规格
        setGoodsSkuDTOList(goodsSkuList)
        form.setFieldsValue({ goodsSkuDTOList: goodsSkuList });
    }, [goodsSkuList, form]);
    //规格值
    const { data: goodsSpecList } = useGoodsSpecListAll();
    const goodsSpecOptions = goodsSpecList?.map((item: any, value: any) =>
        <Option value={item.goodsSpecId} key={item.goodsSpecId}>{item.goodsSpecName}</Option>
    );
    //上传商品主图
    const [uploadGoodsPicLoading, setUploadGoodsPicLoading] = useState(false);
    const uploadGoodsPicButton = (
        <div>
          {uploadGoodsPicLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const handleChangeGoodsPic = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadGoodsPicLoading(false);
            setGoodsPicUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                goodsPic: relativePath
            })
        }
    };
    const beforeUploadGoodsPic = (file:any) => {
        setGoodsPicUrl("");
        setUploadGoodsPicLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadGoodsPicLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadGoodsPicLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const normGoodsPicFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //上传多图
    const uploadGoodsMorePicButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const handleChangeGoodsMorePic = (info:any) => {
        console.log(info)
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setFileList(info?.fileList)
        }
        if (info.file.status === 'removed') {
            setFileList(info?.fileList)
        }
    };
    const beforeUploadGoodsMorePic = (file:any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const normGoodsMorePicFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //上传视频封面
    const [uploadGoodsVideoCoverPreviewPicLoading, setUploadGoodsVideoCoverPreviewPicLoading] = useState(false);
    const uploadGoodsVideoCoverPreviewPicButton = (
        <div>
          {uploadGoodsVideoCoverPreviewPicLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const handleChangeGoodsVideoCoverPreviewPic = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadGoodsVideoCoverPreviewPicLoading(false);
            setGoodsVideoCoverPreviewPicUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                goodsVideoCoverPreviewPic: relativePath
            })
        }
    };
    const beforeUploadGoodsVideoCoverPreviewPic = (file:any) => {
        setGoodsVideoCoverPreviewPicUrl("");
        setUploadGoodsVideoCoverPreviewPicLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadGoodsVideoCoverPreviewPicLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadGoodsVideoCoverPreviewPicLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const normGoodsVideoCoverPreviewPicFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //上传视频
    const [uploadGoodsVideoLoading, setUploadGoodsVideoLoading] = useState(false);
    const uploadGoodsVideoButton = (
        <div>
          {uploadGoodsVideoLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传视频</div>
        </div>
    );
    const beforeGoodsVideoUpload = (file:any) => {
        setGoodsVideoUrl("");
        setUploadGoodsVideoLoading(true);
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
            setUploadGoodsVideoLoading(false);
            message.error('Image must smaller than 20MB!');
        }
        return isLt20M;
    }
    const handleGoodsVideoChange = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadGoodsVideoLoading(false);
            setGoodsVideoUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                goodsVideo: relativePath
            })
        }
    };
    const normGoodsVideoFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //时间选择
    const handleSelectGoodsShowStartTime = (value:any ,dateString:any) => {
        setGoodsShowStartTimeStr(dateString);
    }
    const handleEditorChange = (editorState:any) => {
        setGoodsContent(editorState.toHTML())
    }
    //提交
    const useMutateGoods = useEditGoods;
    const { mutateAsync: mutateAsyncUpdateGoods, error } = useMutateGoods(
        useGoodsQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        if (values.goodsShow == 2) {
            if(goodsShowStartTimeStr.length == 0){
                setSubmitLoading(false);
                message.error('开售时间必选');
                return;
            }
        }
        if(values.goodsUniformFreight == true) {
            console.log(values.goodsFreight)
            if(values.goodsFreight == null){
                console.log(values.goodsFreight)
                setSubmitLoading(false);
                message.error('请输入快递运费');
                return;
            }
        }
        console.log(fileList)
        var goodsMorePic = "";
        fileList?.map((item: any, value: any) => {
            console.log(item?.response)
            goodsMorePic = goodsMorePic + item?.response + ",";
        })
        const goods = {
            goodsId: editingGoods?.data?.goodsId,
            goodsName: values.goodsName,
            goodsPromotions: values.goodsPromotions,
            goodsSpecOpen: values.goodsSpecOpen,
            goodsSpecName: values.goodsSpecName,
            goodsVideo: values.goodsVideo,
            goodsVideoCoverPreviewPic: values.goodsVideoCoverPreviewPic,
            goodsPic: values.goodsPic,
            goodsMorePic: goodsMorePic,
            goodsPrice: values.goodsPrice,
            goodsOriginalPrice: values.goodsOriginalPrice,
            goodsShow: values.goodsShow,
            goodsUniformFreight: values.goodsUniformFreight,
            goodsFreight: values.goodsFreight,
            transportId: values.transportId,
            goodsStorage: values.goodsStorage,
            goodsSort: values.goodsSort,
            goodsClassId: values.goodsClassId,
            goodsDelivery: values.goodsDelivery,
            goodsSpu: values.goodsSpu
        }
        const goodsSkuDTOList = values?.goodsSkuDTOList
        const goodsDetail = {
            goodsContent: goodsContent
        };
        mutateAsyncUpdateGoods({ goods, goodsSkuDTOList, goodsDetail, goodsShowStartTimeStr }).then(() => {
            window.location.href = "/#/goods/goodsManage/list";
        });
    }
    //富文本上传
    const myUploadFn = (param: any) => {
        const serverURL = apiUrl + '/upload/braftEditorImag'
        const xhr = new XMLHttpRequest
        const fd = new FormData()
        const successFn = (response: any) => {
          // 假设服务端直接返回文件上传后的地址
          // 上传成功后调用param.success并传入上传后的文件地址
          param.success({
            url: xhr.responseText,
            meta: {
              loop: true, // 指定音视频是否循环播放
              autoPlay: true, // 指定音视频是否自动播放
              controls: true, // 指定音视频是否显示控制栏
            }
          })
        }
        const progressFn = (event: any) => {
          // 上传进度发生变化时调用param.progress
          param.progress(event.loaded / event.total * 100)
        }
        const errorFn = (response: any) => {
          // 上传发生错误时调用param.error
          param.error({
            msg: 'unable to upload.'
          })
        }
        xhr.upload.addEventListener("progress", progressFn, false)
        xhr.addEventListener("load", successFn, false)
        xhr.addEventListener("error", errorFn, false)
        xhr.addEventListener("abort", errorFn, false)
        fd.append('file', param.file)
        xhr.open('POST', serverURL, true)
        xhr.setRequestHeader("name", "imageFile");
        xhr.send(fd)
    }

    //执行查询规格值 / useGoodsSpecValueList
    const useMutateGoodsSpecValueList = useGoodsSpecValueListAll;
    const { mutateAsync } = useMutateGoodsSpecValueList(
        useGoodsSpecValueQueryKey()
    );

    //goodsSpecIds
    const useMutateGoodsSkuCreateList = useGoodsSkuCreateList;
    const { mutateAsync: mutateAsyncGoodsSkuCreateList } = useMutateGoodsSkuCreateList(
        useGoodsQueryKey()
    );

    const [goodsSpecValueOptions1, setGoodsSpecValueOptions1] = useState();
    function changeGoodsSpecId1(value: any){
        const goodsSpecId = value
        mutateAsync({ goodsSpecId }).then((data: any) => {
            const goodsSpecValueList = data.data
            const goodsSpecValueOptions = goodsSpecValueList?.map((item: any, value: any) =>
                <Option value={item.goodsSpecValueId} key={item.goodsSpecValueId}>{item.goodsSpecValueName}</Option>
            );
            setGoodsSpecValueOptions1(goodsSpecValueOptions)
        });
    }
    const [goodsSpecValueOptions2, setGoodsSpecValueOptions2] = useState();
    function changeGoodsSpecId2(value: any){
        const goodsSpecId = value
        mutateAsync({ goodsSpecId }).then((data: any) => {
            const goodsSpecValueList = data.data
            const goodsSpecValueOptions = goodsSpecValueList?.map((item: any, value: any) =>
                <Option value={item.goodsSpecValueId} key={item.goodsSpecValueId}>{item.goodsSpecValueName}</Option>
            );
            setGoodsSpecValueOptions2(goodsSpecValueOptions)
        });
    }

    const [goodsSpecValueOptions3, setGoodsSpecValueOptions3] = useState();
    function changeGoodsSpecId3(value: any){
        const goodsSpecId = value
        mutateAsync({ goodsSpecId }).then((data: any) => {
            const goodsSpecValueList = data.data
            const goodsSpecValueOptions = goodsSpecValueList?.map((item: any, value: any) =>
                <Option value={item.goodsSpecValueId} key={item.goodsSpecValueId}>{item.goodsSpecValueName}</Option>
            );
            setGoodsSpecValueOptions3(goodsSpecValueOptions)
        });
    }
    const [goodsSpecValue1, setGoodsSpecValue1] = useState([]);
    const [goodsSpecValue2, setGoodsSpecValue2] = useState([]);
    const [goodsSpecValue3, setGoodsSpecValue3] = useState([]);
    function handleChange1(value: any){
        setGoodsSpecValue1(value)
        const goodsSpecValueIds:(number)[] = [...value, ...goodsSpecValue2, ...goodsSpecValue3]
        mutateAsyncGoodsSkuCreateList({goodsSpecValueIds}).then((data) => {
            setGoodsSkuDTOList(data.data)
            form.setFieldsValue({ goodsSkuDTOList: data.data });
        });
    }
    function handleChange2(value: any){
        setGoodsSpecValue2(value)
        const goodsSpecValueIds:(number)[] = [...goodsSpecValue1, ...value, ...goodsSpecValue3]
        mutateAsyncGoodsSkuCreateList({goodsSpecValueIds}).then((data) => {
            setGoodsSkuDTOList(data.data)
            form.setFieldsValue({ goodsSkuDTOList: data.data });
        });
    }
    function handleChange3(value: any){
        setGoodsSpecValue3(value)
        const goodsSpecValueIds:(number)[] = [...goodsSpecValue1, ...goodsSpecValue2, ...value]
        mutateAsyncGoodsSkuCreateList({goodsSpecValueIds}).then((data) => {
            setGoodsSkuDTOList(data.data)
            form.setFieldsValue({ goodsSkuDTOList: data.data });
        });
    }

    function changeGoodsSpecOpen(e: any){
        setGoodsSpecOpenShow(e.target.value)
    }

    return(
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/goods/goodsManage/list"}>
                        <Button type="primary">商品列表</Button>
                    </Link>
                </Col>
            </Row>
            <Form
                form={form}
                {...layout}
                name="basic"
                onFinish={onFinish}
            >
                <Tabs defaultActiveKey="1">
                    <TabPane tab="基本信息" key="1">

                        <Form.Item
                            label={"商品名称"}
                            name={"goodsName"}
                            rules={[{ required: true, message: "商品名称必填" }]}
                            style={{marginTop: 24}}
                        >
                            <Input style={{width: 380}} placeholder={"请输入商品名称"} />
                        </Form.Item>

                        <Form.Item
                            label={"商品卖点"}
                            name={"goodsPromotions"}
                        >
                            <Input style={{width: 380}} placeholder={"请输入商品卖点"} />
                        </Form.Item>

                        <Form.Item
                            label={"商品SPU"}
                            name={"goodsSpu"}
                        >
                            <Input style={{width: 380}} placeholder={"请输入商品SPU"} />
                        </Form.Item>

                        <Form.Item
                            label={"商品分类"}
                            name={"goodsClassId"}
                            rules={[{ required: true, message: "商品分类必选" }]}
                        >
                            <Select
                                showSearch
                                placeholder="请选择商品分类"
                                optionFilterProp="children"
                                style={{textAlign:'left', width: 380}}
                            >
                                {options}
                            </Select>
                        </Form.Item>

                        {/* 商品主图 */}
                        <Form.Item
                            name={"uploadGoodsPic"}
                            label={"商品主图"}
                            valuePropName="fileList"
                            getValueFromEvent={normGoodsPicFile}
                            rules={[{ required: true, message: "商品主图必传" }]}
                            style={{ textAlign:'left' }}
                            initialValue={[
                                {
                                "uid": "",
                                "lastModified": "",
                                "lastModifiedDate": "",
                                "name": "",
                                "size": "",
                                "type": "",
                                "percent": "",
                                "originFileObj": {
                                    "uid": ""
                                },
                                "status": "done",
                                "response": {
                                    "status": 0,
                                    "data": {
                                    "relativePath": "",
                                    "realPath": ""
                                    }
                                },
                                "xhr": {}
                                }
                            ]}
                        >
                            <Upload
                                name="imageFile"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={apiUrl + "/upload/img"}
                                beforeUpload={beforeUploadGoodsPic}
                                onChange={handleChangeGoodsPic}
                            >
                                {goodsPicUrl ? <img src={goodsPicUrl} alt="avatar" style={{ width: '100%' }} /> : uploadGoodsPicButton}
                            </Upload>
                        </Form.Item>

                        <Form.Item hidden
                            label={"商品主图"}
                            name={"goodsPic"}
                            rules={[{ required: true, message: "商品主图必传" }]}
                        >
                            <Input />
                        </Form.Item>

                        {/* 商品详情图 */}
                        <Form.Item
                            name={"uploadMore"}
                            label={"商品详情图"}
                            valuePropName="fileList"
                            getValueFromEvent={normGoodsMorePicFile}
                            style={{ textAlign:'left' }}
                            initialValue={fileList}
                        >
                            <Upload
                                name="imageFile"
                                action={apiUrl + "/upload/antdImg"}
                                listType="picture-card"
                                fileList={fileList}
                                beforeUpload={beforeUploadGoodsMorePic}
                                onChange={handleChangeGoodsMorePic}
                            >

                                {fileList.length < 5? uploadGoodsMorePicButton : ''}
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name={"uploadGoodsVideo"}
                            label={"商品视频"}
                            valuePropName="fileList"
                            getValueFromEvent={normGoodsVideoFile}
                            style={{ textAlign:'left' }}
                        >
                            <Upload
                                name="videoFile"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={apiUrl + "/upload/video"}
                                beforeUpload={beforeGoodsVideoUpload}
                                onChange={handleGoodsVideoChange}
                            >
                                {goodsVideoUrl ? <video src={goodsVideoUrl} style={{ width: '100%' }} /> : uploadGoodsVideoButton}
                            </Upload>
                        </Form.Item>

                        <Form.Item hidden
                            label={"商品视频"}
                            name={"goodsVideo"}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name={"uploadGoodsVideoCoverPreviewPic"}
                            label={"视频贴片"}
                            valuePropName="fileList"
                            getValueFromEvent={normGoodsVideoCoverPreviewPicFile}
                            style={{ textAlign:'left' }}
                        >
                            <Upload
                                name="imageFile"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={apiUrl + "/upload/img"}
                                beforeUpload={beforeUploadGoodsVideoCoverPreviewPic}
                                onChange={handleChangeGoodsVideoCoverPreviewPic}
                            >
                                {goodsVideoCoverPreviewPicUrl ? <img src={goodsVideoCoverPreviewPicUrl} alt="avatar" style={{ width: '100%' }} /> : uploadGoodsVideoCoverPreviewPicButton}
                            </Upload>
                            <Text style={{color: "#999", fontSize: 12}}>视频贴片是指视频播放器在播放前展示的图片。</Text>
                        </Form.Item>

                        <Form.Item hidden
                            label={"视频贴片"}
                            name={"goodsVideoCoverPreviewPic"}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={"统一规格"}
                            name={"goodsSpecOpen"}
                            rules={[{ required: true, message: "统一规格必选" }]}
                            style={{ textAlign:'left' }}
                        >
                            <Radio.Group onChange={changeGoodsSpecOpen}>
                                <Radio defaultChecked value={false}>是</Radio>
                                <Radio value={true}>否</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {goodsSpecOpenShow == true ? "" : <Form.Item
                            label={"商品规格"}
                            name={"goodsSpecName"}
                            rules={[{ required: true, message: "商品规格必填" }]}
                            style={{marginTop: 24}}
                        >
                            <Input style={{width: 380}} placeholder={"请输入商品规格"} />
                        </Form.Item> }
                        
                        {goodsSpecOpenShow == true ? "" : <Form.Item
                            label={"商品价格"}
                            name={"goodsPrice"}
                            rules={[{ required: true, message: "商品价格必填" }]}
                            style={{marginTop: 24}}
                        >
                            <Input style={{width: 380}} placeholder={"请输入商品价格"} />
                        </Form.Item> }

                        {goodsSpecOpenShow == true ? "" : <Form.Item
                            label={"商品划线价"}
                            name={"goodsOriginalPrice"}
                            rules={[{ required: true, message: "商品划线价必填" }]}
                            style={{marginTop: 24}}
                        >
                            <Input style={{width: 380}} placeholder={"请输入商品划线价"} />
                        </Form.Item> }
                        
                        {goodsSpecOpenShow == true ? "" : <Form.Item
                            label={"商品结算价"}
                            name={"goodsCostPrice"}
                            rules={[{ required: true, message: "商品结算价必填" }]}
                            style={{marginTop: 24}}
                        >
                            <Input readOnly style={{width: 380}} placeholder={"请输入商品成本价"} />
                        </Form.Item> }
                        
                        {goodsSpecOpenShow == true ? "" : <Form.Item
                            label={"商品库存"}
                            name={"goodsStorage"}
                            rules={[{ required: true, message: "商品库存必填" }]}
                            style={{marginTop: 24}}
                        >
                            <Input style={{width: 380}} placeholder={"请输入商品库存"} />
                        </Form.Item> }

                        {goodsSpecOpenShow == true ? <Form.Item
                            label={"商品规格"}
                        >
                            <Card style={{width: 900, boxShadow: "0 0 4px 0 rgb(200 200 200 / 50%)"}}>
                                <Form.Item
                                    label={"规格名"}
                                    name={"goodsSpecId1"}
                                >
                                    <Select
                                        showSearch
                                        filterOption={false}
                                        placeholder="请选择规格名"
                                        optionFilterProp="children"
                                        style={{textAlign:'left', width: 380}}
                                        onChange={changeGoodsSpecId1}
                                    >
                                        {goodsSpecOptions}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={"规格值"}
                                    name={"goodsSpecValueId1"}
                                    style={{marginBottom: 40}}
                                >
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        filterOption={false}
                                        placeholder="请选择规格值"
                                        optionFilterProp="children"
                                        style={{textAlign:'left', width: 380}}
                                        onChange={handleChange1}
                                    >
                                        {goodsSpecValueOptions1}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={"规格名"}
                                    name={"goodsSpecId2"}
                                >
                                    <Select
                                        showSearch
                                        filterOption={false}
                                        placeholder="请选择商品规格"
                                        optionFilterProp="children"
                                        style={{textAlign:'left', width: 380}}
                                        onChange={changeGoodsSpecId2}
                                    >
                                        {goodsSpecOptions}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={"规格值"}
                                    name={"goodsSpecValueId2"}
                                    style={{marginBottom: 40}}
                                >
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        filterOption={false}
                                        placeholder="请选择规格值"
                                        optionFilterProp="children"
                                        style={{textAlign:'left', width: 380}}
                                        onChange={handleChange2}
                                    >
                                        {goodsSpecValueOptions2}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={"规格名"}
                                    name={"goodsSpecId3"}
                                >
                                    <Select
                                        showSearch
                                        filterOption={false}
                                        placeholder="请选择商品规格"
                                        optionFilterProp="children"
                                        style={{textAlign:'left', width: 380}}
                                        onChange={changeGoodsSpecId3}
                                    >
                                        {goodsSpecOptions}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={"规格值"}
                                    name={"goodsSpecValueId3"}
                                >
                                    <Select
                                        filterOption={false}
                                        mode="multiple"
                                        showSearch
                                        placeholder="请选择规格值"
                                        optionFilterProp="children"
                                        style={{textAlign:'left', width: 380}}
                                        onChange={handleChange3}
                                    >
                                        {goodsSpecValueOptions3}
                                    </Select>
                                </Form.Item>
                            </Card>
                        </Form.Item> : ""}

                        {goodsSpecOpenShow == true ? <Form.Item
                            label={"规格明细"}
                            name={"spec"}
                        >
                            <Card style={{width: 900, boxShadow: "0 0 4px 0 rgb(200 200 200 / 50%)"}}>
                                <Space style={{ display: 'flex', marginBottom: 0 }} align="baseline">
                                    <Form.Item style={{width: 120, marginBottom: 10, textAlign:"center", display: "none"}}>
                                        规格ID
                                    </Form.Item>
                                    <Form.Item style={{width: 120, marginBottom: 10, textAlign:"center", display: "none"}}>
                                        规格值ID
                                    </Form.Item>
                                    <Form.Item style={{width: 300, marginBottom: 10, textAlign:"center"}}>
                                        规格名称
                                    </Form.Item>
                                    <Form.Item style={{width: 120, marginBottom: 10, textAlign:"center"}}>
                                        规格价格
                                    </Form.Item>
                                    <Form.Item style={{width: 120, marginBottom: 10, textAlign:"center"}}>
                                        规格原价
                                    </Form.Item>
                                    {/* <Form.Item style={{width: 120, marginBottom: 10, textAlign:"center"}}>
                                        规格结算价
                                    </Form.Item> */}
                                    <Form.Item style={{width: 120, marginBottom: 10, textAlign:"center"}}>
                                        规格库存
                                    </Form.Item>
                                </Space>

                                <Form.List initialValue={goodsSkuDTOList} name="goodsSkuDTOList">
                                    {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Space
                                                key={field.key} style={{display: 'flex', marginBottom: 8 }} align="baseline">

                                                    <Space>
                                                        <Form.Item
                                                            name={[field.name, 'goodsSkuId']}
                                                            fieldKey={[field.fieldKey, 'goodsSkuId']}
                                                            style={{display: 'none'}}
                                                        >
                                                            <Input style={{width: 120}} placeholder="请输入规格ID" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={[field.name, 'goodsSpecValueIds']}
                                                            fieldKey={[field.fieldKey, 'goodsSpecValueIds']}
                                                            style={{display: 'none'}}
                                                        >
                                                            <Input style={{width: 120}} placeholder="请输入规格值ID" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={[field.name, 'goodsSkuName']}
                                                            fieldKey={[field.fieldKey, 'goodsSkuName']}
                                                            rules={[{ required: true, message: '规格名称必填' }]}

                                                        >
                                                            <Input style={{width: 300}} placeholder="请输入名称" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={[field.name, 'goodsSkuPrice']}
                                                            fieldKey={[field.fieldKey, 'goodsSkuPrice']}
                                                            rules={[{ required: true, message: '规格价格必填' }]}

                                                        >
                                                            <Input style={{width: 120}} placeholder="请输入价格" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={[field.name, 'goodsSkuOriginalPrice']}
                                                            fieldKey={[field.fieldKey, 'goodsSkuOriginalPrice']}
                                                            rules={[{ required: true, message: '规格原价必填' }]}

                                                        >
                                                            <Input style={{width: 120}} placeholder="请输入原价" />
                                                        </Form.Item>
                                                        {/* <Form.Item
                                                            name={[field.name, 'goodsSkuCostPrice']}
                                                            fieldKey={[field.fieldKey, 'goodsSkuCostPrice']}
                                                            rules={[{ required: true, message: '规格结算价必填' }]}
                                                        >
                                                            <Input readOnly style={{width: 120}} placeholder="请输入结算价" />
                                                        </Form.Item> */}
                                                        <Form.Item
                                                            name={[field.name, 'goodsSkuStorage']}
                                                            fieldKey={[field.fieldKey, 'goodsSkuStorage']}
                                                            rules={[{ required: true, message: '规格库存必填' }]}
                                                        >
                                                            <Input style={{width: 120}} placeholder="请输入库存" />
                                                        </Form.Item>
                                                    </Space>
                                                {/* <MinusCircleOutlined onClick={() => remove(field.name)} /> */}
                                            </Space>
                                        ))}
                                        {/* <Form.Item>
                                            <Button style={{width: 150}} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                添加规格
                                            </Button>
                                        </Form.Item> */}
                                    </>
                                    )}
                                </Form.List>
                            </Card>
                        </Form.Item> : ""}

                        <Form.Item
                            label={"配送方式"}
                            name={"goodsDelivery"}
                            rules={[{ required: true, message: "配送方式必选" }]}
                            style={{ textAlign:'left' }}
                        >
                            <Radio.Group>
                                <Radio value={false}>到店自提</Radio>
                                <Radio value={true}>快递配送</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label={"快递运费"}
                            name={"goodsUniformFreight"}
                            rules={[{ required: true, message: "快递运费必选" }]}
                            style={{ textAlign:'left' }}
                        >
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio defaultChecked value={true}>
                                        <Form.Item
                                            colon={false}
                                            label={"统一邮费"}
                                            name={"goodsFreight"}
                                            style={{marginTop: 0, marginBottom: 0}}
                                        >
                                            <Input name={"goodsFreight"} prefix={"¥"} style={{width: 180,}} placeholder={"请输入邮费价格"} />
                                        </Form.Item>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label={"开售时间"}
                            name={"goodsShow"}
                            rules={[{ required: true, message: "开售时间必选" }]}
                            style={{ textAlign:'left' }}
                        >
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value={1}>
                                        立即开售
                                    </Radio>
                                    <Radio value={2}>
                                        定时开售
                                        <Form.Item
                                            noStyle={true}
                                            name={"goodsShowStartTimeStr"}
                                        >
                                            <DatePicker onChange={handleSelectGoodsShowStartTime}  showTime style={{marginLeft: 10}} placeholder={"请选择开售时间"} />
                                        </Form.Item>
                                    </Radio>
                                    <Radio value={0}>
                                        放入仓库
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label={"商品排序"}
                            name={"goodsSort"}
                            rules={[{ required: true, message: "商品排序必填" }]}
                        >
                            <Input style={{width: 380}} placeholder={"请输入商品排序"} />
                        </Form.Item>
                    </TabPane>

                    <TabPane tab="商品详情" key="2">
                        <Form.Item
                            label={"商品详情"}
                            name={"goodsContent"}
                            style={{marginTop: 24}}
                        >
                            <BraftEditor
                                style={{border: "1px solid #f0f0f0"}}
                                onChange={handleEditorChange}
                                media={{uploadFn: myUploadFn}}
                            />
                        </Form.Item>
                    </TabPane>
                </Tabs>
                <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                    <Button loading={ submitLoading } type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
