import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { Serve } from "../interfaces/serve";

//列表
export const useServeList = (param?: Partial<Serve>) => {
    const client = useHttp();
    const result = useQuery<any>(["serveList", param], () =>
        client("serve/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增
export const useAddServe = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<Serve>) =>
            client(`serve/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑
export const useEditServe = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<Serve>) =>
            client(`serve/update`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除
export const useDeleteServe = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ serveId }: { serveId: number}) =>
            client(`serve/delete?serveId=${serveId}`, {
            method: "DELETE",
        })
    );
};


//详情
export const useServe = (serveId?: number) => {
    const client = useHttp();
    return useQuery<any>(["serve", { serveId }], () =>
        client(`serve/detail?serveId=${serveId}`),
        {
            enabled: Boolean(serveId)
        }
    );
};

//详情内容
export const useServeDetail = (serveId?: number) => {
    const client = useHttp();
    return useQuery<any>(["serveDetail", { serveId }], () =>
        client(`serveDetail/detail?serveId=${serveId}`),
        {
            enabled: Boolean(serveId)
        }
    );
};

//须知
export const useServeNotice = (serveId?: number) => {
    const client = useHttp();
    return useQuery<any>(["serveNotice", { serveId }], () =>
        client(`serveNotice/detail?serveId=${serveId}`),
        {
            enabled: Boolean(serveId)
        }
    );
};

//多规格
export const useServeSpecList = (serveId?: number) => {
    const client = useHttp();
    const result = useQuery<any>(["expressList", { serveId }], () =>
        client(`serveSpec/list?serveId=${serveId}`)
    );
    const data = result.data?.data;
    return {...result, data };
};

//
export const useServeClassList = () => {
    const client = useHttp();
    return useQuery<any>(["serveClassList", {}], () =>
        client(`serveClass/list`)
    );
};

