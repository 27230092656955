import { Button, Col, Form, Input, Row, Tabs, Select, message, Upload, Typography, Radio, Space, DatePicker, Divider, Tooltip, Checkbox } from "antd";
import { LoadingOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useStore, useEditStore } from '../../services/store-service';
import { useStoreQueryKey, useStoreSearchParams } from './util';
import { Link, useLocation } from "react-router-dom";
const { Option } = Select;
const { TabPane } = Tabs;
const { Text } = Typography;
const { RangePicker } = DatePicker;

export const StoreUpdatePage = () => {
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const apiUrl = process.env.REACT_APP_API_URL;
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //获取数据
    const { pathname } = useLocation();
    const storeId = pathname.match(/setting\/store\/update\/(\d+)/)?.[1]
    const { data: editingStore, isLoading } = useStore(); 
    const [submitLoading, setSubmitLoading] = useState(false);
    //表单回显
    const [storeLogoUrl, setStoreLogoUrl] = useState("");
    const [storePicUrl, setStorePicUrl] = useState("");
    const [storeVideoCoverPreviewPicUrl, setStoreVideoCoverPreviewPicUrl] = useState("");
    const [storeVideoUrl, setStoreVideoUrl] = useState("");
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue(editingStore?.data)
        const storeLogo = imgUrl + editingStore?.data?.storeLogo;
        setStoreLogoUrl(storeLogo)
        const storePic = imgUrl + editingStore?.data?.storePic;
        setStorePicUrl(storePic)
        const storeVideoCoverPreviewPic = imgUrl + editingStore?.data?.storeVideoCoverPreviewPic;
        setStoreVideoCoverPreviewPicUrl(storeVideoCoverPreviewPic)
        const storeVideo = imgUrl + editingStore?.data?.storeVideo;
        setStoreVideoUrl(storeVideo)
    }, [editingStore, form]);
    //上传LOGO
    const [uploadStoreLogoLoading, setUploadStoreLogoLoading] = useState(false);
    const uploadStoreLogoButton = (
        <div>
          {uploadStoreLogoLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const handleChangeStoreLogo = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadStoreLogoLoading(false);
            setStoreLogoUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                storeLogo: relativePath
            })
        }
    };
    const beforeUploadStoreLogo = (file:any) => {
        setStoreLogoUrl("");
        setUploadStoreLogoLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadStoreLogoLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadStoreLogoLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const normStoreLogoFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //上传店铺详情图
    const [uploadStorePicLoading, setUploadStorePicLoading] = useState(false);
    const uploadStorePicButton = (
        <div>
          {uploadStorePicLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const handleChangeStorePic = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadStorePicLoading(false);
            setStorePicUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                storePic: relativePath
            })
        }
    };
    const beforeUploadStorePic = (file:any) => {
        setStorePicUrl("");
        setUploadStorePicLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadStorePicLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadStorePicLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const normStorePicFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //上传视频封面 
    const [uploadStoreVideoCoverPreviewPicLoading, setUploadStoreVideoCoverPreviewPicLoading] = useState(false);
    const uploadStoreVideoCoverPreviewPicButton = (
        <div>
          {uploadStoreVideoCoverPreviewPicLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const handleChangeStoreVideoCoverPreviewPic = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadStoreVideoCoverPreviewPicLoading(false);
            setStoreVideoCoverPreviewPicUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                storeVideoCoverPreviewPic: relativePath
            })
        }
    };
    const beforeUploadStoreVideoCoverPreviewPic = (file:any) => {
        setStoreVideoCoverPreviewPicUrl("");
        setUploadStoreVideoCoverPreviewPicLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadStoreVideoCoverPreviewPicLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadStoreVideoCoverPreviewPicLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const normStoreVideoCoverPreviewPicFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //上传视频
    const [uploadStoreVideoLoading, setUploadStoreVideoLoading] = useState(false);
    const uploadStoreVideoButton = (
        <div>
          {uploadStoreVideoLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传视频</div>
        </div>
    );
    const beforeStoreVideoUpload = (file:any) => {
        setStoreVideoUrl("");
        setUploadStoreVideoLoading(true);
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
            setUploadStoreVideoLoading(false);
            message.error('Image must smaller than 20MB!');
        }
        return isLt20M;
    }
    const handleStoreVideoChange = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadStoreVideoLoading(false);
            setStoreVideoUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                storeVideo: relativePath
            })
        }
    };
    const normStoreVideoFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //提交
    const useMutateStore = useEditStore;
    const { mutateAsync, error } = useMutateStore(
        useStoreQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingStore?.data, ...values }).then(() => {
            message.info('编辑成功');
            setSubmitLoading(false);
        });
    }
    return(
        <div>
            <Form
                form={form}
                {...layout}
                name="basic"
                onFinish={onFinish}
            >
                <Form.Item
                    label={"门店名称"}
                    name={"storeName"}
                    rules={[{ required: true, message: "门店名称必填" }]}
                    style={{marginTop: 24}}
                >
                    <Input style={{width: 380}} placeholder={"请输入门店名称"} />
                </Form.Item>

                <Form.Item
                    label={"门店电话"}
                    name={"storeTel"}
                    rules={[{ required: true, message: "门店电话必填" }]}
                >
                    <Input style={{width: 380}} placeholder={"请输入门店电话"} />
                </Form.Item>

                <Form.Item
                    label={"店铺地址"}
                    name={"storeProvince"}
                    style={{marginBottom: 0}}
                    rules={[{ required: true, message: "店铺地址必填" }]}
                >
                    <Input.Group compact>
                        <Form.Item
                            style={{marginRight: 5, width: 123}}
                            name={"storeProvince"}
                            rules={[{ required: true, message: "省必填" }]}
                        >
                            <Input placeholder={"请输入省"} />
                        </Form.Item>
                        <Form.Item
                            style={{marginRight: 5, width: 123}}
                            name={"storeCity"}
                            rules={[{ required: true, message: "市必填" }]}
                        >
                            <Input placeholder={"请输入市"} />
                        </Form.Item>
                        <Form.Item
                            style={{width: 123}}
                            name={"storeDistrict"}
                            rules={[{ required: true, message: "区必填" }]}
                        >
                            <Input placeholder={"请输入区"} />
                        </Form.Item>
                    </Input.Group>
                    <Input.Group compact>
                        <Form.Item
                            style={{width: 380}}
                            name={"storeAddress"}
                            rules={[{ required: true, message: "详细地址必填" }]}
                        >
                            <Input placeholder={"请输入详细地址"} />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>

                <Form.Item
                    label={"经纬度"}
                    name={"storeLongitude"}
                    style={{marginBottom: 0}}
                    rules={[{ required: true, message: "经纬度必填" }]}
                >
                    <Input.Group compact>
                        <Form.Item
                            style={{marginRight: 5, width: 123}}
                            name={"storeLongitude"}
                            rules={[{ required: true, message: "经度必填" }]}
                        >
                            <Input placeholder={"请输入经度"} />
                        </Form.Item>
                        <Form.Item
                            style={{marginRight: 10, width: 123}}
                            name={"storeLatitude"}
                            rules={[{ required: true, message: "纬度必填" }]}
                        >
                            <Input placeholder={"请输入纬度"} />
                        </Form.Item>
                        <Tooltip title="腾讯地图坐标拾起器">
                            <Typography.Link target="_blank" href="https://lbs.qq.com/getPoint/">坐标拾起器</Typography.Link>
                        </Tooltip>
                    </Input.Group>
                </Form.Item>

                <Form.Item
                    name={"uploadStoreLogo"}
                    label={"店铺LOGO"}
                    valuePropName="fileList" 
                    getValueFromEvent={normStoreLogoFile}
                    rules={[{ required: true, message: "店铺LOGO必传" }]}
                    style={{ textAlign:'left' }}
                    initialValue={[
                        {
                        "uid": "",
                        "lastModified": "",
                        "lastModifiedDate": "",
                        "name": "",
                        "size": "",
                        "type": "",
                        "percent": "",
                        "originFileObj": {
                            "uid": ""
                        },
                        "status": "done",
                        "response": {
                            "status": 0,
                            "data": {
                            "relativePath": "",
                            "realPath": ""
                            }
                        },
                        "xhr": {}
                        }
                    ]}
                >
                    <Upload
                        name="imageFile"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={apiUrl + "/upload/img"}
                        beforeUpload={beforeUploadStoreLogo}
                        onChange={handleChangeStoreLogo}
                    >
                        {storeLogoUrl ? <img src={storeLogoUrl} alt="avatar" style={{ width: '100%' }} /> : uploadStoreLogoButton}
                    </Upload>
                </Form.Item>

                <Form.Item hidden
                    label={"店铺LOGO"}
                    name={"storeLogo"}
                    rules={[{ required: true, message: "店铺LOGO必传" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={"uploadStorePic"}
                    label={"店铺详情图"}
                    valuePropName="fileList" 
                    getValueFromEvent={normStoreLogoFile}
                    style={{ textAlign:'left' }}
                    initialValue={[
                        {
                        "uid": "",
                        "lastModified": "",
                        "lastModifiedDate": "",
                        "name": "",
                        "size": "",
                        "type": "",
                        "percent": "",
                        "originFileObj": {
                            "uid": ""
                        },
                        "status": "done",
                        "response": {
                            "status": 0,
                            "data": {
                            "relativePath": "",
                            "realPath": ""
                            }
                        },
                        "xhr": {}
                        }
                    ]}
                >
                    <Upload
                        name="imageFile"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={apiUrl + "/upload/img"}
                        beforeUpload={beforeUploadStorePic}
                        onChange={handleChangeStorePic}
                    >
                        {storePicUrl ? <img src={storePicUrl} alt="avatar" style={{ width: '100%' }} /> : uploadStorePicButton}
                    </Upload>
                </Form.Item>

                <Form.Item hidden
                    label={"店铺详情图"}
                    name={"storePic"}
                >
                    <Input />
                </Form.Item>
                       
                <Form.Item
                    name={"uploadStoreVideo"}
                    label={"店铺视频"}
                    valuePropName="fileList" 
                    getValueFromEvent={normStoreVideoFile}
                    style={{ textAlign:'left' }}
                    initialValue={[
                        {
                        "uid": "",
                        "lastModified": "",
                        "lastModifiedDate": "",
                        "name": "",
                        "size": "",
                        "type": "",
                        "percent": "",
                        "originFileObj": {
                            "uid": ""
                        },
                        "status": "done",
                        "response": {
                            "status": 0,
                            "data": {
                            "relativePath": "",
                            "realPath": ""
                            }
                        },
                        "xhr": {}
                        }
                    ]}
                >
                    <Upload
                        name="videoFile"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={apiUrl + "/upload/video"}
                        beforeUpload={beforeStoreVideoUpload}
                        onChange={handleStoreVideoChange}
                    >
                        {storeVideoUrl ? <video src={storeVideoUrl} style={{ width: '100%' }} /> : uploadStoreVideoButton}
                    </Upload>
                </Form.Item>

                <Form.Item hidden
                    label={"店铺视频"}
                    name={"storeVideo"}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={"uploadGoodsVideoCoverPreviewPic"}
                    label={"视频贴片"}
                    valuePropName="fileList" 
                    getValueFromEvent={normStoreVideoCoverPreviewPicFile}
                    style={{ textAlign:'left' }}
                    initialValue={[
                        {
                        "uid": "",
                        "lastModified": "",
                        "lastModifiedDate": "",
                        "name": "",
                        "size": "",
                        "type": "",
                        "percent": "",
                        "originFileObj": {
                            "uid": ""
                        },
                        "status": "done",
                        "response": {
                            "status": 0,
                            "data": {
                            "relativePath": "",
                            "realPath": ""
                            }
                        },
                        "xhr": {}
                        }
                    ]}
                >
                    <Upload
                        name="imageFile"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={apiUrl + "/upload/img"}
                        beforeUpload={beforeUploadStoreVideoCoverPreviewPic}
                        onChange={handleChangeStoreVideoCoverPreviewPic}
                    >
                        {storeVideoCoverPreviewPicUrl ? <img src={storeVideoCoverPreviewPicUrl} alt="avatar" style={{ width: '100%' }} /> : uploadStoreVideoCoverPreviewPicButton}
                    </Upload>
                    <Text style={{color: "#999", fontSize: 12}}>视频贴片是指视频播放器在播放前展示的图片。</Text>
                </Form.Item>

                <Form.Item hidden
                    label={"视频贴片"}
                    name={"storeVideoCoverPreviewPic"}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={"营业开始时间"}
                    name={"storeBusinessStartHours"}
                    style={{marginBottom: 0}}
                    rules={[{ required: true, message: "营业开始时间必填" }]}
                >
                    <Input.Group compact>
                        <Form.Item
                            style={{marginRight: 5, width: 123}}
                            name={"storeBusinessStartHours"}
                            rules={[{ required: true, message: "时必填" }]}
                        >
                            <Input placeholder={"请输入时"} />
                        </Form.Item>
                        <Form.Item
                            style={{marginRight: 10, width: 123}}
                            name={"storeBusinessStartMinute"}
                            rules={[{ required: true, message: "分必填" }]}
                        >
                            <Input placeholder={"请输入分"} />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>

                <Form.Item
                    label={"营业结束时间"}
                    name={"storeBusinessEndHours"}
                    style={{marginBottom: 0}}
                    rules={[{ required: true, message: "营业结束时间必填" }]}
                >
                    <Input.Group compact>
                        <Form.Item
                            style={{marginRight: 5, width: 123}}
                            name={"storeBusinessEndHours"}
                            rules={[{ required: true, message: "时必填" }]}
                        >
                            <Input placeholder={"请输入时"} />
                        </Form.Item>
                        <Form.Item
                            style={{marginRight: 10, width: 123}}
                            name={"storeBusinessEndMinute"}
                            rules={[{ required: true, message: "分必填" }]}
                        >
                            <Input placeholder={"请输入分"} />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>

                <Form.Item
                    label={"是否显示"}
                    name={"storeShow"}
                    rules={[{ required: true, message: "是否显示必选" }]}
                    style={{ textAlign:'left' }}
                >
                    <Radio.Group>
                        <Radio value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                    <Button loading={ submitLoading } type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}