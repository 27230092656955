import { Divider, Row, Col, Button, Input, Form, Radio, Spin, message, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useStoreAccountUpdatePassword } from '../../services/store-account-service';
import { useStoreAccountQueryKey } from './util';
import { useForm } from 'antd/lib/form/Form';
const { Option } = Select;

export const StoreAccountUpdatePage = () => {
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    //回显
    const [form] = useForm();
    //编辑
    const useMutateStoreAccountUpdatePassword = useStoreAccountUpdatePassword;
    const { mutateAsync, error } = useMutateStoreAccountUpdatePassword(
        useStoreAccountQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values }).then((data) => {
            message.info(data.msg);
            setSubmitLoading(false);
        });
    };
    return (
        <div>
            <div>
                <Form
                    form={form}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"旧密码"}
                        name={"oldPassword"}
                        rules={[{ required: true, message: "旧密码必填" }]}
                    >
                        <Input placeholder={"请输入旧密码"} />
                    </Form.Item>

                    <Form.Item
                        label={"新密码"}
                        name={"newPassword"}
                        rules={[{ required: true, message: "新密码必填" }]}
                    >
                        <Input placeholder={"请输入新密码"} />
                    </Form.Item>

                    <Form.Item
                        label={"确认密码"}
                        name={"againPassword"}
                        rules={[{ required: true, message: "确认密码必填" }]}
                    >
                        <Input placeholder={"请输入确认密码"} />
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}