import { Table, Space, Row, Col, Button, Input, Modal, message } from 'antd';
import { useStoreAccountList, useDeleteStoreAccount, useRestStoreAccount } from '../../services/store-account-service';
import { useDebounce } from '../../utils';
import { useStoreAccountQueryKey, useStoreAccountSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
const { Search } = Input;

export const StoreAccountListPage = () => {
    //参数    
    const [param, setParam] = useStoreAccountSearchParams();
    const { isLoading, error, totalElements, data: list } = useStoreAccountList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateRestStoreAccount = useRestStoreAccount;
    const { mutateAsync: mutateAsyncRest } = useMutateRestStoreAccount(
        useStoreAccountQueryKey()
    );
    const confirmRestStoreAccount = (storeAccountId: number) => {
        Modal.confirm({
            title: "确定重置密码吗?",
            content: "点击确定",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsyncRest({ storeAccountId }).then(() => {
                        message.info('重置成功');
                        setParam({
                            ...param,
                            storeAccountId: storeAccountId,
                        })
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //重置密码事件
    const useMutateStoreAccount = useDeleteStoreAccount;
    const { mutateAsync } = useMutateStoreAccount(
        useStoreAccountQueryKey()
    );
    const confirmDeleteStoreAccount = (storeAccountId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ storeAccountId }).then(() => {
                        message.info('删除成功');
                        setParam({
                            ...param,
                            storeAccountId: storeAccountId,
                        })
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    return (
        <div>
            <Row style={{marginBottom: 20}}>
                <Col span={12}>
                    <Link to={"/setting/storeAccount/add"}>
                        <Button type="primary">新建账号</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索账号名称" 
                        style={{ width: 200 }}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            storeAccountName: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            storeAccountName: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <div className="site-layout-background">
                <Table 
                    loading = { isLoading }
                    pagination = { paginationProps } 
                    rowKey={ columns => columns.storeAccountId } 
                    columns = {[
                        {
                            title: '账号名称',
                            dataIndex: 'storeAccountName',
                            key: 'storeAccountName',
                        },
                        {
                            title: '是否超级管理员',
                            dataIndex: 'storeAccountIsSuper',
                            key: 'storeAccountIsSuper',
                            render(value, storeAccount) {
                                return (
                                  <span>
                                    { storeAccount.storeAccountIsSuper ? "是" : "否" }
                                  </span> 
                                );
                            }
                        },
                        {
                            title: '操作',
                            key: 'action',
                            render: (value, storeAccount) => (
                                <Space size="middle">
                                    <a onClick={() => confirmDeleteStoreAccount(storeAccount.storeAccountId)}>
                                        删除
                                    </a>
                                    <a onClick={() => confirmRestStoreAccount(storeAccount.storeAccountId)}>
                                        重置
                                    </a>
                                </Space>
                            ),
                            width: 150,
                        },
                    ]}
                    dataSource={ list || [] }  />
            </div>
            <ErrorBox error={ error } />
        </div>
    )
}