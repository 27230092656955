import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useServeSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["serveId", "serveName", "startServeAddTime", "endServeAddTime", "minServeSaleNum", "maxServeSaleNum", "minServePrice", "maxServePrice", "serveClassId", "typeId", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, serveId: Number(param.serveId) || undefined, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useServeQueryKey = () => {
  const [params] = useServeSearchParams();
  return ["serveList", params];
};