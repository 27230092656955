import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { StoreAddress } from "../interfaces/store-address";

//列表
export const useStoreAddressList = () => {
    const client = useHttp();
    const result = useQuery<any>(["storeAddressList"], () =>
        client("storeAddress/list", {})
    );
    const data = result.data?.data;
    return {...result, data };
};
