import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useStoreBillSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["storeBillIncomeMoney", "startStoreBillDate", "endStoreBillDate", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, storeBillIncomeMoney: Number(param.pageNum) || undefined, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useStoreBillQueryKey = () => {
  const [params] = useStoreBillSearchParams();
  return ["storeBillList", params];
};