import { Layout, Menu, Row, Col, Dropdown } from 'antd';
import {
    DownOutlined,
} from '@ant-design/icons';
import { useAuth } from '../context/auth-context';
const { Header } = Layout;

export const HeaderLayout = () => {

    const { logout, storeAccount } = useAuth();

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <a target="_blank" rel="noopener noreferrer" href="/#/setting/changePwd/update">
                    修改密码
            </a>
            </Menu.Item>
            <Menu.Item key="1">
                <a rel="noopener noreferrer" onClick={ logout }>
                    退出登录
            </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3">
                当前账号：{ storeAccount?.storeAccountName }
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className="site-layout-background" style={{ padding: 0, background: '#fff' }}>
            <Row style={{ marginLeft: 16, marginRight: 16 }}>
                <Col span={6} >荟盈星街</Col>
                <Col span={10}></Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                    <Dropdown overlay={menu}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            { storeAccount?.storeAccountName } <DownOutlined />
                        </a>
                    </Dropdown>
                </Col>
            </Row>
        </Header>
    )
}