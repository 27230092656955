import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";

export const useOrdersReturnLogList = (ordersReturnId?: number) => {
    const client = useHttp();
    const result = useQuery<any>(["ordersReturnLogList"], () =>
        client("ordersReturnLog/listAll?ordersReturnId=" + ordersReturnId, {})
    );
    const data = result.data?.data;
    return {...result, data };
};