import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { CarWashRecord } from "../interfaces/car-wash-record";

//洗车记录列表
export const useCarWashRecordList = (param?: Partial<CarWashRecord>) => {
    const client = useHttp();
    const result = useQuery<any>(["carWashRecordList", param], () =>
        client("carWashRecord/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};