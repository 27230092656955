import { Button, Card, Col, Row, Space, Steps, Table, Typography, Image, Divider, List, Modal, Form, message, Select } from "antd";
import { useLocation } from "react-router";
import { useOrdersReturn, useOrdersReturnOrdersGoods, useAgreeOrdersReturn, useRejectOrdersReturn, useAgreeOrdersReturnFinal, useRejectReceive } from '../../services/orders-return-service';
import { useOrdersReturnLogList } from '../../services/orders-return-log-service';
import { useStoreAddressList } from '../../services/store-address-service';
import { useOrdersReturnQueryKey, useOrdersReturnSearchParams } from './util';
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
const { Text, Link, Title } = Typography;
const { Step } = Steps;
const { Option } = Select;

export const OrdersReturnDetailPage = () => {
    const imgUrl = process.env.REACT_APP_IMG_URL;
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //获取数据
    const { pathname } = useLocation();
    const ordersReturnId = pathname.match(/orders\/return\/detail\/(\d+)/)?.[1]
    //详情
    const { data: ordersReturn, isLoading } = useOrdersReturn(
        Number(ordersReturnId)
    ); 
    const [ordersReturnState, setOrdersReturnState] = useState(0); 
    const [currentStep, setCurrentStep] = useState(0); 
    const ors = ordersReturn?.data?.ordersReturnState
    console.log(ors)
    //退款地址
    const { data: storeAddressList } =useStoreAddressList()
    //console.log(storeAddressList)
    const options = storeAddressList?.map((item: any, value: any) => 
        <Option value={item.storeAddressId} key={item.storeAddressId}>{item.storeAddressProvince}{item.storeAddressCity}{item.storeAddressDistrict}{item.storeAddressDetail}</Option>
    );
    //进度条
    useEffect(() =>{
        if(Number(ors) === 20 || Number(ors) === 30) {
            setCurrentStep(1)
        }
        if(Number(ors) === 25 || Number(ors) === 45) {
            setCurrentStep(2)
        }
        if(Number(ors) === 40 ||  Number(ors) === 50) {
            setCurrentStep(3)
        }
        setOrdersReturnState(ors)
    }, [ors])
    //商品
    const { data: ordersReturnOrdersGoods } = useOrdersReturnOrdersGoods(
        Number(ordersReturnId)
    );
    //协商记录
    const { data: ordersReturnLogList } = useOrdersReturnLogList(
        Number(ordersReturnId)
    ); 
    //退款地址
    //storeAddressId
    const [storeAddressId, setStoreAddressId] = useState<number>();
    const storeAddressChange = (e: any) =>{
        setStoreAddressId(e);
    }
    //同意退款并发送地址弹框
    const [submitAgreeLoading, setSubmitAgreeLoading] = useState(false);
    const useMutateAgreeOrdersReturn = useAgreeOrdersReturn;
    const { mutateAsync } = useMutateAgreeOrdersReturn(
        useOrdersReturnQueryKey()
    );
    const [isAgreeModalVisible, setIsAgreeModalVisible] = useState(false);
    const showAgreeModal = () => {
        setIsAgreeModalVisible(true);
    };
    const handleAgreeOk = (ordersReturnId: number) => {
        if(storeAddressId){
            setSubmitAgreeLoading(true)
            return new Promise((resolve, reject) => {
                mutateAsync({ ordersReturnId, storeAddressId }).then(() => {
                    message.info('退款成功');
                    window.location.href = "/#/orders/return/list";
                });
            }).catch(
                () => console.log('Oops errors!')
            );
        } else{
            message.info('请选择退货地址');
        }
    };
    const handleAgreeCancel = () => {
        setIsAgreeModalVisible(false);
    };
    //拒绝退款弹框
    const [submitRejectLoading, setSubmitRejectLoading] = useState(false);
    const useMutateRejectOrdersReturn = useRejectOrdersReturn;
    const { mutateAsync: mutateAsyncReject } = useMutateRejectOrdersReturn(
        useOrdersReturnQueryKey()
    );
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const showRejectModal = () => {
        setIsRejectModalVisible(true);
    };
    const handleRejectOk = (ordersReturnId: number) => {
        setSubmitRejectLoading(true)
        mutateAsyncReject({ ordersReturnId }).then(() => {
            window.location.href = "/#/orders/return/list";
        });
        //setIsRejectModalVisible(false);
    };
    const handleRejectCancel = () => {
        setIsRejectModalVisible(false);
    };
    //确认收货并退款弹框
    const [submitAgreeReturnLoading, setSubmitAgreeReturnLoading] = useState(false);
    const useMutateAgreeOrdersReturnFinal = useAgreeOrdersReturnFinal;
    const { mutateAsync: mutateAsyncAgreeOrdersReturnFinal } = useMutateAgreeOrdersReturnFinal(
        useOrdersReturnQueryKey()
    );
    const [isAgreeReturnModalVisible, setIsAgreeReturnModalVisible] = useState(false);
    const showAgreeReturnModal = () => {
        setIsAgreeReturnModalVisible(true);
    };
    const handleAgreeReturnOk = (ordersReturnId: number) => {
        setSubmitAgreeReturnLoading(true)
        mutateAsyncAgreeOrdersReturnFinal({ ordersReturnId }).then(() => {
            window.location.href = "/#/ordersReturn/list";
        });
        //setIsAgreeReturnModalVisible(false);
    };
    const handleAgreeReturnCancel = () => {
        setIsAgreeReturnModalVisible(false);
    };
    //拒绝收货弹框
    const [submitRejectReceiveLoading, setSubmitRejectReceiveLoading] = useState(false);
    const useMutateRejectReceive = useRejectReceive;
    const { mutateAsync: mutateAsyncRejectReceive } = useMutateRejectReceive(
        useOrdersReturnQueryKey()
    );
    const [isRejectReceiveModalVisible, setIsRejectReceiveModalVisible] = useState(false);
    const showRejectReceiveModal = () => {
        setIsRejectReceiveModalVisible(true);
    };
    const handleRejectReceiveOk = (ordersReturnId: number) => {
        setSubmitRejectReceiveLoading(true)
        mutateAsyncRejectReceive({ ordersReturnId }).then(() => {
            window.location.href = "/#/orders/return/list";
        });
    };
    const handleRejectReceiveCancel = () => {
        setIsRejectReceiveModalVisible(false);
    };
    return (
        <div>
            <Modal confirmLoading={submitAgreeReturnLoading} title="确认收货并退款" visible={isAgreeReturnModalVisible} onOk={() => handleAgreeReturnOk(ordersReturn?.data?.ordersReturnId)} onCancel={handleAgreeReturnCancel}>
                <p>退款方式：退货退款</p>
                <p>退款金额：￥{ordersReturn?.data?.ordersReturnMoney}</p>
                <p>退款数量：{ordersReturn?.data?.ordersReturnNum}</p>
            </Modal>
            <Modal confirmLoading={submitRejectReceiveLoading} title="拒绝收货" visible={isRejectReceiveModalVisible} onOk={() => handleRejectReceiveOk(ordersReturn?.data?.ordersReturnId)} onCancel={handleRejectReceiveCancel}>
                <p>退款方式：退货退款</p>
                <p>退款金额：￥{ordersReturn?.data?.ordersReturnMoney}</p>
                <p>退款数量：{ordersReturn?.data?.ordersReturnNum}</p>
            </Modal>
            <Modal confirmLoading={submitAgreeLoading} title="同意退货" visible={isAgreeModalVisible} onOk={() => handleAgreeOk(ordersReturn?.data?.ordersReturnId)} onCancel={handleAgreeCancel}>
                <p>退款方式：退货退款</p>
                <p>退款金额：￥{ordersReturn?.data?.ordersReturnMoney}</p>
                <p>退款数量：{ordersReturn?.data?.ordersReturnNum}</p>
                <p>退货地址：
                    <Select
                        onChange={storeAddressChange}
                        showSearch
                        placeholder="请选择退货地址"
                        optionFilterProp="children"
                        style={{textAlign:'left', width: '70%'}}
                        >
                        {options}
                    </Select>
                </p>
            </Modal>
            <Modal confirmLoading={submitRejectLoading} title="拒绝买家退款" visible={isRejectModalVisible} onOk={() => handleRejectOk(ordersReturn?.data?.ordersReturnId)} onCancel={handleRejectCancel}>
                <p>退款方式：退货退款</p>
                <p>退款金额：￥{ordersReturn?.data?.ordersReturnMoney}</p>
                <p>退款数量：{ordersReturn?.data?.ordersReturnNum}</p>
            </Modal>
            <Row style={{fontSize: 14, marginBottom: 24}}>
                <Text style={{marginRight:30}}>
                    退款编号： {ordersReturn?.data?.ordersReturnSn}
                </Text>
                <Text>
                    申请时间： {ordersReturn?.data?.ordersReturnAddTime ? dayjs(ordersReturn?.data?.ordersReturnAddTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                </Text>
            </Row>
            <Row style={{ marginBottom: 24 }}>
                <Col span={8} style={{ height: 220, width: "100%", padding:20, border: "1px solid #f0f2f5" }}>
                    <Title level={4}>
                        {ordersReturn?.data?.ordersReturnState == 10 ? '等待商家处理退款申请':''}
                        {ordersReturn?.data?.ordersReturnState == 20 ? '等待买家退货':''}
                        {ordersReturn?.data?.ordersReturnState == 25 ? '等待商家确认收货':''}
                        {ordersReturn?.data?.ordersReturnState == 30 ? '商家不同意退款申请':''}
                        {ordersReturn?.data?.ordersReturnState == 35 ? '商家拒绝收货, 不同意退款':''}
                        {ordersReturn?.data?.ordersReturnState == 40 ? '退款关闭':''}
                        {ordersReturn?.data?.ordersReturnState == 45 ? '商家已确认收货并退货，待到账':''}
                        {ordersReturn?.data?.ordersReturnState == 50 ? '退款成功':''}
                    </Title>
                    <Space direction="vertical" style={{marginBottom: 20}}>
                        {ordersReturn?.data?.ordersReturnState == 10 ? <div>
                            <Text>收到买家退货退款申请，请在{ordersReturn?.data?.ordersReturnNextStepTime ? dayjs(ordersReturn?.data?.ordersReturnNextStepTime).format('YYYY-MM-DD HH:mm:ss'):'无'}前尽快处理</Text>
                            <Text>如你逾期未处理，将自动同意退款</Text> 
                        </div>:''}
                        {ordersReturn?.data?.ordersReturnState == 20 ?  <div>
                            <Text>收到买家退货时，请验货后同意退款</Text>
                            <Text>如买家在{ordersReturn?.data?.ordersReturnNextStepTime ? dayjs(ordersReturn?.data?.ordersReturnNextStepTime).format('YYYY-MM-DD HH:mm:ss'):'无'}前未响应，退款申请将自动关闭</Text>
                        </div>:''}
                        {ordersReturn?.data?.ordersReturnState == 25 ?  <div>
                            <Text>买家已退货，收到买家退货时，请验货后同意退款</Text>
                            <Text>如你在{ordersReturn?.data?.ordersReturnNextStepTime ? dayjs(ordersReturn?.data?.ordersReturnNextStepTime).format('YYYY-MM-DD HH:mm:ss'):'无'}前未处理，将自动确认收货并退款给买家</Text>
                        </div>:''}
                        {ordersReturn?.data?.ordersReturnState == 30 ? <div>
                            <Text>你已拒绝本次退款申请，如买家修改退款申请后，需要你重新处理</Text>
                            <Text>如买家在{ordersReturn?.data?.ordersReturnNextStepTime ? dayjs(ordersReturn?.data?.ordersReturnNextStepTime).format('YYYY-MM-DD HH:mm:ss'):'无'}前未响应，退款申请将自动关闭</Text>
                        </div>:''}
                        {ordersReturn?.data?.ordersReturnState == 35 ? <div>
                            <Text>你已拒绝收货，如买家修改退货物流信息后，需要你重新处理</Text>
                            <Text>如买家在{ordersReturn?.data?.ordersReturnNextStepTime ? dayjs(ordersReturn?.data?.ordersReturnNextStepTime).format('YYYY-MM-DD HH:mm:ss'):'无'}前未响应，退款申请将自动关闭</Text>
                        </div>:''}
                        {ordersReturn?.data?.ordersReturnState == 40 ? <div>
                            <Text>本次退款已被撤销</Text>
                        </div>:''}
                        {ordersReturn?.data?.ordersReturnState == 45 ? <div>
                            <Text>退款金额：￥{ordersReturn?.data?.ordersReturnMoney}</Text>
                        </div>:''}
                        {ordersReturn?.data?.ordersReturnState == 50 ? <div>
                            <Text>退款金额：￥{ordersReturn?.data?.ordersReturnMoney}</Text>
                        </div>:''}
                    </Space>
                    {ordersReturn?.data?.ordersReturnState == 10 ? <div>
                        <Button onClick={showAgreeModal} type="primary" style={{marginRight: 10}}>同意退货</Button>
                        <Button onClick={showRejectModal}>拒绝买家退款</Button>
                    </div>:''}
                    {ordersReturn?.data?.ordersReturnState == 25 ? <div>
                        <Button onClick={showAgreeReturnModal} type="primary" style={{marginRight: 10}}>确认收货并退款</Button>
                        <Button onClick={showRejectReceiveModal}>拒绝收货</Button>
                    </div>:''}
                    {ordersReturn?.data?.ordersReturnState == 35 ? <div>
                        <Button onClick={showAgreeReturnModal} type="primary" style={{marginRight: 10}}>确认收货并退款</Button>
                    </div>:''}
                </Col>
                <Col span={16} style={{ height: 220, padding:20, borderTop: "1px solid #f0f2f5", borderRight: "1px solid #f0f2f5", borderBottom: "1px solid #f0f2f5" }}>
                    <Steps current={currentStep}>
                        <Step title="买家申请退款" description={ordersReturn?.data?.ordersReturnAddTime ? dayjs(ordersReturn?.data?.ordersReturnAddTime).format('YYYY-MM-DD HH:mm:ss'):''} />
                        <Step title="商家处理退款申请" description={ordersReturn?.data?.ordersReturnConfirmTime ? dayjs(ordersReturn?.data?.ordersReturnConfirmTime).format('YYYY-MM-DD HH:mm:ss'):''} />
                        <Step title="买家退货给商家" description={ordersReturn?.data?.ordersReturnShippingTime ? dayjs(ordersReturn?.data?.ordersReturnConfirmTime).format('YYYY-MM-DD HH:mm:ss'):''} />
                        {ordersReturnState === 40 ? 
                            <Step title="退款关闭" description={ordersReturn?.data?.ordersReturnCloseTime ? dayjs(ordersReturn?.data?.ordersReturnCloseTime).format('YYYY-MM-DD HH:mm:ss'):''} /> : 
                            <Step title="退款成功" description={ordersReturn?.data?.ordersReturnFinishTime ? dayjs(ordersReturn?.data?.ordersReturnFinishTime).format('YYYY-MM-DD HH:mm:ss'):''} />}
                    </Steps>
                </Col>
            </Row>
            <Row style={{ backgroundColor: '#f0f2f5', marginBottom: 24  }}>
                <Col span={12} style={{  width: "100%", padding:20 }}>
                    <Space direction="vertical">
                        <Text strong>退款申请信息</Text>
                        <Text>退款方式：仅退款</Text>
                        <Text>货物状态：{ordersReturn?.data?.ordersReturnGoodsStatus ? '已发货':'未发货'}</Text>
                        <Text>退款原因：{ordersReturn?.data?.ordersReturnReason}</Text>
                    </Space>
                </Col>
                <Col span={12} style={{ width: "100%", padding:20 }}>
                    <Space direction="vertical">
                        <Text strong>订单信息</Text>
                        <Text>买家昵称：{ordersReturn?.data?.memberNickname}</Text>
                        <Text>订单编号：{ordersReturn?.data?.ordersSn}</Text>
                        <Text>付款时间：{ordersReturn?.data?.ordersPayTime ? dayjs(ordersReturn?.data?.ordersPayTime).format('YYYY-MM-DD HH:mm:ss'):'无'}</Text>
                        <Text>实付金额：￥{ordersReturn?.data?.ordersActuallyAmount}</Text>
                        <Text>订单金额：￥{ordersReturn?.data?.ordersTotalAmount}</Text>
                    </Space>
                </Col>
            </Row>
            <Row style={{marginBottom: 24}}>
                <Table
                    pagination={false}
                    style={{width:"100%"}}
                    columns = {[
                        {
                            title: '退款商品',
                            dataIndex: 'goodsName',
                            key: 'goodsName',
                            width: "30%",
                            render: (value, item) => (
                                <Space size="middle">
                                    <Row>
                                        <Col span={6}>
                                            <Image
                                                width="100%"
                                                src={imgUrl + item.goodsPic}
                                            />
                                        </Col>
                                        <Col span={18} style={{paddingLeft:10}}>
                                            <Row>
                                                <Col span={24} style={{marginBottom:5}}>
                                                    {item.goodsName}
                                                </Col>
                                                <Col span={24} style={{marginBottom:5}}>
                                                    规格：{item.goodsSkuInfo}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Space>
                            ),
                        },
                        {
                            title: '商品小计金额(元)',
                            dataIndex: 'goodsTotalPrice',
                            key: 'goodsTotalPrice',
                            width: "11.5%",
                        },
                        {
                            title: '商品数量',
                            dataIndex: 'goodsNum',
                            key: 'goodsNum',
                            width: "11.5%",
                        },
                        {
                            title: '是否已发货',
                            dataIndex: 'goodsState',
                            key: 'goodsState',
                            width: "11.5%",
                            render(value, ordersRefundOrdersGoods) {
                                return <span>
                                    {ordersRefundOrdersGoods.goodsState == 30 ? '是':'否'}
                                </span>
                            },
                        },
                        {
                            title: '退款金额(元)',
                            dataIndex: 'ordersReturnMoney',
                            key: 'ordersReturnMoney',
                            width: "11.5%",
                        },
                        {
                            title: '退款数量',
                            dataIndex: 'ordersReturnNum',
                            key: 'ordersReturnNum',
                            width: "11.5%",
                        },
                    ]}
                    dataSource={ [{...ordersReturnOrdersGoods?.data}] || [] } />
            </Row>
            <Card style={{ width: "100%" }}>
                <Row style={{marginBottom: 24}}>
                    <Text strong>协商记录</Text>
                </Row>
                <List
                    bordered = {false}
                    split = {false}
                    dataSource={ordersReturnLogList || []}
                    renderItem={(item:any) => (
                        <List.Item>
                            <Row style={{marginBottom: 30}}>
                                <Space style={{width: "100%"}}>
                                    <Text strong style={{ paddingRight: 30 }}>
                                        {item.ordersReturnLogType == 1 ? "买家" : "卖家"}
                                    </Text>
                                    <Text>
                                        {item.ordersReturnLogCreateTime ? dayjs(item.ordersReturnLogCreateTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                                    </Text>
                                </Space>
                                <Divider dashed style={{ margin: "14px 0"}} />
                                {item.ordersReturnLogMsgType == 10 ? <Space direction="vertical">
                                    <Text strong>{item.ordersReturnLogTitle}</Text>
                                    <Text>退款方式：退货退款</Text>
                                    <Text>退款原因：{item.ordersReturnReason}</Text>
                                    <Text>退款金额：{item.ordersReturnMoney}</Text>
                                    <Text>退款数量：{item.ordersReturnNum}</Text>
                                    <Text>手机号码：{item.ordersReturnContactTel}</Text>
                                </Space> : ""}
                                {item.ordersReturnLogMsgType == 20 ? <Space direction="vertical">
                                    <Text strong>{item.ordersReturnLogTitle}</Text>
                                    <Text>退货地址：{item.ordersReturnAddressProvince}{item.ordersReturnAddressCity}{item.ordersReturnAddressDistrict}{item.ordersReturnAddressDetail}, {item.ordersReturnAddressReceiverName}, {item.ordersReturnAddressReceiverTel}</Text>
                                </Space> : ""}
                                {item.ordersReturnLogMsgType == 25 ? <Space direction="vertical">
                                    <Text strong>{item.ordersReturnLogTitle}</Text>
                                    <Text>物流名称：{item.ordersReturnExpressCompany}</Text>
                                    <Text>物流编号：{item.ordersReturnTrackingNumber}</Text>
                                    <Text>手机号码：{item.ordersReturnAddressReceiverTel}</Text>
                                </Space> : ""}
                                {item.ordersReturnLogMsgType == 30 ? <Space direction="vertical">
                                    <Text strong>{item.ordersReturnLogTitle}</Text>
                                    <Text>拒绝理由：{item.ordersReturnRejectReason}</Text>
                                </Space> : ""}
                                {item.ordersReturnLogMsgType == 35 ? <Space direction="vertical">
                                    <Text strong>{item.ordersReturnLogTitle}</Text>
                                    <Text>拒绝理由：{item.ordersReturnRejectReason}</Text>
                                </Space> : ""}
                                {item.ordersReturnLogMsgType == 40 ? <Space direction="vertical">
                                    <Text strong>{item.ordersReturnLogTitle}</Text>
                                </Space> : ""}
                                {item.ordersReturnLogMsgType == 45 ? <Space direction="vertical">
                                    <Text strong>{item.ordersReturnLogTitle}</Text>
                                </Space> : ""}
                            </Row>
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    )
}