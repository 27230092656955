import { Divider, Upload, Row, Col, Button, Input, Form, Radio, Spin, message, Menu } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { useAddGoodsClass } from '../../services/goods-class-service';
import { useGoodsClassQueryKey } from './util';
import { useForm } from 'antd/lib/form/Form';

export const GoodsClassAddPage = () => {
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    const [form] = useForm();
    //保存
    const useMutateGoodsClass = useAddGoodsClass;
    const { mutateAsync, error } = useMutateGoodsClass(
        useGoodsClassQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values }).then(() => {
          window.location.href = "/#/goods/goodsClass/list";
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/goods/goodsClass/list"}>
                        <Button type="primary">分类列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <div className="site-layout-background">
                <Form
                    form={form}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                    initialValues={{
                        goodsClassSort: 0,
                        goodsClassShow: true
                    }}
                >
                    <Form.Item
                        label={"分类名称"}
                        name={"goodsClassName"}
                        rules={[{ required: true, message: "分类名称必填" }]}
                    >
                        <Input placeholder={"请输入分类名称"} />
                    </Form.Item>

                    <Form.Item
                        label={"分类排序"}
                        name={"goodsClassSort"}
                        rules={[{ required: true, message: "分类排序必填" }]}
                    >
                        <Input placeholder={"请输入分类排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"是否显示"}
                        name={"goodsClassShow"}
                        rules={[{ required: true, message: "分类显示必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}