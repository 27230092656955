import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { GoodsSpec } from "../interfaces/goods-spec";

//列表
export const useGoodsSpecList = (param?: Partial<GoodsSpec>) => {
    const client = useHttp();
    const result = useQuery<any>(["goodsSpecList", param], () =>
        client("goodsSpec/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增
export const useAddGoodsSpec = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<GoodsSpec>) =>
            client(`goodsSpec/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑
export const useEditGoodsSpec = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<GoodsSpec>) =>
            client(`goodsSpec/update?goodsSpecId=${params.goodsSpecId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除
export const useDeleteGoodsSpec = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ goodsSpecId }: { goodsSpecId: number}) =>
            client(`goodsSpec/delete?goodsSpecId=${goodsSpecId}`, {
            method: "DELETE",
        })
    );
};

//详情
export const useGoodsSpec = (goodsSpecId?: number) => {
    const client = useHttp();
    return useQuery<any>(["goodsSpec", { goodsSpecId }], () =>
        client(`goodsSpec/detail?goodsSpecId=${goodsSpecId}`),
        {
            enabled: Boolean(goodsSpecId)
        }
    );
};

export const useGoodsSpecListAll = () => {
    const client = useHttp();
    const result = useQuery<any>(["goodsSpecListAll", { }], () =>
        client(`goodsSpec/listAll`)
    );
    const data = result.data?.data;
    return {...result, data };
};
