import React, { ReactNode, useState } from "react";
import * as auth from "../services/auth-provider";
import { StoreAccount } from "../interfaces/store-account";
import { useMount } from "../utils";
import { http } from "../utils/http";
import { useAsync } from "../utils/use-async";
import { FullPageErrorFallback, FullPageLoading } from "../components/lib";
import { useQueryClient } from "react-query";


interface AuthForm {
  storeAccountName: string;
  storeAccountPassword: string;
}

const bootstrapStoreAccount = async () => {
  let storeAccount = null;
  const token = auth.getToken();
  const localStorageStoreAccountKey = "__auth_provider_store_account_token__";
  const localStorageStoreKey = "__auth_provider_store_token__";
  if (token) {
    const data = await http("storeAccount", { token });
    const status = data.status
    if(status == 0) {
      storeAccount = data.data
      const token = data.data.token;
      const stoken = data.data.stoken;
      window.localStorage.setItem(localStorageStoreAccountKey, token || "");
      window.localStorage.setItem(localStorageStoreKey, stoken || "");
    }
  }
  return storeAccount;
};

const AuthContext = React.createContext<
  | {
      storeAccount: StoreAccount | null;
      register: (form: AuthForm) => Promise<void>;
      login: (form: AuthForm) => Promise<void>;
      logout: () => Promise<void>;
    }
  | undefined
>(undefined);
AuthContext.displayName = "AuthContext";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const {
    data: storeAccount,
    error,
    isLoading,
    isIdle,
    isError,
    run,
    setData: setStoreAccount,
  } = useAsync<StoreAccount | null>();
  const queryClient = useQueryClient()

  const localStorageStoreAccountKey = "__auth_provider_store_account_token__";

  const localStorageStoreKey = "__auth_provider_store_token__";

  const login = (form: AuthForm) => auth.login(form).then(
    async (response) => {
      const token = response.data.token;
      const stoken = response.data.stoken;
      window.localStorage.setItem(localStorageStoreAccountKey, token || "");
      window.localStorage.setItem(localStorageStoreKey, stoken || "");
      setStoreAccount(response.data)
    }
  );
  const register = (form: AuthForm) => auth.register(form).then(setStoreAccount);
  const logout = () => auth.logout().then(() => {
    setStoreAccount(null)
    queryClient.clear()
  });

  useMount(() => {
    run(bootstrapStoreAccount());
  });

  if(isIdle || isLoading) {
    return <FullPageLoading />;
  }

  if(isError) {
    return <FullPageErrorFallback error={error} />;
  }

  return (
    <AuthContext.Provider
      children={children}
      value={{ storeAccount, login, register, logout }}
    />
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth必须在AuthProvider中使用");
  }
  return context;
};