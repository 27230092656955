import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { GoodsClass } from "../interfaces/goods-class";

//分类列表
export const useGoodsClassList = (param?: Partial<GoodsClass>) => {
    const client = useHttp();
    const result = useQuery<any>(["goodsClassList", param], () =>
        client("goodsClass/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//分类树状列表
export const useGoodsClassListTree = () => {
    const client = useHttp();
    return useQuery<any>(["goodsClassListTree", {}], () =>
        client(`goodsClass/listTree`)
    );
};

//新增分类
export const useAddGoodsClass = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<GoodsClass>) =>
            client(`goodsClass/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑分类
export const useEditGoodsClass = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<GoodsClass>) =>
            client(`goodsClass/update?goodsClassId=${params.goodsClassId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除分类
export const useDeleteGoodsClass = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ goodsClassId }: { goodsClassId: number}) =>
            client(`goodsClass/delete?goodsClassId=${goodsClassId}`, {
            method: "DELETE",
        })
    );
};


//分类详情
export const useGoodsClass = (goodsClassId?: number) => {
    const client = useHttp();
    return useQuery<any>(["goodsClassList", { goodsClassId }], () =>
        client(`goodsClass/detail?goodsClassId=${goodsClassId}`),
        {
            enabled: Boolean(goodsClassId)
        }
    );
};

