import React from 'react';
import logo from './logo.svg';
import './App.css';
import { UnAuthenticatedApp } from './pages/unauthenticated';
import { AuthenticatedApp } from './pages/authenticated';
import { useAuth } from './context/auth-context';
import { ErrorBoundary } from './components/error-boundary';
import { FullPageErrorFallback } from './components/lib';

function App() {
  const { storeAccount } = useAuth();
  return (
    <div className="App">
      <ErrorBoundary fallbackRender={ FullPageErrorFallback }>
        {storeAccount ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
      </ErrorBoundary>
    </div>
  );
}
export default App;
