import { Table, Space, Row, Col, Button, Input, Modal, message, Card, Form, DatePicker } from 'antd';
import { useCarWashRecordList } from '../../services/car-wash-record-service';
import { useDebounce } from '../../utils';
import { useCarWashRecordQueryKey, useCarWashRecordSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import { useState } from 'react';
const { RangePicker } = DatePicker;
const { Search } = Input;

export const CarWashRecordListPage = () => {
    //参数    
    const [param, setParam] = useCarWashRecordSearchParams();
    const { isLoading, error, totalElements, data: list } = useCarWashRecordList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    const [startCarWashRecordTime, setStartCarWashRecordTime] = useState();
    const [endCarWashRecordTime, setEndCarWashRecordTime] = useState();
    const handleSelectTime = (value:any ,dateString:any) => {
        setStartCarWashRecordTime(dateString[0]);
        setEndCarWashRecordTime(dateString[1]);
    }
    const onFinish = (values: any) => {
        setParam({
            ...param,
            memberNickname: values.memberNickname,
            myCarWashCardUsername: values.myCarWashCardUsername,
            myCarWashCardTel: values.myCarWashCardTel,
            carLicensePlate: values.carLicensePlate,
            storeName: values.storeName,
            startCarWashRecordTime: startCarWashRecordTime,
            endCarWashRecordTime: endCarWashRecordTime,
            pageNum: null
        })
    }
    return (
        <div>
            <Card style={{ marginBottom: 24, backgroundColor: "#f2f3f5" }}>
                <Form onFinish={onFinish}>
                    <Form.Item name="carWashRecordTimeRange" label="洗车时间" {...rangeConfig}>
                        <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="memberNickname"
                                label="用户昵称"
                            >
                                <Input placeholder="输入用户昵称" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="myCarWashCardUsername"
                                label="用户姓名"
                            >
                                <Input placeholder="输入用户姓名" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="myCarWashCardTel"
                                label="用户电话"
                            >
                                <Input placeholder="输入用户电话" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="storeName"
                                label="洗车门店"
                            >
                                <Input placeholder="输入洗车门店" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="carLicensePlate"
                                label="车牌号"
                            >
                                <Input placeholder="输入车牌号" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button style={{ marginLeft:70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <div className="site-layout-background">
                <Table 
                    loading = { isLoading }
                    pagination = { paginationProps } 
                    rowKey={ columns => columns.carWashRecordId } 
                    columns = {[
                        {
                            title: '车牌号',
                            dataIndex: 'carLicensePlate',
                            key: 'carLicensePlate',
                        },
                        { 
                            title: '洗车时间', 
                            dataIndex: 'carWashRecordTime', 
                            key: 'carWashRecordTime',
                            render(value, carWashRecord) {
                                return <span>
                                    {carWashRecord.carWashRecordTime ? dayjs(carWashRecord.carWashRecordTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                                </span>
                            },
                        },
                        {
                            title: '洗车用户',
                            dataIndex: 'memberNickname',
                            key: 'memberNickname',
                            render(value, carWashRecord) {
                                return (
                                  <span>
                                    { carWashRecord.memberNickname } <br/> { carWashRecord.myCarWashCardUsername } <br/> { carWashRecord.myCarWashCardTel }
                                  </span> 
                                );
                            }
                        },
                        {
                            title: '核销员',
                            dataIndex: 'storeAccountName',
                            key: 'storeAccountName',
                        },
                        {
                            title: '洗车门店',
                            dataIndex: 'storeName',
                            key: 'storeName',
                            render(value, carWashRecord) {
                                return (
                                  <span>
                                    { carWashRecord.storeName } <br/> { carWashRecord.storeAddress }
                                  </span> 
                                );
                            }
                        },
                    ]}
                    dataSource={ list || [] }  />
            </div>
            <ErrorBox error={ error } />
        </div>
    )
}