import { Divider, Row, Col, Button, Input, Form, Radio, Spin, message, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useTransport, useEditTransport } from '../../services/transport-service';
import { useTransportQueryKey } from './util';
import { useForm } from 'antd/lib/form/Form';
const { Option } = Select;

export const TransportUpdatePage = () => {
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    //回显
    const { pathname } = useLocation();
    const transportId = pathname.match(/setting\/transport\/update\/(\d+)/)?.[1]
    const { data: editingTransport } = useTransport(
        Number(transportId)
    );   
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue(editingTransport?.data)
    }, [editingTransport, form]);
    //编辑
    const useMutateTransport = useEditTransport;
    const { mutateAsync, error } = useMutateTransport(
        useTransportQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingTransport?.data, ...values }).then(() => {
            window.location.href = "/#/setting/transport/list";
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/setting/transport/list"}>
                        <Button type="primary">模版列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <div>
                <Form
                    form={form}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"模版名称"}
                        name={"transportName"}
                        rules={[{ required: true, message: "模版名称必填" }]}
                    >
                        <Input placeholder={"请输入模版名称"} />
                    </Form.Item>

                    <Form.Item
                        label={"模版价格"}
                        name={"transportPrice"}
                        rules={[{ required: true, message: "模版价格必填" }]}
                    >
                        <Input placeholder={"请输入模版价格"} />
                    </Form.Item>
                    
                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}