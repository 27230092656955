import { Table, Space, Row, Col, Button, Input, Modal, message, Card, Form, DatePicker, List, Select } from 'antd';
import { useOrdersRefundList } from '../../services/orders-refund-service';
import { useDebounce } from '../../utils';
import { useOrdersRefundQueryKey, useOrdersRefundSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import { useState } from 'react';
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

export const OrdersRefundListPage = () => {
    //参数    
    const [param, setParam] = useOrdersRefundSearchParams();
    const { isLoading, error, totalElements, data: list } = useOrdersRefundList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    const [startOrdersRefundAddTime, setStartOrdersRefundAddTime] = useState();
    const [endOrdersRefundAddTime, setEndOrdersRefundAddTime] = useState();
    const handleSelectTime = (value:any ,dateString:any) => {
        setStartOrdersRefundAddTime(dateString[0]);
        setEndOrdersRefundAddTime(dateString[1]);
    }
    const onFinish = (values: any) => {
        setParam({
            ...param,
            startOrdersRefundAddTime: startOrdersRefundAddTime,
            endOrdersRefundAddTime: endOrdersRefundAddTime,
            ordersRefundSn: values.ordersRefundSn,
            ordersSn: values.ordersSn,
            goodsName: values.goodsName,
            ordersRefundState: values.ordersRefundState,
            ordersRefundGoodsStatusVal: values.ordersRefundGoodsStatusVal,
            pageNum: null
        })
    }
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 15 }}>
                <Form onFinish={onFinish}>
                <Form.Item name="ordersRefundAddTimeRange" label="申请时间" {...rangeConfig}>
                    <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item>
                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item
                            name="ordersRefundSn"
                            label="退款单号"
                        >
                            <Input placeholder="请输入退款单号" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="ordersSn"
                            label="订单编号"
                        >
                            <Input placeholder="请输入订单编号" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="goodsName"
                            label="商品名称"
                        >
                            <Input placeholder="请输入商品名称" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item
                            name="ordersRefundState"
                            label="退款状态"
                        >
                            <Select
                            showSearch
                            placeholder="请选择退款状态"
                            optionFilterProp="children"
                            >
                                <Option value={0}>全部</Option>
                                <Option value={10}>退款申请待商家处理</Option>
                                <Option value={20}>商家已同意退款申请，待到账</Option>
                                <Option value={30}>商家不同意退款申请，待买家处理</Option>
                                <Option value={40}>退款关闭</Option>
                                <Option value={50}>退款成功</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="ordersRefundGoodsStatusVal"
                            label="发货状态"
                        >
                            <Select
                                showSearch
                                placeholder="请选择发货状态"
                                optionFilterProp="children"
                                >
                                <Option value={0}>全部</Option>
                                <Option value={10}>已发货</Option>
                                <Option value={20}>待发货</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Button style={{ marginLeft:70 }} type="primary" htmlType="submit">
                    提交
                    </Button>
                </Form.Item>
                </Form>
            </Card>
            <Row style={{height: 50, lineHeight: 50, fontWeight: 500, backgroundColor: "#f7f8fa"}}>
                <Col style={{height: 50, lineHeight: "50px", width: "30%", textAlign:'center'}}>商品</Col>
                <Col style={{height: 50, lineHeight: "50px", width: "10%", textAlign:'center'}}>发货状态</Col>
                <Col style={{height: 50, lineHeight: "50px", width: "10%", textAlign:'center'}}>订单金额(元)</Col>
                <Col style={{height: 50, lineHeight: "50px", width: "10%", textAlign:'center'}}>退款金额(元)</Col>
                <Col style={{height: 50, lineHeight: "50px", width: "10%", textAlign:'center'}}>申请时间</Col>
                <Col style={{height: 50, lineHeight: "50px", width: "10%", textAlign:'center'}}>超时时间</Col>
                <Col style={{height: 50, lineHeight: "50px", width: "10%", textAlign:'center'}}>退款状态</Col>
                <Col style={{height: 50, lineHeight: "50px", width: "10%", textAlign:'center'}}>操作</Col>
            </Row>
            <List 
                loading = { isLoading }
                pagination = { paginationProps } 
                dataSource={list || []}
                renderItem={(item:any, index) => (
                <List.Item style={{display: 'block', borderBottom: '0px solid #f0f0f0'}}>
                    <Table
                        bordered={true}
                        showHeader={false} 
                        pagination={false} 
                        dataSource={[{...item}] || []} 
                        title={() => <Row>
                        <Col span={20}>
                            <span style={{marginRight:20}}>退款编号：{item.ordersRefundSn}</span>
                            <span style={{marginRight:20}}>订单编号：{item.ordersSn}</span>
                        </Col>
                        <Col span={4}></Col>
                        </Row>}
                        columns={[
                            {
                                title: '商品',
                                dataIndex: 'goodsName',
                                key: 'goodsName',
                                width: "30%",
                            },
                            {
                                title: '发货状态',
                                dataIndex: 'ordersRefundGoodsStatus',
                                key: 'ordersRefundGoodsStatus',
                                width: "10%",
                                render(value, ordersRefund) {
                                    return <span>
                                        {ordersRefund.ordersRefundGoodsStatus ? '已发货':'未发货'}
                                    </span>
                                },
                            },
                            {
                                title: '订单金额',
                                dataIndex: 'ordersActuallyAmount',
                                key: 'ordersActuallyAmount',
                                width: "10%",
                            },
                            {
                                title: '退款金额',
                                dataIndex: 'ordersRefundMoney',
                                key: 'ordersRefundMoney',
                                width: "10%",
                            },
                            {
                                title: '申请时间',
                                dataIndex: 'ordersRefundAddTime',
                                key: 'ordersRefundAddTime',
                                width: "10%",
                                render(value, ordersRefund) {
                                    return <span>
                                        {ordersRefund.ordersRefundAddTime ? dayjs(ordersRefund.ordersRefundAddTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                                    </span>
                                },
                            },
                            {
                                title: '超时时间',
                                dataIndex: 'ordersRefundNextStepTime',
                                key: 'ordersRefundNextStepTime',
                                width: "10%",
                                render(value, ordersRefund) {
                                    return <span>
                                        {ordersRefund.ordersRefundNextStepTime ? dayjs(ordersRefund.ordersRefundNextStepTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                                    </span>
                                },
                            },
                            {
                                title: '退款状态',
                                dataIndex: 'ordersRefundState',
                                key: 'ordersRefundState',
                                width: "10%",
                                render(value, ordersRefund) {
                                    return <span>
                                        {ordersRefund.ordersRefundState == 10 ? '退款申请待商家处理':''}
                                        {ordersRefund.ordersRefundState == 20 ? '商家已同意退款申请，待到账':''}
                                        {ordersRefund.ordersRefundState == 30 ? '商家不同意退款申请，待买家处理':''}
                                        {ordersRefund.ordersRefundState == 40 ? '退款关闭':''}
                                        {ordersRefund.ordersRefundState == 50 ? '退款成功':''}
                                    </span>
                                },
                            },
                            {
                                title: '操作',
                                key: 'action',
                                width: "10%",
                                render: (value, ordersRefund) => (
                                    <Space size="middle">
                                        <Link to={"/orders/refund/detail/" + ordersRefund.ordersRefundId}>
                                            退款处理
                                        </Link>
                                    </Space>
                                ),
                            },
                        ]} 
                    />
                </List.Item>
                )}
            />
            <ErrorBox error={ error } />
        </div>
    )
}