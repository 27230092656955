import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp } from "../utils/http";
import { GoodsEvaluate } from "../interfaces/goods-evaluate";

//商品评论列表
export const useGoodsEvaluateList = (param?: Partial<GoodsEvaluate>) => {
    const client = useHttp();
    const result = useQuery<any>(["goodsEvaluateList", param], () =>
        client("goodsEvaluate/list", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增商品评论
export const useAddGoodsEvaluate = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<GoodsEvaluate>) =>
            client(`goodsEvaluate/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑商品评论
export const useEditGoodsEvaluate = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<GoodsEvaluate>) =>
            client(`goodsEvaluate/update?goodsEvaluateId=${params.goodsEvaluateId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除商品评论
export const useDeleteGoodsEvaluate = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ goodsEvaluateId }: { goodsEvaluateId: number}) =>
            client(`goodsEvaluate/delete?goodsEvaluateId=${goodsEvaluateId}`, {
            method: "DELETE",
        })
    );
};


//商品评论详情
export const useGoodsEvaluate = (goodsEvaluateId?: number) => {
    const client = useHttp();
    return useQuery<any>(["goodsEvaluateList", { goodsEvaluateId }], () =>
        client(`goodsEvaluate/detail?goodsEvaluateId=${goodsEvaluateId}`),
        {
            enabled: Boolean(goodsEvaluateId)
        }
    );
};

