import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useCarWashRecordSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["storeName", "carLicensePlate", "memberNickname", "myCarWashCardUsername", "myCarWashCardTel", "startCarWashRecordTime", "endCarWashRecordTime", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useCarWashRecordQueryKey = () => {
  const [params] = useCarWashRecordSearchParams();
  return ["carWashRecordList", params];
};