import { Table, Space, Row, Col, Button, Input, Modal, message, Rate, Image, Card, Form, DatePicker, Select } from 'antd';
import { useGoodsEvaluateList, useDeleteGoodsEvaluate } from '../../services/goods-evaluate-service';
import { useDebounce } from '../../utils';
import { useGoodsEvaluateQueryKey, useGoodsEvaluateSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import { useState } from 'react';
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

export const GoodsEvaluateListPage = () => {
    //参数    
    const [param, setParam] = useGoodsEvaluateSearchParams();
    const { isLoading, error, totalElements, data: list } = useGoodsEvaluateList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateGoodsEvaluate = useDeleteGoodsEvaluate;
    const { mutateAsync } = useMutateGoodsEvaluate(
        useGoodsEvaluateQueryKey()
    );
    const confirmDeleteGoodsEvaluate = (goodsEvaluateId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ goodsEvaluateId }).then(() => {
                        message.info('删除成功');
                        setParam({
                            ...param,
                            goodsEvaluateId: goodsEvaluateId,
                        })
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    //提交表单
    const [startGoodsEvaluateAddTime, setStartGoodsEvaluateAddTime] = useState();
    const [endGoodsEvaluateAddTime, setEndGoodsEvaluateAddTime] = useState();
    const handleSelectTime = (value:any ,dateString:any) => {
        setStartGoodsEvaluateAddTime(dateString[0]);
        setEndGoodsEvaluateAddTime(dateString[1]);
    }
    const onFinish = (values: any) => {
        setParam({
            ...param,
            startGoodsEvaluateAddTime: startGoodsEvaluateAddTime,
            endGoodsEvaluateAddTime: endGoodsEvaluateAddTime,
            ordersSn: values.ordersSn,
            goodsName: values.goodsName,
            goodsEvaluateScores: values.goodsEvaluateScores,
            goodsEvaluateType: values.goodsEvaluateType,
            pageNum: null
        })
    }
    return (
        <div>
            <Card style={{ marginBottom: 24, backgroundColor: "#f2f3f5" }}>
                <Form onFinish={onFinish}>
                    <Form.Item name="goodsAddTimeRange" label="创建时间" {...rangeConfig}>
                        <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="ordersSn"
                                label="订单编号"
                            >
                                <Input placeholder="输入订单编号" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="goodsName"
                                label="商品名称"
                            >
                                <Input placeholder="输入商品名称" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="goodsEvaluateScores"
                                label="评价星级"
                            >
                                <Select
                                    showSearch
                                    placeholder="请选择星级评价"
                                    optionFilterProp="children"
                                >
                                   <Option value={0}>全部</Option>
                                   <Option value={1}>一星</Option>
                                   <Option value={2}>二星</Option>
                                   <Option value={3}>三星</Option>
                                   <Option value={4}>四星</Option>
                                   <Option value={5}>五星</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="goodsEvaluateType"
                                label="评价方式"
                            >
                                <Select
                                    showSearch
                                    placeholder="请选择评价方式"
                                    optionFilterProp="children"
                                >
                                   <Option value={0}>全部</Option>
                                   <Option value={1}>用户自评</Option>
                                   <Option value={2}>默认评价</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button style={{ marginLeft:70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <div className="site-layout-background">
                <Table 
                    loading = { isLoading }
                    pagination = { paginationProps } 
                    rowKey={ columns => columns.goodsEvaluateId } 
                    columns = {[
                        {
                            title: '商品信息',
                            width: 350,
                            dataIndex: 'goodsName',
                            key: 'goodsName',
                            fixed: 'left',
                            render: (value, goodsEvaluate) => (
                                <Space size="middle">
                                    <Row>
                                        <Col span={6}>
                                            <Image
                                                width="100%"
                                                src={"https://img.ihsh.cn/upload/8/" + goodsEvaluate.goodsPic}
                                            />
                                        </Col>
                                        <Col span={18} style={{paddingLeft:10}}>
                                            <Row>
                                                <Col span={24} style={{marginBottom:5}}>
                                                    {goodsEvaluate.goodsName}
                                                </Col>
                                                <Col span={24} style={{marginBottom:5}}>
                                                    规格：{goodsEvaluate.goodsSkuName}
                                                </Col>
                                                <Col span={24}>
                                                    订单编号：{goodsEvaluate.ordersSn}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Space>
                            ),
                        },
                        {
                            title: '评价内容',
                            dataIndex: 'goodsEvaluateContent',
                            key: 'goodsEvaluateContent',
                            render(value, goodsEvaluate) {
                                return (
                                    <Row>
                                        <Col span={24}>
                                            {goodsEvaluate.goodsEvaluateContent}
                                        </Col>
                                        <Col span={24}>
                                            {
                                                goodsEvaluate?.goodsEvaluatePic?.split(",")?.map((item: any, value: any) => 
                                                    <span  style={{marginRight:10}}>
                                                        <Image
                                                            width={60}
                                                            src={"https://img.ihsh.cn/upload/8/" + item} />
                                                    </span>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                );
                            }
                        },
                        {
                            title: '星级评价',
                            dataIndex: 'goodsEvaluateScores',
                            key: 'goodsEvaluateScores',
                            render(value, goodsEvaluate) {
                                return (
                                  <span>
                                    <Rate allowHalf disabled defaultValue={goodsEvaluate.goodsEvaluateScores} />
                                  </span> 
                                );
                            }
                        },
                        { 
                            title: '评价时间', 
                            dataIndex: 'goodsEvaluateAddTime', 
                            key: 'goodsEvaluateAddTime',
                            render(value, goodsEvaluate) {
                                return <span>
                                    {goodsEvaluate.goodsEvaluateAddTime ? dayjs(goodsEvaluate.goodsEvaluateAddTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                                </span>
                            },
                        },
                        {
                            title: '买家',
                            dataIndex: 'memberNickname',
                            key: 'memberNickname',
                        },
                        {
                            title: '操作',
                            key: 'operation',
                            fixed: 'right',
                            width: 150,
                            render: (value, goodsEvaluate) => (
                                <Space size="middle">
                                    <a onClick={() => confirmDeleteGoodsEvaluate(goodsEvaluate.goodsEvaluateId)}>
                                        删除
                                    </a>
                                </Space>
                            ),
                        },
                    ]}
                    dataSource={ list || [] }
                    scroll={{ x: 1500 }} />
            </div>
            <ErrorBox error={ error } />
        </div>
    )
}