import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useTransportDetailSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["transportDetailId", "transportId", "transportName", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, transportDetailId: Number(param.transportDetailId) || undefined, transportId: Number(param.transportId) || undefined, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useTransportDetailQueryKey = () => {
  const [params] = useTransportDetailSearchParams();
  return ["transportDetailList", params];
};