import { Table, Space, Row, Col, Button, Input, Modal, message, Menu } from 'antd';
import { useStoreWithdrawCashList } from '../../services/store-withdraw-cash';
import { useDebounce } from '../../utils';
import { useStoreWithdrawCashQueryKey, useStoreWithdrawCashSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
const { Search } = Input;

export const StoreWithdrawCashListPage = () => {
    //参数    
    const [param, setParam] = useStoreWithdrawCashSearchParams();
    const { isLoading, error, totalElements, data: list } = useStoreWithdrawCashList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    return (
        <div>
            <Menu selectedKeys={["storePhotoAlbumInner"]} mode="horizontal" style={{marginBottom:24}}>
                <Menu.Item key="storePhotoInner">
                    <Link to='/asset/storeWithdrawCash/add'>提现</Link>
                </Menu.Item>
                <Menu.Item key="storePhotoAlbumInner">
                    <Link to='/asset/storeWithdrawCash/list'>提现记录</Link>
                </Menu.Item>
                <Menu.Item key="storeBankAccountInner">
                    <Link to='/asset/storeWithdrawCash/storeBankAccount/list'>银行卡</Link>
                </Menu.Item>
            </Menu>
            <Row style={{marginBottom: 20}}>
                <Col span={12}>
                    <Link to={"/asset/storeWithdrawCash/add"}>
                        <Button type="primary">提现</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索银行卡号" 
                        style={{ width: 200 }}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            storeWithdrawCashBankEncBankNo: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            storeWithdrawCashBankEncBankNo: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <div className="site-layout-background">
                <Table 
                    loading = { isLoading }
                    pagination = { paginationProps } 
                    rowKey={ columns => columns.storeWithdrawCashId } 
                    columns = {[
                        {
                            title: '申请时间',
                            dataIndex: 'storeWithdrawCashAddTime',
                            key: 'storeWithdrawCashAddTime',
                            render(value, storeWithdrawCash) {
                                return <span>
                                    {storeWithdrawCash.storeWithdrawCashAddTime ? dayjs(storeWithdrawCash.storeWithdrawCashAddTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                                </span>
                            },
                        },
                        {
                            title: '金额（元）',
                            dataIndex: 'storeWithdrawCashAddTime',
                            key: 'storeWithdrawCashAddTime',
                        },
                        {
                            title: '手续费',
                            dataIndex: 'storeWithdrawCashCharges',
                            key: 'storeWithdrawCashCharges',
                        },
                        {
                            title: '实际到账（元）',
                            dataIndex: 'storeWithdrawCashReallyAmount',
                            key: 'storeWithdrawCashReallyAmount',
                        },
                        {
                            title: '到账银行卡',
                            dataIndex: 'storeWithdrawCashBankEncTrueName',
                            key: 'storeWithdrawCashBankEncTrueName',
                            render(value, storeWithdrawCash) {
                                return <span>
                                    {storeWithdrawCash.storeWithdrawCashBankEncTrueName}<br/>
                                    {storeWithdrawCash.storeWithdrawCashBankEncBankNo}<br/>
                                    {storeWithdrawCash.storeWithdrawCashBankName}<br/>
                                </span>
                            },
                        },
                        {
                            title: '提现状态',
                            dataIndex: 'storeWithdrawCashStatus',
                            key: 'storeWithdrawCashStatus',
                            render(value, storeWithdrawCash) {
                                return (
                                  <span>
                                    { storeWithdrawCash.storeWithdrawCashStatus == 1 ? "待处理" : "" }
                                    { storeWithdrawCash.storeWithdrawCashStatus == 2 ? "已处理" : "" }
                                    { storeWithdrawCash.storeWithdrawCashStatus == 3 ? "已拒绝" : "" }
                                  </span> 
                                );
                            }
                        },
                        {
                            title: '提现单号|流水号',
                            dataIndex: 'storeWithdrawCashSn',
                            key: 'storeWithdrawCashSn',
                            render(value, storeWithdrawCash) {
                                return (
                                  <span>
                                    { storeWithdrawCash.storeWithdrawCashSn } <br/>
                                    { storeWithdrawCash.storeWithdrawCashThirdSn }
                                  </span> 
                                );
                            }
                        },
                    ]}
                    dataSource={ list || [] }  />
            </div>
            <ErrorBox error={ error } />
        </div>
    )
}