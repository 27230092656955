import { Table, Space, Row, Col, Button, Input, Modal, message } from 'antd';
import { useGoodsSpecValueList, useDeleteGoodsSpecValue } from '../../services/goods-spec-value-service';
import { useDebounce } from '../../utils';
import { useGoodsSpecValueQueryKey, useGoodsSpecValueSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link, useLocation } from "react-router-dom";
const { Search } = Input;

export const GoodsSpecValueListPage = () => {
    const { pathname } = useLocation();
    const goodsSpecId = pathname.match(/goods\/goodsSpec\/goodsSpecValue\/list\/(\d+)/)?.[1]
    //参数    
    const [param, setParam] = useGoodsSpecValueSearchParams();
    const { isLoading, error, totalElements, data: list } = useGoodsSpecValueList(useDebounce(param, 200), goodsSpecId);
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateGoodsSpecValue = useDeleteGoodsSpecValue;
    const { mutateAsync } = useMutateGoodsSpecValue(
        useGoodsSpecValueQueryKey()
    );
    const confirmDeleteGoodsSpecValue = (goodsSpecValueId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ goodsSpecValueId }).then(() => {
                        message.info('删除成功');
                        setParam({
                            ...param,
                            goodsSpecValueId: goodsSpecValueId,
                        })
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/goods/goodsSpec/goodsSpecValue/add/" + goodsSpecId}>
                        <Button type="primary">新建规格值</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right', display: 'none'}}>
                    <Search 
                        placeholder="可搜索规格值名称" 
                        style={{ width: 200 }}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            goodsSpecValueName: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            goodsSpecValueName: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <div className="site-layout-background">
                <Table 
                    loading = { isLoading }
                    pagination = { paginationProps } 
                    rowKey={ columns => columns.carFirmsId } 
                    columns = {[
                        {
                            title: '规格值名称',
                            dataIndex: 'goodsSpecValueName',
                            key: 'goodsSpecValueName',
                        },
                        {
                            title: '排序',
                            dataIndex: 'goodsSpecValueSort',
                            key: 'goodsSpecValueSort',
                        },
                        {
                            title: '操作',
                            key: 'action',
                            render: (value, goodsSpecValue) => (
                                <Space size="middle">
                                    <Link to={"/goods/goodsSpec/goodsSpecValue/update/" + goodsSpecValue.goodsSpecValueId}>
                                        编辑
                                    </Link>
                                    <a onClick={() => confirmDeleteGoodsSpecValue(goodsSpecValue.goodsSpecValueId)}>
                                        删除
                                    </a>
                                </Space>
                            ),
                            width: 150,
                        },
                    ]}
                    dataSource={ list || [] }  />
            </div>
            <ErrorBox error={ error } />
        </div>
    )
}