import { Layout, Menu, Row, Col, Image } from 'antd';
import {
    BarChartOutlined,
    ProfileOutlined,
    UserOutlined,
    ShopOutlined,
    AccountBookOutlined,
    FundOutlined,
    TagOutlined,
    AppstoreOutlined,
    SettingOutlined,
    CarOutlined,
    BankOutlined
} from '@ant-design/icons';
import logo from "../assets/header-logo.jpeg";
import { Link, useLocation } from "react-router-dom";
import { useState } from 'react';
import React from 'react';
const { SubMenu } = Menu;
const { Sider } = Layout;


export const SiderLayout = () => {
    const { pathname } = useLocation();
    const paths = pathname.split("/");
    const selectedKeys1 = paths[1];
    const selectedKeys2 = paths[2];

    const rootSubmenuKeys = ['dashboard', 'store', 'goods', 'orders', 'carWash', 'asset', 'setting'];
    const [openKeys, setOpenKeys] = React.useState([selectedKeys1, selectedKeys2]);
   
    const onOpenChange = (keys:any) => {
        const latestOpenKey = keys.find((key:any) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          setOpenKeys(keys);
        } else {
          setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    return (
        <Sider width="150"
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
            }}
        >
            <Row>
                <div className="logo"
                    style={{
                        height: '32px',
                        margin: '16px',
                        marginLeft: '59px',
                        textAlign: 'center',
                    }}
                >
                    <Image src={logo} width={32} height={32} />
                </div>
            </Row>
            <Row>
                <Menu
                    theme="dark"
                    mode="inline"
                    openKeys={openKeys}  onOpenChange={onOpenChange} defaultSelectedKeys={[selectedKeys1, selectedKeys2]}
                    style={{ minHeight: 'calc(100vh - 60px)', borderRight: 0 }}
               >
                    <Menu.Item key="dashboard" icon={<FundOutlined />}>
                        <Link to='/dashboard'>概况</Link>
                    </Menu.Item>
                    <SubMenu key="store" icon={<ShopOutlined />} title="装饰">
                        <Menu.Item key="photo">
                            <Link to='/store/photo/list'>相册管理</Link>
                        </Menu.Item>
                        <Menu.Item key="video">
                            <Link to='/store/video/list'>视频管理</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key="goods" icon={<TagOutlined  />} title="商品">
                        <Menu.Item key="goodsManage">
                            <Link to='/goods/goodsManage/list'>商品管理</Link>
                        </Menu.Item>
                        <Menu.Item key="goodsClass">
                            <Link to='/goods/goodsClass/list'>分类管理</Link>
                        </Menu.Item>
                        <Menu.Item key="goodsSpec">
                            <Link to='/goods/goodsSpec/list'>规格管理</Link>
                        </Menu.Item>
                        <Menu.Item key="goodsEvaluate">
                            <Link to='/goods/goodsEvaluate/list'>商品评价</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key="orders" icon={<ProfileOutlined  />} title="订单">
                        <Menu.Item key="ordersManage">
                            <Link to='/orders/ordersManage/list'>订单管理</Link>
                        </Menu.Item>
                        <Menu.Item key="refund">
                            <Link to='/orders/refund/list'>退款管理</Link>
                        </Menu.Item>
                        <Menu.Item key="return">
                            <Link to='/orders/return/list'>退货管理</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key="asset" icon={<TagOutlined  />} title="资产">
                        <Menu.Item key="storeMoney">
                            <Link to='/asset/storeMoney/list'>店铺余额</Link>
                        </Menu.Item>
                        <Menu.Item key="storeWithdrawCash">
                            <Link to='/asset/storeWithdrawCash/add'>余额提现</Link>
                        </Menu.Item>
                        <Menu.Item key="storeBill">
                            <Link to='/asset/storeBill/list'>对账单管理</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key="setting" icon={<SettingOutlined />} title="设置">
                        <Menu.Item key="storeAccount">
                            <Link to='/setting/storeAccount/list'>门店账号</Link>
                        </Menu.Item>
                        <Menu.Item key="store">
                            <Link to='/setting/store/update'>门店详情</Link>
                        </Menu.Item>
                        <Menu.Item key="changePwd">
                            <Link to='/setting/changePwd/update'>修改密码</Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </Row>
        </Sider>
    )
}
