import { useMutation, useQuery, QueryKey } from "react-query";
import { useHttp, useHttps } from "../utils/http";
import { GoodsSpecValue } from "../interfaces/goods-spec-value";

//列表
export const useGoodsSpecValueList = (param?: Partial<GoodsSpecValue>, goodsSpecId?: any) => {
    const client = useHttps();
    const result = useQuery<any>(["goodsSpecValueList", param], () =>
        client("goodsSpecValue/list?goodsSpecId=" + goodsSpecId, { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增
export const useAddGoodsSpecValue = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<GoodsSpecValue>) =>
            client(`goodsSpecValue/add`, {
                data: params,
                method: "POST"
            }),
    );
};

//编辑
export const useEditGoodsSpecValue = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<GoodsSpecValue>) =>
            client(`goodsSpecValue/update?goodsSpecValueId=${params.goodsSpecValueId}`, {
                data: params,
                method: "PUT"
            }),
    );
};

//删除
export const useDeleteGoodsSpecValue = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        ({ goodsSpecValueId }: { goodsSpecValueId: number}) =>
            client(`goodsSpecValue/delete?goodsSpecValueId=${goodsSpecValueId}`, {
            method: "DELETE",
        })
    );
};

//详情
export const useGoodsSpecValue = (goodsSpecValueId?: number) => {
    const client = useHttp();
    return useQuery<any>(["goodsSpec", { goodsSpecValueId }], () =>
        client(`goodsSpecValue/detail?goodsSpecValueId=${goodsSpecValueId}`),
        {
            enabled: Boolean(goodsSpecValueId)
        }
    );
};

export const useGoodsSpecValueListAll = (queryKey: QueryKey) => {
    const client = useHttp();
    return useMutation(
        (params: Partial<GoodsSpecValue>) =>
            client(`goodsSpecValue/listAll?goodsSpecId=` + params.goodsSpecId, {
                method: "GET"
            }),
    );
};