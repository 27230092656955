import { Button, Form, Input, Menu, Spin, Select, message } from "antd"
import { Link } from "react-router-dom"
import { useForm } from 'antd/lib/form/Form';
import { useState } from "react";
import { useStore } from '../../services/store-service';
import { useStoreBankAccountListAll } from '../../services/store-bank-account';
import { useAddStoreWithdrawCash } from '../../services/store-withdraw-cash';
import { useStoreWithdrawCashQueryKey } from './util';

const { Option } = Select;

export const StoreWithdrawCashAddPage = () => {
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //准备店铺数据
    const { data: store } = useStore(); 
    //准备银行卡号
    const { data: storeBankAccountList, isLoading } = useStoreBankAccountListAll();
    const options = storeBankAccountList?.map((item: any, value: any) => 
        <Option value={item.storeBankAccountId} key={item.storeBankAccountId}>{item.storeBankAccountBankEncBankNo}（{item.storeBankAccountBankName}）</Option>
    );
    const [form] = useForm();
    //保存
    const useMutateStoreWithdrawCash = useAddStoreWithdrawCash;
    const { mutateAsync, error } = useMutateStoreWithdrawCash(
        useStoreWithdrawCashQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values }).then((res) => {
            console.log(res)
            if(res.status == 0){
                window.location.href = "/#/asset/storeWithdrawCash/list";
            } else{
                setSubmitLoading(false);
                message.info(res.msg);
            }
        });
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    return (
        <div>
            <Spin spinning={isLoading}>
                <Menu selectedKeys={["storePhotoInner"]} mode="horizontal" style={{marginBottom:24}}>
                    <Menu.Item key="storePhotoInner">
                        <Link to='/asset/storeWithdrawCash/add'>提现</Link>
                    </Menu.Item>
                    <Menu.Item key="storePhotoAlbumInner">
                        <Link to='/asset/storeWithdrawCash/list'>提现记录</Link>
                    </Menu.Item>
                    <Menu.Item key="storeBankAccountInner">
                        <Link to='/asset/storeWithdrawCash/storeBankAccount/list'>银行卡</Link>
                    </Menu.Item>
                </Menu>
                <div className="site-layout-background" style={{ textAlign: 'center' }}>
                    <Form
                        form={form}
                        {...layout}
                        name="basic"
                        onFinish={onFinish}
                        initialValues={{
                            carFirmsSort: 0,
                            carFirmsShow: true
                        }}
                    >
                        <Form.Item
                            label={"店铺名称"}
                            rules={[{ required: true }]}
                            style={{textAlign:"left"}}
                        >
                            {store?.data?.storeName}
                        </Form.Item>
                        <Form.Item
                            label={"可提现金额"}
                            rules={[{ required: true }]}
                            style={{textAlign:"left"}}
                        >
                            {store?.data?.storeMoney}元
                        </Form.Item>
                        <Form.Item
                            label={"本次提现金额"}
                            name={"storeWithdrawCashAmount"}
                            rules={[{ required: true, message: "本次提现金额必填" }]}
                        >
                            <Input placeholder={"请输入本次提现金额"} />
                        </Form.Item>
                        <Form.Item
                            label={"到账银行卡"}
                            name={"storeBankAccountId"}
                            rules={[{ required: true, message: "请选择到账银行卡" }]}
                            >
                            <Select
                                showSearch
                                placeholder="请选择到账银行卡"
                                optionFilterProp="children"
                                style={{textAlign:'left'}}
                            >
                                {options}
                            </Select>
                        </Form.Item>
                        <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                            <Button loading={ submitLoading } type="primary" htmlType="submit">
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </div>
    )
}