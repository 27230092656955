import { Divider, Row, Col, Button, Input, Form, Checkbox, Spin, message, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useTransportDetail, useEditTransportDetail } from '../../services/transport-detail-service';
import { useTransportDetailQueryKey } from './util';
import { useForm } from 'antd/lib/form/Form';
const CheckboxGroup = Checkbox.Group;

export const TransportDetailUpdatePage = () => {
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    
    //回显
    const { pathname } = useLocation();
    const transportDetailId = pathname.match(/setting\/transport\/detail\/update\/(\d+)/)?.[1]
    const { data: editingTransportDetail } = useTransportDetail(
        Number(transportDetailId)
    );   
    // 选中
    const plainOptions = ['北京市', '天津市', '河北省', '山西省', '内蒙古自治区', '辽宁省', '吉林省', '黑龙江省', '上海市', '江苏省', '浙江省', '安徽省', '福建省', '江西省', '山东省', '河南省', '湖北省', '湖南省', '广东省', '广西壮族自治区', '海南省', '重庆市', '四川省', '贵州省', '云南省', '西藏自治区', '陕西省', '甘肃省', '青海省', '宁夏回族自治区', '新疆维吾尔自治区', '台湾省', '香港特别行政区', '澳门特别行政区'];
    const defaultCheckedList = [''];
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const onChange = (list:any) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
    };
    const onCheckAllChange = (e:any) => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue(editingTransportDetail?.data)
        setCheckedList(editingTransportDetail?.data.checkedList)
    }, [editingTransportDetail, form]);
    //编辑
    const useMutateTransport = useEditTransportDetail;
    const { mutateAsync, error } = useMutateTransport(
        useTransportDetailQueryKey()
    );
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingTransportDetail?.data, checkedList, ...values }).then(() => {
            window.location.href = "/#/setting/transport/detail/list/" + editingTransportDetail?.data.transportId;
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/setting/transport/detail/list/" + editingTransportDetail?.data.transportId}>
                        <Button type="primary">模版列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <div>
                <Form
                    form={form}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"名称"}
                        name={"transportDetailName"}
                        rules={[{ required: true, message: "名称必填" }]}
                    >
                        <Input placeholder={"请输入名称"} />
                    </Form.Item>

                    <Form.Item
                        label={"运费价格"}
                        name={"transportDetailPrice"}
                        rules={[{ required: true, message: "运费价格必填" }]}
                    >
                        <Input placeholder={"请输入运费价格"} />
                    </Form.Item>

                    <Form.Item
                        label={"区域"}
                        name={"transportDetailArea"}
                    >
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                            全选
                        </Checkbox>
                        <Divider />
                        <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                    </Form.Item>
                    
                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}