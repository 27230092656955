import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useGoodsEvaluateSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["goodsEvaluateId", "goodsEvaluateContent", "startGoodsEvaluateAddTime", "endGoodsEvaluateAddTime", "ordersSn", "goodsName", "goodsEvaluateScores", "goodsEvaluateType", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, goodsEvaluateId: Number(param.goodsEvaluateId) || undefined, pageNum: Number(param.pageNum) || undefined, goodsEvaluateScores: Number(param.goodsEvaluateScores) || undefined, goodsEvaluateType: Number(param.goodsEvaluateType) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useGoodsEvaluateQueryKey = () => {
  const [params] = useGoodsEvaluateSearchParams();
  return ["goodsEvaluateList", params];
};