import { useSetUrlSearchParam, useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useStoreWithdrawCashSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["storeWithdrawCashBankEncBankNo", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};

export const useStoreWithdrawCashQueryKey = () => {
  const [params] = useStoreWithdrawCashSearchParams();
  return ["storeWithdrawCashList", params];
};