import { Table, Space, Row, Col, Button, Input, Modal, message, Image, Tabs, Card, Form, DatePicker, TimePicker, Select } from 'antd';
import { useGoodsList, useDeleteGoods, useGoodsClassListTree } from '../../services/goods-service';
import { useDebounce } from '../../utils';
import { useGoodsQueryKey, useGoodsSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
import { useState } from 'react';
import dayjs from 'dayjs';
const { Search } = Input;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;

export const GoodsListPage = () => {
    const imgUrl = process.env.REACT_APP_IMG_URL;
    //获取参数
    const { data: goodsClassList } = useGoodsClassListTree();
    const goodsClassListData = goodsClassList?.data;
    const options = goodsClassListData?.map((item: any, value: any) => 
        <Option value={item.goodsClassId} key={item.goodsClassId}>{item.goodsClassName}</Option>
    );
    //参数
    const [param, setParam] = useGoodsSearchParams();
    const { isLoading, error, totalElements, data: list } = useGoodsList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateGoods = useDeleteGoods;
    const { mutateAsync } = useMutateGoods(
        useGoodsQueryKey()
    );
    const confirmDeleteGoods = (goodsId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ goodsId }).then(() => {
                        message.info('删除成功');
                        setParam({
                            ...param,
                            goodsId: goodsId,
                        })
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    //提交表单
    //时间
    const [startGoodsAddTime, setStartGoodsAddTime] = useState();
    const [endGoodsAddTime, setEndGoodsAddTime] = useState();
    const handleSelectTime = (value:any ,dateString:any) => {
        setStartGoodsAddTime(dateString[0]);
        setEndGoodsAddTime(dateString[1]);
    }
    //销量
    const [minGoodsSaleNum, setMinGoodsSaleNum] = useState();
    const [maxGoodsSaleNum, setMaxGoodsSaleNum] = useState();
    const handleMinGoodsSaleNum = (e:any) => {
        setMinGoodsSaleNum(e.target.value)
    }
    const handleMaxGoodsSaleNum = (e:any) => {
        setMaxGoodsSaleNum(e.target.value)
    }
    //价格
    const [minGoodsPrice, setMinGoodsPrice] = useState();
    const [maxGoodsPrice, setMaxGoodsPrice] = useState();
    const handleMinGoodsPrice = (e:any) => {
        setMinGoodsPrice(e.target.value)
    }
    const handleMaxGoodsPrice = (e:any) => {
        setMaxGoodsPrice(e.target.value)
    }
    //分类
    const [goodsClassId, setGoodsClassId] = useState();
    const handleGoodsClass = (value: any) => {
        setGoodsClassId(value)
    };
    //上下架
    const [typeId, setTypeId] = useState();
    const handleTab = (key: any, event: any) => {
        setTypeId(key)
        setParam({
            ...param,
            typeId: key,
            pageNum: null
        })
    }
    //提交
    const onFinish = (values: any) => {
        setParam({
            ...param,
            goodsName: values.goodsName,
            startGoodsAddTime: startGoodsAddTime,
            endGoodsAddTime: endGoodsAddTime,
            minGoodsSaleNum: minGoodsSaleNum,
            maxGoodsSaleNum: maxGoodsSaleNum,
            minGoodsPrice: minGoodsPrice,
            maxGoodsPrice: maxGoodsPrice,
            goodsClassId: goodsClassId,
            typeId: typeId,
            pageNum: null
        })
    };
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/goods/goodsManage/add"}>
                        <Button type="primary">新建商品</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    
                </Col>
            </Row>
            <Card style={{ marginBottom: 24, backgroundColor: "#f2f3f5" }}>
                <Form onFinish={onFinish}>
                    <Form.Item name="goodsAddTimeRange" label="创建时间" {...rangeConfig}>
                        <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="goodsName"
                                label="商品名称"
                            >
                                <Input placeholder="输入商品名称" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="goodsClassId"
                                label="商品分类"
                            >
                                <Select
                                    showSearch
                                    placeholder="请选择商品分类"
                                    optionFilterProp="children"
                                    onChange={handleGoodsClass}
                                >
                                    {options}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                label="商品销量"
                            >
                                <Input.Group compact>
                                    <Input
                                        onChange={handleMinGoodsSaleNum}
                                        name="minGoodsSaleNum"
                                        style={{ width: '44%', textAlign: 'center' }} />
                                    <Input
                                        className="site-input-split"
                                        style={{
                                            width: '12%',
                                            borderLeft: 0,
                                            borderRight: 0,
                                            pointerEvents: 'none',
                                        }}
                                        placeholder="~"
                                        disabled
                                    />
                                    <Input
                                        onChange={handleMaxGoodsSaleNum}
                                        name="maxGoodsSaleNum"
                                        className="site-input-right"
                                        style={{ width: '44%', textAlign: 'center', }} />
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="goodsPrice"
                                label="商品价格"
                            >
                                <Input.Group compact>
                                    <Input
                                        onChange={handleMinGoodsPrice}
                                        name="minGoodsPrice"
                                        style={{ width: '44%', textAlign: 'center' }} />
                                    <Input
                                        className="site-input-split"
                                        style={{
                                            width: '12%',
                                            borderLeft: 0,
                                            borderRight: 0,
                                            pointerEvents: 'none',
                                        }}
                                        placeholder="~"
                                        disabled
                                    />
                                    <Input
                                        onChange={handleMaxGoodsPrice}
                                        name="maxGoodsPrice"
                                        className="site-input-right"
                                        style={{ width: '44%', textAlign: 'center', }} />
                                </Input.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button style={{ marginLeft:70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form> 
            </Card>
            <div className="site-layout-background">
                <Tabs onTabClick={handleTab} defaultActiveKey={param.typeId} type="card" size={"small"}>
                    <TabPane tab="全部" key="1">
                    </TabPane>
                    <TabPane tab="销售中" key="2">
                    </TabPane>
                    <TabPane tab="已售罄" key="3">
                    </TabPane>
                    <TabPane tab="仓库中" key="4">
                    </TabPane>
                </Tabs>
                <Table
                    loading = { isLoading }
                    pagination = { paginationProps } 
                    rowKey={ columns => columns.goodsId } 
                    columns={[
                        {
                            title: '商品名称',
                            width: 300,
                            dataIndex: 'goodsName',
                            key: 'goodsName',
                            fixed: 'left',
                            render: (value, goods) => (
                                <Space size="middle">
                                    <Row>
                                        <Col span={6}>
                                            <Image
                                                width="100%"
                                                src={imgUrl + goods.goodsPic}
                                            />
                                        </Col>
                                        <Col span={18} style={{paddingLeft:10}}>
                                            {goods.goodsName}
                                        </Col>
                                    </Row>
                                </Space>
                            ),
                        },
                        { 
                            title: '价格（元）', 
                            dataIndex: 'goodsPrice', 
                            key: 'goodsPrice' 
                        },
                        { 
                            title: '访问量', 
                            dataIndex: 'goodsPageView', 
                            key: 'goodsPageView' 
                        },
                        { 
                            title: '库存', 
                            dataIndex: 'goodsStorage', 
                            key: 'goodsStorage' 
                        },
                        { 
                            title: '销量', 
                            dataIndex: 'goodsSaleNum', 
                            key: 'goodsSaleNum' 
                        },
                        { 
                            title: '创建时间', 
                            dataIndex: 'goodsAddTime', 
                            key: 'goodsAddTime',
                            render(value, goods) {
                                return <span>
                                    {goods.goodsAddTime ? dayjs(goods.goodsAddTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                                </span>
                            },
                        },
                        {
                            title: '操作',
                            key: 'operation',
                            fixed: 'right',
                            width: 150,
                            render: (value, goods) => (
                                <Space size="middle">
                                    <Link to={"/goods/goodsManage/update/" + goods.goodsId}>
                                        编辑
                                    </Link>
                                    <a onClick={() => confirmDeleteGoods(goods.goodsId)}>
                                        删除
                                    </a>
                                </Space>
                            ),
                        },
                    ]}
                    dataSource={ list || [] }
                    scroll={{ x: 1500 }}/>
            </div>
            <ErrorBox error={ error } />
        </div>
    )
}